import React, { useEffect, useState } from 'react';
import './LandingPage.scss';
import { useParams } from 'react-router-dom';
import { getLanding } from '../../api';
import { LandingContent } from '../../components/layout/campaign/LandingContent';
import NotFound from '../../components/layout/common/NotFound';

const LandingPage = () => {
  let { landingId } = useParams();
  const [landing, setLanding] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    // console.log({ landingId });
    const landingRes = await getLanding(landingId);
    // console.log({ landingRes });
    setLanding(landingRes);
  };

  return (
    <div id={'landing-page'}>
      <LandingContent content={{ ...landing }} />
    </div>
  );
};

export default LandingPage;
