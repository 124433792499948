import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { defaultStyleSpacing } from '../../theme';
import { PaymentSuccessImg } from '../../assets';
import './PaymentSuccess.scss';
import { Row, Container } from '../../components/ui';
import { AppRoutes } from '../../app/routers';
import { useHistory } from 'react-router-dom';
import React from 'react';

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const goHome = () => {
    history.replace(AppRoutes.home());
  };

  return (
    <Container id={'payment-success'}>
      <Box p={2}>
        <h1 style={{ marginLeft: defaultStyleSpacing * 3 }}>{t('paymentSuccessTitle')}</h1>

        <p style={{ marginLeft: defaultStyleSpacing * 3 }}>{t('paymentSuccessSubtitle')}</p>
        {/*<p className={'link-success'} onClick={goHome}>*/}
        {/*  {t('goHome')}*/}
        {/*</p>*/}
        <Row justifyCenter>
          <img src={PaymentSuccessImg} alt={''} className={''} />
        </Row>
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
