
import { Avatar, Link, Typography, Grid } from '@mui/material'
import { useTranslation } from "react-i18next";
import {Button, Row, Col} from "../../ui";
import {defaultStyleSpacing} from "../../../theme";
import _ from 'lodash'

const StepWelcome = ({ user, onSubmit, onSignout }) => {
  const { t } = useTranslation()
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit()
  }
  const handleSignout = (e) => {
    e.preventDefault()
    onSignout()
  }

  return (
    <Col spaceBetween>
      <Typography component='h1' variant='h1'>
        {t('welcome')}{' '}{user?.firstName}!
      </Typography>
      <Row noFlex spaceBetween style={{marginTop: defaultStyleSpacing * 2, marginBottom: defaultStyleSpacing * 2}}>
        <Col style={{marginRight: defaultStyleSpacing}}>
          <Avatar src={user.img} />
        </Col>
        <Col style={{
          paddingLeft: defaultStyleSpacing,
          paddingRight: defaultStyleSpacing
        }}
        >
          <span>
            {`${_.get(user, 'firstName', '')} ${_.get(user, 'lastName', '')}`}
          </span>
          <Typography variant={'label'} color={'black'}>
            {user.email}
          </Typography>
        </Col>
        <Col justifyCenter flex flexEnd>
          <Link href='#' onClick={handleSignout} underline="none">
            <Typography variant={'label'} color={'primary'} style={{fontWeight: 700}} >
              {t('not you')}
            </Typography>
          </Link>
        </Col>
      </Row>
      <Button
        onClick={handleSubmit}
        fullWidth
        text={t('register')}
      />
    </Col>
  )
}



export default StepWelcome
