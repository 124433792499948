import axios from 'axios';
import _ from 'lodash';
import { store } from '../app/store';
import { appSelectors, setCatalogues } from '../features/appSlice';
import { CATALOGUES_TYPES } from '../utils/const';

export const getCatalogue = async (type) => {
  //TODO: controllare che senza l'oggetto completo vada bene lo stesso quando salvo in campagns
  const params = JSON.stringify({
    regex: {
      options: 'i',
      'values.0': '^',
    },
    limit: -1,
    prj: {
      inc: true,
      fields: ['label', '_id', 'values'],
    },
    sort: {
      asc: true,
      fields: ['label'],
    },
  });

  try {
    const { data } = await axios.get(`/public/catalogues/${type}`, {
      params: {
        params: params,
      },
    });

    return _.cloneDeep(data.data);
  } catch (e) {
    console.log({ e });
    return null;
  }
};

export const getCompanySocials = async () => {
  const state = store.getState();

  try {
    const storeCatalogues = appSelectors.catalogueByType(CATALOGUES_TYPES.ACTIVE_CATALOGUE_ENTRY_SOCIAL)(state);
    // if (_.isEmpty(storeCatalogues)) {
      const {
        data: { data },
      } = await axios.get(`/public/active-catalogue-entry-social`);
      const catData = {
        type: CATALOGUES_TYPES.ACTIVE_CATALOGUE_ENTRY_SOCIAL,
        data: _.map(data, 'catalogueEntrySocial[0]').map((social) => ({ _id: social._id, values: social.values, label: social.label })),
      };
      store.dispatch(setCatalogues(catData));
      return _.cloneDeep(data);
    // }
  } catch (e) {
    console.debug({ errorGetCompanySocial: e });
    return null;
  }
};

export const getCataloguesAndSaveInState = async () => {
  await Promise.all([
    getCatalogueAndSaveState(CATALOGUES_TYPES.CURRENCY),
    getCatalogueAndSaveState(CATALOGUES_TYPES.SOCIALS),
    getCatalogueAndSaveState(CATALOGUES_TYPES.EXPERTISE),
    getCatalogueAndSaveState(CATALOGUES_TYPES.LEVELS),
    getCompanySocials(),
  ]);
};

export const getCatalogueAndSaveState = async (type) => {
  const state = store.getState();
  const storeCatalogues = appSelectors.catalogueByType(type)(state);
  console.log({ storeCatalogues });
  try {
    // if (_.isEmpty(storeCatalogues)) {
      const data = await getCatalogue(type);
      const catData = { type, data };
      store.dispatch(setCatalogues(catData));
    // }
  } catch (e) {
    console.debug({ e });
  }
};
