import React, { useState } from 'react';
import { Button, Icon, InputPassword, Row } from '../../ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import spacing from './../../../../src/app/spacing.module.scss';
export const FIELD_NAME_PASSWORD = 'password';

const REGEX_GOOD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?+\-&])[A-Za-z\d@$!%*?+\-&]{8,}$/;

const StepPassword = ({ user, onSubmit, errorMessage, loading }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [control, setControl] = useState('');
  const [good, setGood] = useState(true);
  const [notMatching, setNotMatching] = useState(false);

  const handleChangePassword = (e) => {
    if (!good) {
      setGood(REGEX_GOOD.test(e.target.value));
    }
    setPassword(e.target.value);
  };

  const handleChangeControl = (e) => {
    setNotMatching(false);
    setControl(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log({password, control})
    const isGood = REGEX_GOOD.test(password);
    const matches = password === control;

    if (!isGood) {
      setGood(false);
    }

    if (password === control) {
      setNotMatching(false);
    }

    if (password !== control) {
      setNotMatching(true);
      return;
    }

    if (typeof onSubmit === 'function') {
      if (!isGood || !matches) {
        return;
      }
      const value = e.target[FIELD_NAME_PASSWORD].value;
      onSubmit(value);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {user && (
        <>
          <Typography component="h2" variant="p">
            {t('username')}
          </Typography>
          <p>{user.email}</p>
        </>
      )}

      <Typography component="h2" variant="p">
        {t('password')}
      </Typography>
      <InputPassword sx={{ mt: spacing['md'] }} fullWidth label={t('password')} name={FIELD_NAME_PASSWORD} onChange={handleChangePassword} error={errorMessage} />
      <p className={clsx({ 'b-card-signup__warn': !good })}>{t('passwordRules')}</p>
      <Typography component="h2" variant="hp">
        {t('confirmPassword')}
      </Typography>
      <InputPassword sx={{ mt: spacing['md'] }} fullWidth label={t('password')} name={'control'} onChange={handleChangeControl} error={notMatching} />
      {notMatching && <p className="b-card-signup__warn">{t('passwordNotMatching')}</p>}
      {errorMessage && <p className="b-card-signup__warn">{errorMessage}</p>}
      <Button type="submit" fullWidth loading={loading} sx={{ mt: spacing['md'] }} text={t('continue')} />
    </form>
  );
};

export default StepPassword;
