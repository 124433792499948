import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import {ENVIRONMENTS} from '../app/App';
import {CATALOGUES_TYPES, facebookId, instagramId} from "../utils/const";

export const MODALS = {
  LOGIN: 'login',
  SIGNUP_COMPLETE: 'signupComplete',
  FORGOT_PASSWORD: 'forgotPassword',
}

export const versions = {
  V1: 'v1',
}

export const countries = {
  IT: 'it'
}

const initialState = {
  loginModal: true,
  signupCompleteModal: false,
  forgotPasswordModal: false,
  version: versions.V1,
  country: countries.IT,
  config: {},
  appDebugApiUrl: '',
  localEnvironment: 'PROD',
  catalogues: {}
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoginModal: (state,action) => {
      state.loginModal = action.payload
    },
    setAppSignupCompleteModal : (state,action) => {
      state.signupCompleteModal = action.payload
    },
    setAppForgotPasswordModal: (state, action ) => {
      state.forgotPasswordModal = action.payload
    },
    setAppConfig: (state, action ) => {
      state.config = action.payload
    },
    setAppDebugApiUrl: (state, action ) => {
      state.appDebugApiUrl = action.payload
    },
    setLocalEnvironment: (state, action ) => {
      state.localEnvironment = action.payload
    },
    setCatalogues: (state, action ) => {
      const { type: catalogueType, data } = action.payload
      console.log({catalogueType, data})
      if (!state.catalogues) {
        state.catalogues = {}
      }
      state.catalogues[catalogueType] = data
    }
  },
})

export const {
  setAppLoginModal,
  setAppSignupCompleteModal,
  setAppForgotPasswordModal,
  setAppConfig,
  setAppDebugApiUrl,
  setLocalEnvironment,
  setCatalogues
} = appSlice.actions

export default appSlice.reducer

export const appSelectors = {
  loginModal: (state) => _.get(state, 'app.loginModal', true),
  signUpCompleteModal: (state) => _.get(state, 'app.signupCompleteModal', false),
  forgotModal: (state) => _.get(state, `app.forgotPasswordModal`, false),
  country: (state) => _.get(state, 'app.country') || countries.IT,
  version: (state) => _.get(state, 'app.version') || versions.V1,
  config: (state) => _.get(state, 'app.config') || {},
  appDebugApiUrl: (state) => _.get(state, 'app.appDebugApiUrl') || '',
  localEnvironment: (state) => _.get(state, 'app.localEnvironment') || ENVIRONMENTS.PROD,
  catalogueByType: (catalogueType) => (state) => _.get(state, `app.catalogues[${catalogueType}]`, []),
  socialsPreConf: (state) => {
    const socials = _.get(state, `app.catalogues[${CATALOGUES_TYPES.SOCIALS}]`, [])
    return _.filter(socials, social => [instagramId, facebookId].includes(social._id))
  }
}

