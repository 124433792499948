import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import {captureOrderPaypal, createOrderPaypal, createOrderPaypalCampaign} from '../../../api/payments';
import {useSelector} from 'react-redux';
import {appSelectors} from '../../../features/appSlice';
import {ENVIRONMENTS} from '../../../app/App';

const PayPalCampaign = ({ onPayApprove, total, customIds }) => {
  const env = useSelector(appSelectors.localEnvironment)

  const initialOptions = {
    "client-id": "ARRQQ_AhW7HHhi_NVSJV55jTPvwLgeKSnuBuRZY0G1T1aOrF5rRn4Ih5WDkj6H_ZRVb3dBOGyZri_0r-", // PROD
    // "client-id": "AXYQDj5crRBXHOPUILxwqeOhyaUprVRKDNZAx5d937Ul1NGdZzkP2DeyL2scwaeRiIxTKK4Xj55zfK10", // DEV
    currency: "EUR",
    intent: "capture",
    // "data-client-token": "abc123xyz==",
    // "merchant-id": "5HHEB8M3KCRCY",
    // "data-merchant-id": "5HHEB8M3KCRCY",
    commit: true,
  };

  if (env === ENVIRONMENTS.DEV) {
    initialOptions['client-id'] = 'AXYQDj5crRBXHOPUILxwqeOhyaUprVRKDNZAx5d937Ul1NGdZzkP2DeyL2scwaeRiIxTKK4Xj55zfK10'
  }

  console.log({initialOptions})
  const onError = (err) => {
    console.log({ err });
  };

  const createOrder = async (data, actions) => {
    console.log('create');
    console.log(data, actions);

    const paypalOrder = {
      "typeof": "PaymentIn",
      "note": "",
      "provider": "paypal",
      "correlationId": customIds,
      "req": {
        "intent": "CAPTURE",
        "purchase_units": [
          {
            "amount": {
              "currency_code": "EUR",
              "value": total.toFixed(2)
            },
            // "payee": {
            // },
          }
        ],
      }
    }

    // paypalOrder.purchase_units[0].payee.email_address = 'administration@enchora.com'
    //
    // if (env === ENVIRONMENTS.DEV) {
    //   paypalOrder.purchase_units[0].payee.merchant_id = "5HHEB8M3KCRCY"
    //   paypalOrder.purchase_units[0].payee.email_address = undefined
    // }
    console.log({paypalOrder})

    const createdOrder = await createOrderPaypalCampaign(customIds, paypalOrder)
    return createdOrder.id;
  }

  const onApprove = async (data, actions) => {
    console.log({data, actions});
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      console.log({details})
      onPayApprove(data)
      // alert(`Transaction completed by ${name}`);
    });
  }

  return (
    <PayPalScriptProvider  options={initialOptions}>
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        disabled={false}
        onError={onError}
        // onInit={onInit}
        // onClick={onClick}
        // onError={onError}
        style={{
          shape: 'pill',
          label: 'pay',
          height: 40,
          layout: 'horizontal',
          tagline: false,
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalCampaign;
