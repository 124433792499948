import { useTranslation } from 'react-i18next';
import { getCampaignTypeConfig, normalizeCurrency } from '../../../utils';
import { campaignTypes, CONTACT_TYPES } from '../../../utils/const';
import _ from 'lodash';
import './CampaignContactType.scss';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { Col, Row } from '../../ui';

const CampaignContactType = ({ values, setFieldValue, validateField }) => {
  const { t } = useTranslation();
  const campaignConfig = getCampaignTypeConfig(campaignTypes.PayPerContact);
  const contactTypeCosts = {
    [CONTACT_TYPES.EMAIL]: _.get(campaignConfig, 'emailCost'),
    [CONTACT_TYPES.PHONE]: _.get(campaignConfig, 'phoneCost'),
    [CONTACT_TYPES.EMAIL_PHONE]: _.get(campaignConfig, 'emailAndPhoneCost'),
  };
  console.log({ campaignConfig, contactTypeCosts });
  const onChangeContactType = (e) => {
    const type = e.target.value;
    console.log({ type });
    setFieldValue('contactType', type);
    validateField('contactType');
  };

  return (
    <Box mt="16px" id={'campaign-contact-type'}>
      <Typography variant="h3" mb="8px">
        {values.hasCustomContents ? '4. ' : '3. '}
        {t('campaign type contact')}
      </Typography>
      <Grid container>
        <Grid item md={11} sm={11}>
          {_.values(CONTACT_TYPES).map((contact) => (
            <Row alignCenter style={{ marginTop: '0.5rem' }}>
              <Col alignCenter justifyCenter className={`sectionPrice`}>
                <Typography variant="bold">
                  {normalizeCurrency(contactTypeCosts[contact])}
                </Typography>
              </Col>
              <span>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ fontWeight: 'bold' }}
                >
                  {t(`${campaignTypes.PayPerContact}.contact.${contact}`)}
                </Typography>
              </span>
            </Row>
          ))}
        </Grid>
        <Grid item md={1} sm={1}>
          <FormControl component="fieldset">
            <RadioGroup
              value={values.contactType}
              onChange={onChangeContactType}
            >
              {_.values(CONTACT_TYPES).map((contact) => (
                <FormControlLabel
                  style={{ marginTop: '0.3rem' }}
                  value={contact}
                  control={<Radio />}
                  label={''}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignContactType;
