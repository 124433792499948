import React, { useEffect, useMemo } from 'react';
import './HomeHeader.scss';
import { Button, Col, Row } from '../../ui';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import _ from 'lodash';
import { Image } from '../../ui/image/image';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../app/routers';
import { campaignStatus } from '../../../utils/const';
import { theme } from '../../../utils';
import { metricSelectors } from '../../../features/metricSlice';
import { AvatarIcon } from '../../../assets';
import { getHomeCounters } from '../../../api';

const HomeHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const homeCounters = useSelector(metricSelectors.homeCounters);

  useEffect(() => {
    setTimeout(() => {
      getHomeCounters();
    }, 200);
  }, [businessUnit]);

  const counters = useMemo(() => {
    let active = 0,
      toApprove = 0,
      closed = 0,
      purchased = 0;

    console.log({ homeCampaignKPI: homeCounters });

    _.forEach(homeCounters, ({ campaign }) => {
      let cmpStatus = _.get(campaign, 'campaignStatus');
      if (
        [campaignStatus.ACTIVE, campaignStatus.APPROVED, campaignStatus.FINALIZED, campaignStatus.CLOSED, campaignStatus.TO_BE_APPROVED, campaignStatus.NOT_APPROVED, campaignStatus.REJECTED].includes(
          cmpStatus
        )
      ) {
        purchased++;
      }
      if ([campaignStatus.ACTIVE, campaignStatus.APPROVED].includes(cmpStatus)) {
        active++;
      }
      if ([campaignStatus.TO_BE_APPROVED].includes(cmpStatus)) {
        toApprove++;
      }
      if ([campaignStatus.FINALIZED, campaignStatus.CLOSED].includes(cmpStatus)) {
        closed++;
      }
    });
    return { purchased, active, toApprove, closed };
  }, [homeCounters]);

  const onClickCreateCampaign = () => {
    history.push(AppRoutes.campaign('new'));
  };

  return (
    <Grid container md={12} xs={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} id={'home-header'} justifyContent="center">
      <Row md={4} sm={12} item spaceBetween>
        <Col justifyCenter alignCenter>
          <Image path={_.get(businessUnit, 'avatar.uri')} source={AvatarIcon} className={'avatar'} />
        </Col>
        <Col flex alignCenter justifyCenter className={'profileInfo'} fullWidth>
          <Row alignCenter justifyCenter fullWidth>
            <span className={'bun-name'}>{businessUnit.name}</span>
          </Row>
          <Row>
            <Button text={t('create campaign')} fullWidth onClick={onClickCreateCampaign} size={'small'} className={'create-campaign'} />
          </Row>
        </Col>
      </Row>
      <Row md={8} sm={12} flex spaceBetween>
        <HomeStatisticCard label={'purchased campaign'} value={counters.purchased} backgroundColor={theme['secondaryColor']} />
        <HomeStatisticCard label={'active campaign'} value={counters.active} backgroundColor={theme['primaryColor']} />
        <HomeStatisticCard label={'to approve campaign'} value={counters.toApprove} backgroundColor={theme['grey']} />
        <HomeStatisticCard label={'ended campaign'} value={counters.closed} backgroundColor={theme['primaryDarkColor']} />
      </Row>
    </Grid>
  );
};

export default HomeHeader;

export const HomeStatisticCard = ({ label, value, backgroundColor, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Col md={3} sm={6} xs={12} {...rest}>
      <Col flex className={'card-statistic'}>
        <span className={'card-statistic-value'}>{value}</span>
        <span className={'card-statistic-label'}>{t(label)}</span>
        <div className={'title-bar'} style={{ backgroundColor }} />
      </Col>
    </Col>
  );
};
