import axios from 'axios';
import { store } from '../app/store';
import { userSelectors } from '../features/userSlice';
import { campaignSelectors } from '../features/campaignSlice';
import { businessUnitSelectors } from '../features/businessUnitSlice';
import { patchBusinessUser } from './businessUser';
import _ from 'lodash';

const baseApi = () => {
  const state = store.getState();
  const isLogged = userSelectors.token(state);
  const api = isLogged ? 'private' : 'public';

  return api;
};

export const uploadImage = async (image, path, type = 'image') => {
  try {
    const blobType = type === 'image' ? 'image/png' : 'video/mp4';
    let blob = await fetch(image)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], 'logo', { type: blobType }));
    let formData = new FormData();
    formData.append('fileName', blob);
    formData.append('path', path);
    const { data } = await axios.post(`/${baseApi()}/storages`, formData);
    return data.data[0];
  } catch (e) {
    console.log({ errorUploadImage: e });
    return null;
  }
};

export const uploadFile = async (file, path, fileName) => {
  try {
    const blobType = 'application/pdf';
    let blob = await fetch(file)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], fileName, { type: blobType }));
    let formData = new FormData();
    formData.append('fileName', blob);
    formData.append('path', path);
    const { data } = await axios.post(`/${baseApi()}/storages`, formData);
    return data.data[0];
  } catch (e) {
    console.log({ errorUploadFile: e });
  }
};

export const getUrlImageFromPath = async (path) => {
  try {
    // let blob = await fetch(image).then(r => r.blob());
    if (path.startsWith('blob:')) {
      throw 'not valid url';
    }
    if (!path.startsWith('gs:')) {
      return path;
    }
    const { data } = await axios.get(`/${baseApi()}/storages?uri=${path}`);
    console.log({ data });
    return data.data[0].authLink;
  } catch (e) {
    console.log({ errorUploadImage: e });
  }
};

export const uploadBusinessUnitLogo = async (image) => {
  try {
    const state = store.getState();
    const businessUnit = businessUnitSelectors.selected(state);
    let businessUnitId = businessUnit._id;
    const path = `businessUnit/${businessUnitId}`;
    return await uploadImage(image, path);
  } catch (e) {}
};

export const uploadCampaignImage = async (image) => {
  try {
    const state = store.getState();
    const campaign = campaignSelectors.selected(state);
    let campaignId = campaign._id;
    const path = `campaign/${campaignId}`;
    return await uploadImage(image, path);
  } catch (e) {}
};

export const uploadBusinessUserImage = async (image) => {
  try {
    const state = store.getState();
    const businessUserDetail = userSelectors.detail(state);
    let businessUserId = businessUserDetail._id;
    const path = `businessUser/${businessUserId}/${(Math.random() * 10000).toFixed(0)}`;
    const imageRes = await uploadImage(image, path);
    console.log({ imageRes });
    await patchBusinessUser({ avatar: imageRes });
  } catch (e) {}
};
