import React, { useEffect, useMemo, useState } from 'react';
import './CampaignStatisticInfluencer.scss';
import { getBusinessUnitInfluencerDetail, getInfluencerCampaigns, getMetricCampaignInfluencer } from '../../api';
import { campaignTypes, defaultListObj } from '../../utils/const';
import CampaignStatisticInfluencerFilters from './CampaignStatisticInfluencerFilters';
import { normalizePrice, theme } from '../../utils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Image } from '../../components/ui/image/image';
import Table from '../../components/layout/table/Table';
import { BaseModal, Button, Col, Icon, Row } from '../../components/ui';
import { STATISTICS_MODE } from './CampaignStatisticPage';
import { Card, IconButton, Typography } from '@mui/material';
import { ListSocial } from '../admin/campaign/CampaignDetailPage';
import { ReportModal } from '../../components/layout/modals';

const isToShowSocial = (campaign) => {
  if (campaign.typeof === campaignTypes.PayPerPostEngagement) {
    return true;
  }
  if (campaign.typeof === campaignTypes.PayPerClick && !_.get(campaign, 'contents.influencerContent.hasCustomContents')) {
    return true;
  }
  return false;
};
const CampaignStatisticInfluencer = ({ campaign, mode }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(undefined);
  const [influencerData, setInfluencerData] = useState(defaultListObj);
  const [reportModal, setReportModal] = useState(null);
  const [influencerModal, setInfluencerModal] = useState({});
  const [filters, setFilters] = useState({
    from: _.get(campaign, 'duration.begin.ts'),
    to: _.get(campaign, 'duration.end.ts'),
    infLevel: 'all',
    nickname: undefined,
  });
  const { t } = useTranslation();

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, from: _.get(campaign, 'duration.begin.ts'), to: _.get(campaign, 'duration.end.ts') }));
  }, [campaign]);

  const influencerStatisticFields = useMemo(() => {
    const column = {
      influencer: { align: 'left', sortable: true },
      [`${campaign.typeof}.activityDay`]: { align: 'left', sortable: true },
    };
    if (isToShowSocial(campaign)) {
      column[`${campaign.typeof}.social`] = { align: 'left', sortable: true };
    }
    if (mode === STATISTICS_MODE.PERFORMANCE) {
      column[`${campaign.typeof}.count`] = { align: 'left', sortable: true };
      column[`${campaign.typeof}.count24h`] = { align: 'left', sortable: true };
      column[`${campaign.typeof}.count6h`] = { align: 'left', sortable: true };
      column[`${campaign.typeof}.count1h`] = { align: 'left', sortable: true };
    } else {
      column[`${campaign.typeof}.totalCost`] = { align: 'left', sortable: true };
      column[`${campaign.typeof}.cost24h`] = { align: 'left', sortable: true };
      column[`${campaign.typeof}.cost6h`] = { align: 'left', sortable: true };
      column[`${campaign.typeof}.cost1h`] = { align: 'left', sortable: true };
    }
    return {
      ...column,
      actions: { align: 'right' },
    };
  }, [campaign, mode]);

  const bodyCellComponent = useMemo(() => {
    const cell = {
      influencer: (row) => renderInfluencer(row),
      [`${campaign.typeof}.activityDay`]: (row) => _.get(row, 'days'),
    };
    if (isToShowSocial(campaign)) {
      cell[`${campaign.typeof}.social`] = (row) => renderSocials(row.socials);
    }
    if (mode === STATISTICS_MODE.PERFORMANCE) {
      cell[`${campaign.typeof}.count`] = (row) => _.get(row, 'count');
      cell[`${campaign.typeof}.count24h`] = (row) => _.get(row, 'last24');
      cell[`${campaign.typeof}.count6h`] = (row) => _.get(row, 'last6');
      cell[`${campaign.typeof}.count1h`] = (row) => _.get(row, 'last1');
    } else {
      cell[`${campaign.typeof}.totalCost`] = (row) => normalizePrice(_.get(row, 'totalCost'));
      cell[`${campaign.typeof}.cost24h`] = (row) => normalizePrice(_.get(row, 'cost24'));
      cell[`${campaign.typeof}.cost6h`] = (row) => normalizePrice(_.get(row, 'cost6'));
      cell[`${campaign.typeof}.cost1h`] = (row) => normalizePrice(_.get(row, 'cost1'));
    }
    return {
      ...cell,
      actions: (row) => renderActions(row),
    };
  }, [campaign, mode]);

  const onClickReportUser = (influencer) => {
    setReportModal(influencer._id);
  };

  useEffect(() => {
    onRequest(0, filters);
  }, [campaign]);

  const renderActions = (row) => {
    return (
      <Row justifyEnd flex spaceBetween size="small" variant="outlined">
        <Button text={t('reportUser')} onClick={() => onClickReportUser(row)} />
      </Row>
    );
  };

  const onRequest = async (pageRequest, newFilters) => {
    if (!campaign._id) {
      return;
    }
    const metricRes = await getMetricCampaignInfluencer(campaign._id, pageRequest, newFilters);
    setInfluencerData(metricRes);
  };

  const onClickInfluencerImage = async (infId) => {
    const infData = await getBusinessUnitInfluencerDetail(infId);
    setInfluencerModal(infData);
  };

  const renderInfluencer = (influencer) => {
    const { nickname, avatar } = influencer;
    return (
      <div className={'cursor-pointer'} onClick={() => onClickInfluencerImage(influencer._id)}>
        <Row alignCenter>
          <Image path={_.get(avatar, 'uri', '')} className={'inf-profile'} />
          <span className={'inf-nick'}>@{nickname}</span>
        </Row>
      </div>
    );
  };
  const renderSocials = (socials) => {
    const showFacebook = socials.includes('facebook');
    const showInstagram = socials.includes('instagram');
    return (
      <Row alignCenter>
        {showFacebook && <Icon icon={`fab fa-facebook`} className={'primary'} size={'2x'} />}
        {showFacebook && showInstagram && <div style={{ width: 10 }} />}
        {showInstagram && <Icon icon={`fab fa-instagram`} className={'primary'} size={'2x'} />}
      </Row>
    );
  };

  const onRequestSort = (event, property) => {
    console.debug(event, property);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  console.log({ tableData: influencerData.data });

  const filteredInfluencers = useMemo(() => {
    const filteredByNickname = !!filters.nickname
      ? influencerData.data.filter((v) => v.nickname.toLowerCase().includes(!!filters.nickname ? filters.nickname.toLowerCase() : ''))
      : influencerData.data;
    console.debug({ filteredByNickname, nickname: filters.nickname });
    const filterFromDate = _.filter(
      filteredByNickname,
      (inf) => inf.firstPerformance > filters.from && inf.firstPerformance < filters.to && (filters.infLevel === 'all' || _.get(inf, 'infLevel._id', '') === filters.infLevel)
    );
    console.debug({ filterFromDate, orderBy, order });
    if (!orderBy) {
      return filterFromDate;
    }

    if (orderBy === 'influencer') {
      return _.orderBy(filterFromDate, 'nickname', [order]);
    }

    if (['sum1h', 'sum6h', 'sum24h', 'cost1h', 'cost6h', 'cost24h', 'count1h', 'count6h', 'count24h', 'activityDay', 'count', 'totalCost'].includes(orderBy.split('.')[1])) {
      const filterSplitted = orderBy.split('.')[1]
      let filterBy = filterSplitted.replace('h', '')

      if (filterSplitted === 'activityDay') {
        filterBy = 'days'
      }

      return _.orderBy(filterFromDate, [filterBy], [order]);
    }
    return filterFromDate;
  }, [influencerData, filters, orderBy, order]);

  const onChangeFilters = (newFilter) => {
    setFilters((prevState) => ({ ...prevState, ...newFilter }));
  };

  return (
    <div id={'campaign-stat-inf'}>
      <CampaignStatisticInfluencerFilters filters={filters} onChangeFilters={onChangeFilters} campaign={campaign} />
      <BaseModal open={!_.isEmpty(influencerModal)} style={{ height: '30rem', width: '40rem' }}>
        <InfluencerModalContent influencerModal={influencerModal} onClose={() => setInfluencerModal({})} />
      </BaseModal>
      <ReportModal open={!!reportModal} onClose={() => setReportModal(null)} resourceId={reportModal} />
      {!filteredInfluencers.length && (
        <Typography ml="2rem" mt="2rem" mb="2rem" variant="h4">
          {t('no data available')}
        </Typography>
      )}
      {!!filteredInfluencers.length && (
        <Table
          sortEnable
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          entityFields={influencerStatisticFields}
          data={filteredInfluencers}
          bodyCellComponent={bodyCellComponent}
          showCheckbox={false}>
          {/*<TablePagination rows={filteredInfluencers} page={page} setPage={handleChangePage} totalData={influencerData.total} />*/}
        </Table>
      )}
    </div>
  );
};

export default CampaignStatisticInfluencer;

export const InfluencerModalContent = ({ influencerModal, onClose }) => {
  const { t } = useTranslation();
  const [totCampaign, setTotCampaign] = useState(0);
  const socials = _.get(influencerModal, 'socials', []);
  const style = { height: '1rem', width: '1rem' };

  useEffect(() => {
    if (influencerModal._id) {
      getInfluencerCampaigns(influencerModal._id).then((res) => setTotCampaign(res));
    }
  }, [influencerModal._id]);

  return (
    <Card id={'modal-inf'}>
      <Col>
        <Row className={'row-inf-container'}>
          <span className={'label-inf'}>{t('nickname')}:&nbsp;</span>
          <span className={'value-inf'}>@{influencerModal.nickname}</span>
        </Row>
        <Col>
          <span className={'label-inf'}>{t('socials')}:&nbsp;</span>
          <ListSocial socials={socials} sx={{ height: '1.5rem', width: '1.5rem', marginRight: '0.5rem' }} iconStyle={style} className={'icon-social'} />
        </Col>
        <Row>
          <span className={'label-inf'}>{t('totCampaign')}:&nbsp;</span>
          <span className={'value-inf'}>{totCampaign}</span>
        </Row>
        <Row>
          <span className={'label-inf'}>{t('careerLevel')}:&nbsp;</span>
          <span className={'value-inf'}>{_.get(influencerModal, 'careerLevel.level.label')}</span>
        </Row>
        <Row>
          <span className={'label-inf'}>{t('careerScore')}:&nbsp;</span>
          <span className={'value-inf'}>{_.get(influencerModal, 'careerLevel.score')}</span>
        </Row>
      </Col>
      <Col style={{ marginTop: '1rem' }}>
        <Button onClick={onClose}>{t('close')}</Button>
      </Col>
    </Card>
  );
};
