import { useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import clsx from 'clsx'

import './inputPassword.scss'
export const InputPassword = (props) => {
  const [show, setShow] = useState(false)
  const handleClickShow = () => setShow(!show)

  return (
    <TextField
      className={clsx('input-text')}
      {...props}
      type={show ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={handleClickShow}>
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

