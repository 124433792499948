import React, { useEffect, useMemo, useState } from 'react';
import './CampaignSales.scss';
import { useParams } from 'react-router-dom';
import { defaultListObj } from '../../utils/const';
import { getCampaignPrivate, getCampaignSales, getMetricCampaignInfluencer } from '../../api';
import _ from 'lodash';
import { CampaignDetailHeaderSection, STATISTICS_MODE } from '../dashboard/CampaignStatisticPage';
import { normalizePrice } from '../../utils';
import Table from '../../components/layout/table/Table';
import { salesFields } from '../../utils/entityFields';
import { Col } from '../../components/ui';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CampaignSales = () => {
  let { cmpId } = useParams();
  const [sales, setSales] = useState(defaultListObj);
  const [campaign, setCampaign] = useState({});
  const { t } = useTranslation();
  const hasData = _.size(sales.data) > 0;

  const onRequest = async (pageRequest) => {
    if (!cmpId) {
      return;
    }

    const [dataRes, campRes] = await Promise.all([getCampaignSales(cmpId, pageRequest), getCampaignPrivate(cmpId)]);
    console.debug({ dataRes, campRes });
    setSales(dataRes);
    setCampaign(campRes);
  };

  const bodyCellComponent = useMemo(() => {
    return {
      quantity: (row) => _.get(row, 'quantity', ' - '),
      email: (row) => _.get(row, 'email', ' - '),
      firstName: (row) => _.get(row, 'firstName', ' - '),
      lastName: (row) => _.get(row, 'lastName', ' - '),
      vat: (row) => _.get(row, 'vat', ' - '),
      shippingAddress: (row) => _.get(row, 'shippingAddress', ' - '),
      phone: (row) => {
        return `${row.phone.number}`;
      },
    };
  }, []);

  useEffect(() => {
    onRequest(0);
  }, []);

  console.log({ cmpId });
  return (
    <div id="campaign-sales">
      <CampaignDetailHeaderSection campaign={campaign} />
      <Col md={12} sm={12} xs={12} style={{ marginBottom: '2rem', marginTop: '1rem' }}>
        <Typography variant="h3" color="primary">
          {t('campaignSales')}
        </Typography>
        {!hasData && (
          <Col md={12} sm={12} xs={12}>
            {' '}
            <span>{t('noSalesYet')}</span>{' '}
          </Col>
        )}
      </Col>
      {hasData && <Table entityFields={salesFields} data={sales.data} bodyCellComponent={bodyCellComponent} showCheckbox={false} />}
    </div>
  );
};

export default CampaignSales;
