import React, { memo } from 'react';
import './Campaign.scss';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { STEP_BUDGET, STEP_CATEGORY_TIME_PLACE, STEP_SOCIAL } from '../../../utils/const';
import _ from 'lodash';
import CampaignStepper from './CampaignStepper';
import { useSelector } from 'react-redux';
import { campaignSelectors } from '../../../features/campaignSlice';
import theme from './../../../app/theme.module.scss';
import { defaultStyleSpacing } from '../../../theme';
import CampaignCardRecap from './CampaignCardRecap';
import StepSocial from './StepSocial';
import { StepBudget } from './StepBudget';
import { StepCategory } from './StepCategory';

const STEPS = [STEP_SOCIAL, STEP_BUDGET, STEP_CATEGORY_TIME_PLACE];

export const Campaign = memo(({ campaignType }) => {
  return (
    <Grid container id={'campaign'} spacing={4}>
      <MainContent campaignType={campaignType} />
      <Grid item xs={12} md={4}>
        <CampaignCardRecap />
      </Grid>
    </Grid>
  );
});

const MainContent = ({ campaignType }) => {
  const { t } = useTranslation();
  const currentStep = useSelector(campaignSelectors.currentStep);
  return (
    <Grid item xs={12} md={8}>
      <div style={{ marginBottom: defaultStyleSpacing }}>
        <Typography variant={'h1'}>
          {t('campaign')} {t(`${campaignType}.title`)}
        </Typography>
        <Typography variant="body" color={theme['grey']}>
          {t(`${campaignType}.textDescription`)}
        </Typography>
      </div>
      <CampaignStepper items={STEPS} campaignType={campaignType} />
      {_.isEqual(currentStep, STEP_SOCIAL) && <StepSocial campaignType={campaignType} />}
      {_.isEqual(currentStep, STEP_BUDGET) && <StepBudget campaignType={campaignType} />}
      {_.isEqual(currentStep, STEP_CATEGORY_TIME_PLACE) && <StepCategory campaignType={campaignType} />}
    </Grid>
  );
};
