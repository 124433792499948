import emitter from 'tiny-emitter/instance';

export const EVENT_BUS_ACTION = {
  ITEM_CHANGED: 'item-changed',
  ITEM_DELETED: 'item-changed',
};

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};
