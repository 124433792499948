import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, CheckboxRow, InputText } from './../../ui/index';
import { defaultStyleSpacing } from '../../../theme';
import { openEnchoraCookies, openEnchoraPrivacy, openEnchoraTermsAndConditions } from '../../../utils';
import './steps.scss';
export const FIELD_NAME_FIRST_NAME = 'first-name';
export const FIELD_NAME_LAST_NAME = 'last-name';
export const FIELD_NAME_ROLE = 'companyRole';

const StepProfile = ({ user, onSubmit, errorMessage }) => {
  const { t } = useTranslation();
  const [privacy, setPrivacy] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [error, setError] = useState({
    role: '',
  });

  const inputStyle = {
    marginTop: defaultStyleSpacing,
    marginBottom: defaultStyleSpacing,
    width: '100%',
  };

  const handleChangePrivacy = (e) => {
    setPrivacy((prevState) => !prevState);
  };

  const handleChangeMarketing = (e) => {
    setMarketing((prevState) => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof onSubmit === 'function') {
      const firstName = e.target[FIELD_NAME_FIRST_NAME].value;
      const lastName = e.target[FIELD_NAME_LAST_NAME].value;
      const companyRole = e.target[FIELD_NAME_ROLE].value;

      if (privacy && marketing && firstName && lastName && companyRole) {
        onSubmit({ firstName, lastName, companyRole, privacy, marketing });
      }

      if (!companyRole) {
        setError({
          role: 'required field',
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography component="h1" variant="h1">
        {t('profile')}
      </Typography>
      <Typography style={inputStyle}>{t('step profile text 1')}</Typography>
      <InputText style={inputStyle} fullWidth label={t('firstName')} name={FIELD_NAME_FIRST_NAME} error={errorMessage} defaultValue={user.firstName} />
      <InputText style={inputStyle} fullWidth label={t('lastName')} name={FIELD_NAME_LAST_NAME} error={errorMessage} defaultValue={user.lastName} />
      <InputText style={inputStyle} fullWidth label={t('role in company')} name={FIELD_NAME_ROLE} error={errorMessage} defaultValue={user.companyRole} />
      <div className={'text-container'}>
        <span className={'privacyText'}>{t('stepProfile.disclaimer1')}&nbsp;</span>
      </div>
      {errorMessage && <p className="b-card-signup__warn">{errorMessage}</p>}
      <div className={'privacy-container'}>
        <CheckboxRow onChangeChecked={handleChangePrivacy} checked={privacy}>
          <span className={'privacyText'}>{t('stepProfile.terms1')}&nbsp;</span>
          <span className={'privacyLink'} onClick={openEnchoraPrivacy}>
            {t('stepProfile.enchoraPrivacy')}
          </span>
          <span className={'privacyText'}>&nbsp;{t('stepProfile.terms2')}&nbsp;</span>
          <span className={'privacyLink'} onClick={openEnchoraCookies}>
            {t('stepProfile.enchoraCookies')}
          </span>
          <span className={'privacyText'}>&nbsp;{t('stepProfile.terms3')}</span>
        </CheckboxRow>
        <CheckboxRow onChangeChecked={handleChangeMarketing} checked={marketing}>
          <span className={'privacyText'}>{t('stepProfile.terms4')}&nbsp;</span>
          <span className={'privacyLink'} onClick={openEnchoraTermsAndConditions}>
            {t('stepProfile.enchoraTerms')}
          </span>
          <span className={'privacyText'}>&nbsp;{t('stepProfile.terms3')}</span>
        </CheckboxRow>
      </div>
      <Button disabled={!privacy || !marketing} type="submit" style={{ marginTop: defaultStyleSpacing * 2 }} fullWidth text={t('register')} />
    </form>
  );
};

export default StepProfile;
