import React, { useEffect, useState } from 'react';
import './LoginPage.scss';
import _ from 'lodash';
import {
  ForgotPasswordModal,
  LoginModal,
} from '../../../components/layout/modals';
import { useHistory, useLocation } from 'react-router-dom';
import { getParameterFromUrl } from '../../../utils';
import { AppRoutes } from '../../../app/routers';
import { isSignInWithEmailLink, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../lib/firebase';
import { signInEmailLink } from '../../../api/auth';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import { TOAST } from '../../../utils/const';

const LoginPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [error, setError] = useState('');
  const userDetail = useSelector(userSelectors.detail);
  const [location, setLocation] = useState(window.location.href);
  const params = getParameterFromUrl(search);
  console.log(window.location.href);
  const { oobCode, mode, continueUrl } = params;

  useEffect(() => {
    console.log(window.location.href);
    const email =
      window.localStorage.getItem('email') || _.get(userDetail, 'email');
    console.log({ email });
    console.log({ params, mode });
    if (mode === 'signIn') {
      console.log({ auth });
      console.log({
        isSignInWithEmailLink: isSignInWithEmailLink(
          auth,
          window.location.href
        ),
      });
      if (isSignInWithEmailLink(auth, location)) {
        const email = window.localStorage.getItem('email');
        console.log({ email });
        signInEmailLink(email, location)
          .then((res) => {
            console.log('signInEmailLink', { res });
            history.push({
              pathname: AppRoutes.completeSignup(),
              state: { oobCode, mode, continueUrl },
            });
          })
          .catch((e) => {
            console.log({ signInEmailLink: e });
            setError(e.code);
            toast(translate('error token not valid retry'), TOAST.ERROR);
          });
      } else {
        toast(translate('error token not valid retry'), TOAST.ERROR);
        console.log('KO');
      }
    }

    if (mode === 'resetPassword') {
      history.push({
        pathname: AppRoutes.resetPassword(),
        search: `?oobCode=${oobCode}`,
        state: { step: 'RESET_PASSWORD' },
      });
    }
  }, []);

  return (
    <div id={'loginPage'}>
      <LoginModal />
      <ForgotPasswordModal />
    </div>
  );
};

export default LoginPage;
