import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import clsx from 'clsx'

import './button.scss'
import {Typography} from "@mui/material";

export const Button = ({
  text,
  className,
  color,
  disabled,
  fullWidth,
  onClick,
  loading,
  startIcon,
  type,
  variant = 'contained',
  children,
  ...props
}) => {
  const handleClick = (e) => {
    onClick(e)
  }
  return (
    <LoadingButton
      onClick={onClick}
      disabled={disabled}
      type={type}
      fullWidth={fullWidth}
      loading={loading}
      color={color}
      variant={variant}
      className={clsx('e-button', className)}
      startIcon={startIcon}
      {...props}
    >
      {text}
      {children}
    </LoadingButton>
  )
}

// const propTypes = {
//   /** Additional classnames */
//   className: PropTypes.string,
//   /** MUI color */
//   color: PropTypes.oneOf([
//     'inherit',
//     'primary',
//     'secondary',
//     'success',
//     'error',
//     'info',
//     'warning'
//   ]),
//   /** Disabled button */
//   disabled: PropTypes.bool,
//   /** Display as 100% width block */
//   fullWidth: PropTypes.bool,
//   /** Loading state (show spinner and disable button) */
//   loading: PropTypes.bool,
//   onClick: PropTypes.func,
//   startIcon: PropTypes.node,
//   /** Type of html <button> element */
//   type: PropTypes.oneOf(['button', 'submit']),
//   /** MUI variant */
//   variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
//   text: PropTypes.string,
//   children: PropTypes.any,
//   sx: PropTypes.object
// }
//
// Button.propTypes = propTypes
// Button.defaultProps = {
//   variant: 'contained'
// }
