import React, { useEffect } from 'react';
import './CampaignDetail.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { campaignSelectors } from '../../../features/campaignSlice';
import { Col, Icon, Row } from '../../ui';
import { Card, Grid, Typography } from '@mui/material';
import { getEnchorerCampaignDetail } from '../../../api/enchorer';
import _ from 'lodash';
import { normalizeCurrency } from '../../../utils';
import { IconVk } from '../../../assets';
import clsx from 'clsx';
import { appSelectors } from '../../../features/appSlice';
import { DateTime } from 'luxon';
import { userSelectors } from '../../../features/userSlice';
import { USER_ROLES } from '../../../utils/const';
import { getCampaignDetail } from '../../../api';
import {CampaignCartRow} from './CampaignCartRow';

export const CampaignDetail = ({ action }) => {
  const { t } = useTranslation();
  const campaign = useSelector(campaignSelectors.selected);
  const config = useSelector(appSelectors.config);
  const role = useSelector(userSelectors.role);
  const { budget, brief } = campaign;
  const socials = _.get(campaign, 'contents.influencerContent.socials') || [];
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      role === USER_ROLES.ENCHORER && getEnchorerCampaignDetail(id);
      role === USER_ROLES.BUSINESS_USER && getCampaignDetail(id);
    }
  }, []);

  const hasIva = true;
  const images = _.get(
    campaign,
    'contents.influencerContent.images',
    []
  ).length;
  const video = _.get(campaign, 'contents.influencerContent.video');

  return (
    <Grid spacing={4} container id={'campaign-detail'}>
      <Grid item xs={11} md={9}>
        <CampaignCartRow campaign={campaign} detailPage isAdmin adminButtons={action} />
      </Grid>
    </Grid>
  );
};


export const ListSocial = ({
  socials,
  className,
  sx = { height: 15, width: 15 },
  iconStyle,
  showSeparator,
}) => {
  if (_.isString(socials[0])) {
    return <Col />;
  }
  return (
    <Col>
      {showSeparator && <hr />}
      <Row className={'social-container'}>
        {socials &&
          socials?.map((social) =>
            social.label === 'VKontakte' ? (
              <img src={IconVk} alt={social._id} style={sx} />
            ) : (
              <Icon
                icon={`fab ${social.values[1]}`}
                sx={sx}
                className={className}
                style={iconStyle}
              />
            )
          )}
      </Row>
    </Col>
  );
};

export const InfoDetailCampaign = ({
  fieldName,
  value,
  icon,
  className,
  valueClass,
}) => {
  const { t } = useTranslation();
  return (
    <Row container spaceBetween className={'info-container'}>
      <Col>
        <Row>
          <div style={{ width: '1.5rem' }}>{icon && <Icon icon={icon} />}</div>
          <div className={clsx(className)}>{t(fieldName)}</div>
        </Row>
      </Col>
      <Col flexEnd flex>
        <span className={clsx(className, valueClass)}>{value}</span>
      </Col>
    </Row>
  );
};
