import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import {enchoraCoordinates, USER_ROLES} from "../utils/const";

const initialState = {
  token: null,
  refreshToken: null,
  detail: {},
  coordinates: enchoraCoordinates,
  role: USER_ROLES.BUSINESS_USER,
  invitationId: undefined
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserToken: (state, action) => {
      state.token = action.payload
    },
    setUserRole: (state, action) => {
      state.role = action.payload
    },
    setUserRefreshToken: (state, action) => {
      state.refreshToken = action.payload
    },
    setUserDetail: (state, action) => {
      state.detail = action.payload
    },
    setUserCoordinates: (state, action) => {
      state.coordinates = action.payload
    },
    setUserInvitationId: (state, action) => {
      state.invitationId = action.payload
    }
  },
})

export const {
  setUserToken,
  setUserRefreshToken,
  setUserDetail,
  setUserRole,
  setUserCoordinates,
  setUserInvitationId
} = userSlice.actions

export default userSlice.reducer

export const userSelectors = {
  token: (state) => get(state, 'user.token') || null,
  refreshToken: (state) => get(state, 'user.refreshToken') || null,
  detail: (state) => get(state, 'user.detail') || {},
  role: (state) => get(state, 'user.role') || USER_ROLES.BUSINESS_USER,
  coordinates: (state) => get(state, 'user.coordinates'),
  invitationId: (state) => get(state, 'user.invitationId', undefined),
}


