import React, { useEffect, useState } from 'react';
import './InfluencerDetailPage.scss';
import { useHistory, useParams } from 'react-router-dom';
import { deleteInfluencerUser, getEnchorerInfluencerDetail } from '../../../api/enchorer';
import { Row, Col, InputText, Button } from '../../../components/ui';
import _ from 'lodash';
import { Image } from '../../../components/ui/image/image';
import { useFormik } from 'formik';
import { createCompanySchema } from '../../../utils/validations';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../components/layout/modals';
import { AppRoutes } from '../../../app/routers';

const InfluencerDetailPage = () => {
  const [data, setData] = useState({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { t } = useTranslation();
  let { infId } = useParams();
  const history = useHistory()

  useEffect(() => {
    getInfluencerData(infId);
  }, [infId]);

  const { handleSubmit, setFieldValue, isValid, setFieldError, errors, validateField, validateForm, setValues, values } = useFormik({
    initialValues: {
      ...data,
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      console.debug({ newInfluencerData: data });
    },
  });

  const getInfluencerData = async (infId) => {
    const data = await getEnchorerInfluencerDetail(infId);
    setFieldValue('firstName', data.firstName);
    console.debug({ inflencerDetail: data });
    setData(data);
  };

  const onChangeValue = (fieldName) => (e) => {
    console.debug({ fieldName, e });
    const value = e.target.value;
    setFieldError(fieldName, '');
    setFieldValue(fieldName, value);
  };

  const onClickDelete = () => {
    setShowModalDelete(true)
  }
  const onConfirmDelete = async () => {
    await deleteInfluencerUser(infId)
    history.replace(AppRoutes.influencerList());
  }

  return (
    <div id={'influencer-detail'}>
      <ConfirmModal onConfirm={onConfirmDelete} onClose={() => setShowModalDelete(false)} open={showModalDelete} title={t('delete user modal title')} subtitle={t('delete user modal description')}/>
      <Row alignCenter>
        <Image path={_.get(data, 'avatar.uri')} className={'inf-detail-image'} />
        <span>@{_.get(data, 'nickname', ' - ')}</span>
      </Row>
      <hr />
      <Row md={12} sm={12} xs={12} spaceBetween fullWidth>
        <Col md={4} sm={6} xs={12}>
          {/*<InputText label={t('firstName')} onChange={onChangeValue('firstName')} variant="outlined" error={errors.firstName} helperText={t(errors.firstName)} value={values.firstName} />*/}
          <Button text={t('delete user')} onClick={onClickDelete}/>
        </Col>


      </Row>
    </div>
  );
};

export default InfluencerDetailPage;
