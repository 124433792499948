import React, { useEffect, useMemo, useState } from 'react';
import './SocialConfiguratorPage.scss';
import { Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Col, Icon, InputFile, InputTextArea, Row, Image } from '../../components/ui';
import { useHistory } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { campaignSelectors, editCampaignSelected } from '../../features/campaignSlice';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getParameterFromUrl, getRandomNumber, printTextBreakLine } from '../../utils';
import { facebookId, FILE_TYPES, instagramId } from '../../utils/const';
import { AvatarInfluencer, FacebookFooter, InstagramPreviewImage, SaveIcon } from '../../assets';
import { uploadImage } from '../../api/upload';
import { Video } from '../../components/ui/image/video';
import { getCampaignDetail, getCampaignPrivate } from '../../api';

export const baseBusinessUnitContent = {
  images: [],
  video: {},
  info: '',
  instruction: '',
  socials: [],
};

const TABS = {
  INSTAGRAM: 'INSTAGRAM',
  FACEBOOK: 'FACEBOOK',
};

const SocialConfiguratorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = React.useState(null);
  const [campaign, setCampaign] = useState(useSelector(campaignSelectors.selected));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(_.get(campaign, 'contents.businessUnitContent.info'));

  const showInstagram = _.map(campaign.contents.influencerContent.socials, '_id').includes(instagramId);

  const showFacebook = _.map(campaign.contents.influencerContent.socials, '_id').includes(facebookId);

  const [file, setFile] = useState(null);

  const { cmpId, disabled } = useMemo(() => {
    return getParameterFromUrl(history.location.search);
  }, [history.location]);

  useEffect(() => {
    getCampaignData(cmpId);
  }, [cmpId]);

  const getCampaignData = async (cmpId) => {
    if (cmpId) {
      const campaignRes = await getCampaignPrivate(cmpId, false);
      console.debug({ campaignRes });
      if (campaignRes) {
        setCampaign(campaignRes);
      }
    }
  };

  useEffect(() => {
    const image = _.first(_.get(campaign, 'contents.businessUnitContent.images'));
    const video = _.get(campaign, 'contents.businessUnitContent.video');

    if (!_.isEmpty(image)) {
      setFile(image);
    }

    if (!_.isEmpty(video)) {
      setFile(video);
    }

    if (showInstagram && showFacebook) {
      setValue(TABS.INSTAGRAM);
    }

    if (showInstagram && !showFacebook) {
      setValue(TABS.INSTAGRAM);
    }

    if (!showInstagram && showFacebook) {
      setValue(TABS.FACEBOOK);
    }
  }, [campaign, showInstagram, showInstagram]);

  console.debug({ showFacebook, showInstagram });

  const onClose = () => {
    history.goBack();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onClickConfirm = async () => {
    let buContent = {};
    let uploadedFile;
    setLoading(true);

    let contents = {
      ...campaign.contents,
      businessUnitContent: {
        ...baseBusinessUnitContent,
        ...campaign.contents.businessUnitContent,
        info: description,
        instruction: description,
      },
    };

    if (file?.toUpload) {
      uploadedFile = await uploadImage(file.uri, `campaign/businessUnitContent/${getRandomNumber()}`, file.mimeType.includes('image') ? 'image' : 'video');
      console.log(uploadedFile);
      if (file.mimeType.includes('image')) {
        buContent = {
          images: [uploadedFile],
          video: {},
        };
      }
      if (file.mimeType.includes('video')) {
        buContent = {
          video: { ...uploadedFile },
          images: [],
        };
      }

      contents = {
        ...campaign.contents,
        ...contents,
        businessUnitContent: {
          ...contents.businessUnitContent,
          ...buContent,
        },
      };
    }

    console.log({ file, contents });
    dispatch(editCampaignSelected({ contents }));
    setLoading(false);
    onClose();
  };

  return (
    <div id={'social-configurator'}>
      <Card>
        <Typography variant="h2">{t('social configurator')}</Typography>
        <Box sx={{ width: '100%' }}>
          <Tabs value={value} onChange={handleChange} textColor="primary" centered allowScrollButtonsMobile indicatorColor="primary" className={'tabs'}>
            {showInstagram && (
              <Tab
                className={'tab'}
                value={TABS.INSTAGRAM}
                label={t(`tabs.${TABS.INSTAGRAM}`)}
                icon={<Icon icon={'fab fa-instagram'} color={'primary'} sx={{ padding: '0.5rem' }} />}
                iconPosition="start"
              />
            )}
            {showFacebook && (
              <Tab
                centerRipple
                className={'tab'}
                value={TABS.FACEBOOK}
                label={t(`tabs.${TABS.FACEBOOK}`)}
                icon={<Icon icon={'fab fa-facebook'} color={'primary'} sx={{ padding: '0.5rem' }} />}
                iconPosition="start"
              />
            )}
          </Tabs>
        </Box>
        <div className={'social-container-configurator'}>
          {value === TABS.INSTAGRAM && (
            <>
              <InstagramPreview campaign={campaign} description={description} file={file} />
              <Configurator setDescription={setDescription} description={description} file={file} setFile={setFile} campaign={campaign} disabled={disabled} />
            </>
          )}
          {value === TABS.FACEBOOK && (
            <>
              <Configurator setDescription={setDescription} description={description} file={file} setFile={setFile} campaign={campaign} disabled={disabled} />
              <FacebookPreview campaign={campaign} file={file} description={description} />
            </>
          )}
        </div>
        <Row spaceBetween fullWidth style={{ marginTop: '1rem' }}>
          <Button onClick={onClose} variant={'outlined'}>
            {t('undo')}
          </Button>
          <Button onClick={onClickConfirm} loading={loading} disabled={disabled}>
            {t('confirm')}
          </Button>
        </Row>
      </Card>
    </div>
  );
};

export default SocialConfiguratorPage;

const InstagramPreview = ({ file, description, campaign }) => {
  const { t } = useTranslation();

  console.log({ instagramPreview: campaign, file });
  const fileUri = _.get(file, 'uri', null);

  console.log({description})

  return (
    <Col justifyCenter alignCenter id={'instagram-wrapper'}>
      <Col flexStart fullWidth className={'title'}>
        <Typography variant="h4">{t('socialConfigurator.instagramPreviewTitle')}</Typography>
        <span>{t('socialConfigurator.instagramPreviewText')}</span>
      </Col>
      <div className={'instagram-preview'}>
        <div className={'instagram-preview-header'}>
          <img src={AvatarInfluencer} className={'ig-inf-avatar'} alt={''} />
          <span>{t('socialConfigurator.influencer')}</span>
        </div>
        <div className={'instagram-preview-body'}>
          {!fileUri && <Image source={InstagramPreviewImage} alt={''} className={'image-preview'} />}
          {file?.mimeType?.includes('image') && <Image source={InstagramPreviewImage} path={fileUri} uri={fileUri} alt={''} className={'image-preview'} />}
          {file?.mimeType?.includes('video') && <Video className={'video-player'} uri={fileUri} path={fileUri} width={'100%'} height={'90%'} loop={true} playing={true} muted={true} />}
        </div>
        <div className={'instagram-preview-bottom'}>
          <Row>
            <Icon icon={'far fa-heart'} className={'icon'} />
            <Icon icon={'far fa-comment'} className={'icon'} />
            <Icon icon={'far fa-paper-plane'} className={'icon'} />
          </Row>
          <img src={SaveIcon} alt={''} className={'icon'} />
        </div>
        <div className={'instagram-preview-footer'}>
          <span>{_.get(campaign, 'hashtag') || t('socialConfigurator.linkBio')}</span>
          <span className={'description'}>{printTextBreakLine(description) || ' - '}</span>
        </div>
      </div>
    </Col>
  );
};

const FacebookPreview = ({ file, description, campaign }) => {
  const { t } = useTranslation();

  console.log({ instagramPreview: campaign });
  const fileUri = _.get(file, 'uri', null);

  return (
    <Col justifyCenter alignCenter id={'instagram-wrapper'}>
      <Col flexStart fullWidth className={'title'}>
        <Typography variant="h4">{t('socialConfigurator.facebookPreviewTitle')}</Typography>
        <span>{t('socialConfigurator.facebookPreviewText')}</span>
      </Col>
      <div className={'instagram-preview'}>
        <div className={'instagram-preview-header'}>
          <img src={AvatarInfluencer} className={'ig-inf-avatar'} alt={''} />
          <Col style={{ paddingLeft: '0.5rem' }}>
            <span>{t('socialConfigurator.influencer')}</span>
            <span className={'time'}>{t('socialConfigurator.time')}</span>
          </Col>
        </div>
        <div>
          <span className={'description'}>{printTextBreakLine(description) || ' - '}</span>
        </div>
        <div className={'instagram-preview-body'}>
          {file?.mimeType?.includes('image') && <Image source={InstagramPreviewImage} path={fileUri} uri={fileUri} alt={''} className={'image-preview'} />}
          {file?.mimeType?.includes('video') && <Video className={'video-player'} uri={fileUri} path={fileUri} width={'85%'} height={'90%'} loop={true} playing={true} muted={true} />}
          {!fileUri && <Image source={InstagramPreviewImage} alt={''} className={'image-preview'} />}
        </div>
        <div className={'facebook-preview-bottom'}>
          <img src={FacebookFooter} alt={''} className={'fb-footer'} />
        </div>
      </div>
    </Col>
  );
};

export const Configurator = ({ setFile, campaign, description, setDescription, disabled = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fileLoading, setFileLoading] = useState(false);
  const [fileType, setFileType] = useState(FILE_TYPES.IMAGE);

  const onUploadImage = async (file) => {
    setFileLoading(true);
    setFile({
      ...file,
      mimeType: file.type,
      toUpload: true,
    });

    setFileLoading(false);
  };

  return (
    <div className={'wrapper'}>
      <Col>
        <Col flexStart fullWidth className={'title'}>
          <Typography variant="h4">{t('socialConfigurator.configuratorTitle')}</Typography>
          <span>{t('socialConfigurator.configuratorText')}</span>
        </Col>
        <Grid container>
          <Grid item md={4} sm={12}>
            <FormControl component="fieldset" disabled={disabled}>
              <RadioGroup value={fileType} onChange={(v) => setFileType(v.target.value)}>
                <FormControlLabel
                  value={FILE_TYPES.IMAGE}
                  control={<Radio sx={{ paddingTop: '0px', marginTop: '0px' }} />}
                  style={{ marginTop: '0.3rem' }}
                  label={<span>{t(FILE_TYPES.IMAGE)}</span>}
                />
                <FormControlLabel
                  value={FILE_TYPES.VIDEO}
                  control={<Radio sx={{ paddingTop: '0px', marginTop: '0px' }} />}
                  style={{ marginTop: '0.3rem' }}
                  label={<span>{t(FILE_TYPES.VIDEO)}</span>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Col item md={8} sm={12} justifyCenter alignCenter>
            <span>{t(fileType === FILE_TYPES.IMAGE ? 'socialConfigurator.images' : 'socialConfigurator.video')}</span>
          </Col>
        </Grid>
      </Col>
      <Row>
        <InputFile
          onUpload={onUploadImage}
          returnAll
          disabled={disabled}
          label={fileType === FILE_TYPES.IMAGE ? t('socialConfigurator.uploadImage') : t('socialConfigurator.uploadVideo')}
          loadingProp={fileLoading}
          className={'file-button'}
          accept={fileType === FILE_TYPES.IMAGE ? '.jpeg,.png,.jpg' : '.mp4'}
          buttonStyle={{
            width: '10rem',
            height: '2rem',
            borderRadius: '0.5rem',
          }}
        />
      </Row>
      <Col alignCenter justifyCenter>
        <InputTextArea disabled={disabled} className={'social-bio'} label={t('description')} onChange={setDescription} value={description} touched={true} variant={'outlined'} rows={'5'} />
      </Col>
    </div>
  );
};
