import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, Button, Col, InputSelect, InputTextArea, Row } from '../../ui';
import { Card, Typography } from '@mui/material';
import './ReportModal.scss';
import { ABUSE_TYPES } from '../../../utils/const';
import { getReportAbuseReason, reportAbuse } from '../../../api';

export const ReportModal = ({ open, onClose, resourceType = ABUSE_TYPES.INFLUENCER, resourceId }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [reason, setReason] = useState({
    value: undefined,
    error: '',
  });
  const [message, setMessage] = useState('');

  useEffect(() => {
    getOptions();
  }, [open]);

  const getOptions = async () => {
    const data = await getReportAbuseReason(resourceType);
    console.debug({ data });
    setOptions(data);
  };

  const onChangeReasons = (value) => {
    setReason({ value, error: '' });
  };

  const resetValues = () => {
    setReason({ value: undefined, error: '' });
    setMessage('');
  };

  const onReportInfluencer = async () => {
    if (!reason.value) {
      setReason({ ...reason, error: 'required field' });
      return;
    }

    try {
      const abuseData = {
        message: message,
        reason: { _id: reason.value },
      };
      await reportAbuse(abuseData, resourceType, resourceId);
      onClose();
      resetValues();
    } catch (e) {
      console.debug({ e });
      onClose();
    }
  };

  return (
    <BaseModal open={open} onClose={onClose}>
      <Card id={'modal-confirm'}>
        <Typography variant="h2">{t('reportInfluecer')}</Typography>
        <Col className={'subtitle'}>
          <Typography variant="body">{t('selectMotivationReport')}</Typography>
        </Col>
        <Col>
          <InputSelect
            style={{ width: '100%' }}
            variant={'outlined'}
            label={t('reportMotivation')}
            emitValue
            value={reason.value}
            error={reason.error}
            helperText={t(reason.error)}
            optionLabel={'description'}
            optionValue={'_id'}
            onChange={onChangeReasons}
            options={options}
          />
        </Col>
        <Col style={{ marginTop: '1rem' }}>
          <InputTextArea style={{ borderRadius: 16 }} rows="4" onChange={setMessage} value={message} placeholder={t('message')} maxLength={200} />
        </Col>
        <Row flex spaceBetween style={{ marginTop: 16 }}>
          <Button onClick={onClose} variant={'outlined'}>
            {t('undo')}
          </Button>
          <Button onClick={onReportInfluencer}>{t('confirm')}</Button>
        </Row>
      </Card>
    </BaseModal>
  );
};
