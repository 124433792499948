import React from 'react';
import './HomeBanner.scss';
import { Grid, Typography } from '@mui/material';
import { Button, Row } from '../../ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../app/routers';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';

const HomeBanner = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userToken = useSelector(userSelectors.token);

  const onRegisterCompany = () => {
    userToken ? history.push(AppRoutes.createCompany()) : history.push(AppRoutes.login());
  };

  return (
    <Row alignCenter justifyCenter id={'home-banner'}>
      <Grid container md={10} className={'container'} justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="bold">{t('home banner text')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth text={t('click here')} onClick={onRegisterCompany} size={'small'} />
        </Grid>
      </Grid>
    </Row>
  );
};

export default HomeBanner;
