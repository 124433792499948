import { outlinedInputClasses } from '@mui/material/OutlinedInput'

import stylesInputText from './../../components/ui/input/inputText.scss'
import colors from './../../../src/app/colors.module.scss'

const MuiTextField = (theme) => ({
  styleOverrides: {
    root: {
      borderWidth: 2,
      [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: colors['brand-black'],
          borderRadius: stylesInputText['border-radius']
        }
    }
  }
})

export default MuiTextField
