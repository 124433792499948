import React, { useEffect } from 'react';
import { Container } from '../components/ui';
import { userSelectors } from '../features/userSlice';
import {
  assignCampaignToBusinessUnit,
  checkUserInvitationAndAcceptIt,
  getBusinessUnit,
  getBusinessUnitCampaigns,
  getBusinessUnitInvitations,
  getBusinessUnitList,
  getCampaignPublic,
  getCataloguesAndSaveInState,
  getHomeCounters,
  getMetricsGanttHome,
} from '../api';
import HomeBanner from '../components/layout/home/HomeBanner';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { isCurrentUserEnchorer } from '../api/enchorer';
import { businessUnitSelectors } from '../features/businessUnitSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { campaignSelectors, setCampaignSelected } from '../features/campaignSlice';
import { ConfirmModal } from '../components/layout/modals';
import { useModal } from '../utils/hook';
import { AppRoutes } from '../app/routers';
import { toast } from 'react-toastify';
import { translate } from '../i18n';
import { TOAST } from '../utils/const';
import HomeHeader from '../components/layout/home/HomeHeader';
import HomeGantt from '../components/layout/home/HomeGantt';
import HomePerformances from '../components/layout/home/HomePerformances';

const HomePage = () => {
  const invitationId = useSelector(userSelectors.invitationId);
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const userDetail = useSelector(userSelectors.detail);
  const dispatch = useDispatch();
  const campaignSelected = useSelector(campaignSelectors.selected);
  const [modal, { open, close }] = useModal();
  const userToken = useSelector(userSelectors.token);
  const showBannerBusinessUnit = !businessUnit || _.isEmpty(businessUnit);
  const history = useHistory();
  const location = useLocation();
  console.log({ history, location });

  const checkCampaign = async () => {
    // SPOSTATO LA LOGICA NELL'HEADER => controllo che la campagna selezionata non abbia la bu, in questo caso mostro il popup
    //Controlla se ci sono delle campagne salvate nello stato.
    //SE abbiamo delle campagne chiediamo all'utente se le vuole continuare -> allora va al primo step della campagna
    //Se invece per qualche motivo devide di eliminarla gli diciamo ciao scemo
    //Per evitare che tutte le volte si apra faccio il controllo che la campagna in question sia pubblica e non privata

    // if (!userToken) {
    //   return;
    // }

    // if (campaignSelected && campaignSelected._id && campaignSelected.businessUnit) {
    //   const publicCampaign = await getCampaignPublic(campaignSelected._id);
    //   console.debug({ publicCampaign });
    //   publicCampaign && open();
    // }
  };

  useEffect(() => {
    getCataloguesAndSaveInState();
    checkCampaign();
    if (userToken) {
      getMetricsGanttHome();
      getHomeCounters();
    }
  }, [userToken]);

  const onConfirmModal = async () => {
    if (!businessUnit._id && _.isEmpty(userDetail)) {
      toast(translate('business unit required'), TOAST.WARNING);
      history.push(AppRoutes.createCompany());
    } else {
      assignCampaignToBusinessUnit(campaignSelected._id);
      history.push(`${AppRoutes.campaign('new')}/${campaignSelected.typeof}`);
    }
  };

  const onCloseModal = async () => {
    // await deletePublicCampaign(campaignSelected._id);
    dispatch(setCampaignSelected({}));
    close();
  };

  return (
    <Container style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmModal
        onConfirm={onConfirmModal}
        open={modal}
        onClose={onCloseModal}
        title={'outstanding campaign'}
        subtitle={'want to complete your campaign'}
        textUndo={'undo'}
        textConfirm={'continue'}
        notCloseOnConfirm={true}
      />
      {showBannerBusinessUnit ? (
        <HomeBanner />
      ) : (
        <>
          <HomeHeader />
          <HomeGantt />
          <HomePerformances />
        </>
      )}
    </Container>
  );
};

export default HomePage;
