import { Container, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './SentEmailPage.scss';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ImageBg1 } from '../../../assets';
import { Row } from '../../../components/ui';
import _ from 'lodash';
import { createFirebaseUser, forgotPassword } from '../../../api/auth';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import { getParameterFromUrl } from '../../../utils';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import { TOAST } from '../../../utils/const';

const SentEmailPage = () => {
  const { t } = useTranslation();
  const { state: locationState } = useLocation();
  const history = useHistory();
  const { email } = useSelector(userSelectors.detail);

  const handleClickRetry = async () => {
    console.log({ locationState });
    const params = getParameterFromUrl(history.location.search);
    if (
      _.isEqual(_.get(locationState, 'action', ''), 'forgot_password') &&
      email &&
      locationState?.action
    ) {
      const forgotEmailSuccess = await forgotPassword(email);
      console.log({ forgotEmailSuccess });
      if (!forgotEmailSuccess) {
        console.log('PREPARARE POPUP DI ERROR GENERICO PER TUTTA LA WEBAPP');
      }
      return;
    }

    if (_.get(params, 'email')) {
      await createFirebaseUser(params.email);
      toast(translate('emailRegisterSentSuccess'), TOAST.SUCCESS);
    }

    //window.location.reload()
  };

  return (
    <div id={'sent-email'}>
      <Container p={2} className={'container'}>
        <h1>{t('signup verify title')}</h1>

        <p>{t('signup verify text 1')}</p>

        <p>{t('signup verify text 2')}</p>

        <p>
          {t('signup verify text 3')}
          {'  '}
          <Link onClick={handleClickRetry} className={'cursor'}>
            {t('click here')}
          </Link>{' '}
          {t('signup verify text 4')}
        </p>

        <p>
          {t('signup verify text 5')}
          {'  '}
          <Link href="https://enchora.com/contact" target={'_blank'}>
            {t('contact us').toLowerCase()}
          </Link>{' '}
          {t('signup verify text 6')}
        </p>

        <Row justifyCenter>
          <img src={ImageBg1} alt={''} className={'image'} />
        </Row>
      </Container>
    </div>
  );
};

export default SentEmailPage;
