import stylesButton from './../../components/ui/button/button.module.scss'

const MuiButton = (theme) => ({
  styleOverrides: {
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: theme.typography.body1.fontSize
    },
    sizeSmall: {
      textTransform: 'none',
      height: stylesButton['height--small'],
      borderRadius: stylesButton['border-radius--medium']
    },
    sizeMedium: {
      height: stylesButton['height--medium'],
      textTransform: 'none',
      borderRadius: stylesButton['border-radius--medium']
    },
    startIcon: {
      textTransform: 'none',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '2em',
      width: '2em',
      padding: '.25em',
      borderRadius: '100%'
    }
  }
})

export default MuiButton
