import React, { memo } from 'react';
import './companyData5.scss';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Button, InputTelephone, InputText } from '../../ui';
import { patchBusinessUnit } from '../../../api';
import { useFormik } from 'formik';
import { createCompanySchema } from '../../../utils/validations';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { isAdminOfBusinessUnit } from '../../../utils';

export const CreateCompanyData5 = memo(({ showBack = true }) => {
  const { t } = useTranslation();
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const userDetail = useSelector(userSelectors.detail);
  const disableForm = !isAdminOfBusinessUnit();

  const { handleSubmit, setFieldValue, errors, validateField, validateForm, values } = useFormik({
    initialValues: {
      email: businessUnit?.contact?.mail?.mail || userDetail?.account?.email,
      prefix: businessUnit?.contact?.phone?.prefix,
      number: businessUnit?.contact?.phone?.number,
    },
    validationSchema: createCompanySchema.page5,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      validateForm().then(async () => {
        const contact = {
          mail: { mail: data.email },
          phone: {
            prefix: data.prefix,
            number: data.number,
          },
        };
        await patchBusinessUnit({ contact });
      });
    },
  });

  const onChangeValue = async (value, fieldName) => {
    await setFieldValue(fieldName, value);
    await validateField(fieldName);
  };

  const onChangeTelephone = async (e) => {
    await onChangeValue(e[0], 'prefix');
    await onChangeValue(e[1], 'number');
  };

  const onChangeContact = async (e) => {
    await onChangeValue(e.target.value, 'email');
  };

  return (
    <Grid id={'create-company-data5'}>
      <Typography variant={'h1'}>{t('contacts')}</Typography>
      <Typography variant={'h6'}>{t('company contacts subtitle')}</Typography>
      <Grid xs={6} container className={'form'}>
        <Grid xs={12}>
          <InputText
            label={t('contact email')}
            name="email"
            value={values.email}
            onChange={onChangeContact}
            variant="standard"
            error={errors.email}
            helperText={t(errors.email)}
            disabled={disableForm}
          />
        </Grid>
        <Grid xs={12} style={{ marginTop: 10 }}>
          <InputTelephone
            numberValue={values.number}
            prefixValue={values.prefix}
            errors={[errors.prefix, errors.number]}
            disabled={disableForm}
            prefixes={[
              {
                value: '39',
                label: '+39',
              },
            ]}
            onChange={onChangeTelephone}
            label={t('telephone')}
            name="telephone"
          />
        </Grid>
      </Grid>

      <Button disabled={disableForm} text={t('save')} onClick={handleSubmit} className={'save-button'} />
    </Grid>
  );
});
