import React, {createRef, useEffect, useState} from 'react';
import './editImage.scss'
import {Box, Card, Typography} from '@mui/material';
import {BaseModal} from '../modal/baseModal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import {Row} from '../row/row';
import {Button} from '../button/button';
import {useTranslation} from 'react-i18next';
import {Col} from '../col/col';

const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      },
      'image/jpeg',
      1
    );
  });
}

export const EditImage = ({url, onEdit, onClose, image}) => {

  const { t } = useTranslation()
  const [crop, setCrop] = useState({ aspect: 1 });
  const [blob, setBlob] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [img, setImg] = useState(null);

  const onClickSave = async () => {
    onEdit(croppedImageUrl)
    onClickClose()
  }
  const onClickClose = () => {
    onClose()
  }

  return (
    <BaseModal open={!!url} propStyle={{maxWidth: '80vw', width: '80vw'}}>
      <Typography variant='h5'>{t('resize image popup detail')}</Typography>
      <Card id={'edit-image'}>
        <Box className={'box-image'}>
          {!!url && <ReactCrop
            imageStyle={{objectFit: 'contain', maxHeight: '70vh'}}
            src={url}
            crop={crop}
            onImageLoaded={img => setImg(img)}
            onChange={newCrop => setCrop(newCrop)}
            onComplete={async (crop) => {
              // if (blob) window.URL.revokeObjectURL(blob); // clear previous blob
              if (img) {
                const resUrl = await getCroppedImg(img, crop, 'croppedImage');
                setCroppedImageUrl(resUrl)
              }
            }}
            circularCrop={true}
          />}
        </Box>
        <Row spaceBetween style={{marginTop: '1rem'}}>
          <Button text={t('undo')} className={'button'} onClick={onClickClose}/>
          <Button text={t('save')} className={'button'} onClick={onClickSave} disabled={!croppedImageUrl}/>
        </Row>
      </Card>
    </BaseModal>
  )
}

