import React, {memo} from 'react'
import './ProfileSidebar.scss'
import {useTranslation} from 'react-i18next';
import {Box, Grid, Link} from '@mui/material';
import {Icon, NavRegistration} from '../../ui';
import {useHistory, useLocation} from 'react-router-dom';
import _ from 'lodash';

const STEP_ICONS = {
  PROFILE: 'far fa-user',
  PRIVACY: 'far fa-shield'
}

const ITEMS = {
  PROFILE: 'profile',
  PRIVACY: 'privacy'
}

export const ProfileSidebar = memo(({ showBack = true, active }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation();

  const handleClickNav = (step) => {
    if(step.href === 'profile'){
      history.push(`/profile`)
    } else {
      history.push(`/profile/${step.href}`)
    }

  }

  return (
    <Grid item xs={12} md={3} id={'profile-sidebar'} >
      <Box
        sx={{ display: 'flex' }}
        justifyContent='space-between'
        flexDirection='column'
        height='100%'
      >
        <NavRegistration
          onClick={handleClickNav}
          items={_.map(ITEMS, (stepName) => ({
            label: t(stepName.replace('-', ' ')),
            href: stepName,
            icon: <Icon icon={STEP_ICONS[stepName.toUpperCase()]}/> ,
            selected: _.split(location.pathname, '/').pop() === _.toLower(stepName),
          }))}
        />
        <Link
          underline='hover'
          color='text.disabled'
        >
        </Link>
      </Box>
    </Grid>
  )
})
