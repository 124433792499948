import React from 'react';
import { Row } from '../row/row';
import { Col } from '../col/col';
import { Checkbox } from './checkbox';
import './CheckboxRow.scss';

export const CheckboxRow = ({ checked, onChangeChecked, children }) => {
  return (
    <Row
      fullWidth
      md={12}
      sm={12}
      xs={12}
      style={{ marginTop: '0.5rem' }}
      id="checkbox-row"
    >
      <Col noFlex className={'checkbox-container'}>
        <Checkbox checked={checked} name={''} onChange={onChangeChecked} />
      </Col>
      <div className={'privacyContent'}>{children}</div>
    </Row>
  );
};
