import React, { memo } from 'react'
import clsx from "clsx";
import "./row.scss"
import {Grid} from '@mui/material';

export const Row = memo(({
  children,
  className,
  spaceBetween,
  spaceAround,
  alignCenter,
  alignStart,
  alignEnd,
  justifyCenter,
  justifyEnd,
  justifyStart,
  flex,
  style,
  fullWidth,
  noFlex,
  ...props
}) => {

  let alignItems = undefined
  let justifyContent = undefined
  if (alignCenter) {
    alignItems = 'center'
  }
  if (alignEnd) {
    alignItems = 'flex-end'
  }
  if (alignStart) {
    alignItems = 'flex-start'
  }

  if (spaceBetween) {
    justifyContent = 'space-between'
  }
  if (spaceAround) {
    justifyContent = 'space-around'
  }
  if (justifyCenter) {
    justifyContent = 'center'
  }
  if (justifyEnd) {
    justifyContent = 'flex-end'
  }
  if(justifyStart) {
    justifyContent = 'flex-start'
  }

  const rowStyle = {
    justifyContent,
    alignItems,
    flex: flex ? 1 : noFlex ? 0 : undefined,
    width: fullWidth ? '100%' : undefined,
    display: 'flex',
    flexDirection: 'row',
    ...style
  }


  return (
    <Grid {...props} style={rowStyle} className={clsx(className)} container>
      {children}
    </Grid>
  )
})

