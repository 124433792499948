import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { Link } from '@mui/material';
import { auth } from '../../../lib/firebase';

import { patchBusinessUser } from '../../../api';
import { AUTH_ERROR_INVALID_LINK } from '../../../utils/const';
import CardSignup, { CARD_SIGNUP_STEP_PASSWORD, CARD_SIGNUP_STEP_PROFILE, CARD_SIGNUP_STEP_WELCOME } from './../cards/CardSignup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import { logout, setPassword, signInEmailLink } from '../../../api/auth';
import { AppRoutes } from '../../../app/routers';
import { BaseModal } from '../../ui';

const ModalSignup = ({ locationState }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const userDetail = useSelector(userSelectors.detail);
  const userToken = useSelector(userSelectors.token);
  const [step, setStep] = useState(CARD_SIGNUP_STEP_WELCOME);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  //get params e poi faccio  la singin con l'oobCode se l'opzioneè quella di verifica email

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      console.log({ firebaseUser });
      setFirebaseUser(firebaseUser);
      if (firebaseUser && !userToken) {
      }
    });

    console.log({ locationState, userDetail });
    let locationStep;
    if (locationState) {
      const { step } = locationState;
      locationStep = step;
    }

    const { firstName, lastName, account, companyRole } = userDetail;
    if (firstName && lastName && account?.email) {
      setStep(CARD_SIGNUP_STEP_WELCOME);
    } else {
      setStep(CARD_SIGNUP_STEP_PASSWORD);
    }
    if (locationStep) {
      setStep(locationStep);
    }
  }, []);

  useEffect(() => {
    if (error?.code === AUTH_ERROR_INVALID_LINK) {
      setErrorMessage(t('ModalSignup.invalidLink'));
      return;
    }
    if (error?.code) {
      setErrorMessage(t('ModalSignup.genericError'));
    }
  }, [error, t]);

  useEffect(() => {
    console.log({ firebaseUser });
    // if (!loading) {
    //   if (
    //     firebaseUser &&
    //     firebaseUser.providerData[0].providerId !== 'password'
    //   ) {
    //     setStep(CARD_SIGNUP_STEP_WELCOME)
    //     return
    //   }
    //   if (firebaseUser) {
    //     setStep(CARD_SIGNUP_STEP_PASSWORD)
    //     return
    //   }
    //   setStep(CARD_SIGNUP_STEP_EMAIL)
    // }
  }, [loading, firebaseUser]);

  const handleSignOut = async () => {
    await logout();
    history.push(AppRoutes.login());
  };

  const handleSubmitEmail = async (email) => {
    await signInEmailLink(email);
    setStep(CARD_SIGNUP_STEP_PASSWORD);
  };

  const handleSubmitPassword = async (password) => {
    await setPassword(password);
    setStep(CARD_SIGNUP_STEP_PROFILE);
  };

  const handleSubmitProfile = async ({ firstName, lastName, companyRole }) => {
    console.log({ firstName, lastName, companyRole });

    await patchBusinessUser({
      firstName,
      lastName,
      companyRole,
    });

    history.push(AppRoutes.home());
  };

  if (loading) {
    return null;
  }

  const [firstName, ...lastName] = firebaseUser?.displayName?.split(' ') || [];

  return (
    <BaseModal open={true} propStyle={{ minWidth: '50vh' }}>
      <CardSignup
        step={step}
        user={{
          firstName: userDetail?.firstName,
          lastName: userDetail?.lastName,
          email: userDetail?.account?.email,
          img: firebaseUser?.photoURL,
        }}
        onSubmitEmail={handleSubmitEmail}
        onSubmitPassword={handleSubmitPassword}
        onSubmitProfile={handleSubmitProfile}
        onSignout={handleSignOut}
        loading={loading}
        errorMessage={errorMessage}
        LinkTermsAndConditions={<Link href="#">{t('contract licence')}</Link>}
        LinkPrivacyPolicy={<Link href="#">{t('privacy info')}</Link>}
        LinkCookiePolicy={<Link href="#">{t('cookies info')}</Link>}
      />
    </BaseModal>
  );
};

export default ModalSignup;
