import React from "react";
import "./ProfilePage.scss";
import { Grid } from "@mui/material";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Profile } from "../../components/layout/profile/Profile";
import { ProfilePrivacy } from "../../components/layout/profile/ProfilePrivacy";
import { ProfileSidebar } from "../../components/layout/profile/ProfileSidebar";

const ProfilePage = () => {
  const { path } = useRouteMatch();

  return (
    <div id={"profile-page"}>
      <Grid container>
        <ProfileSidebar />
        <Grid item md={9} xs={12} style={{ paddingTop: "1.5rem" }}>
          <Switch>
            <Route exact path={"/profile"} component={Profile} />
            <Route exact path={"/profile/privacy"} component={ProfilePrivacy} />
            <Redirect path={`${path.replace(/\/$/, "")}`} to={"/profile"} />
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfilePage;
