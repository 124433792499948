import React from 'react';
import { BaseModal, Button, Row } from '../../../ui';
import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CampaignModalRecapBrief.scss';

export const CampaignModalRecapBrief = ({ open, onClose, brief }) => {
  const { t } = useTranslation();

  return (
    <BaseModal open={open} propStyle={{ minWidth: '60vw', maxWidth: '60vw' }}>
      <Card id={'campaign-modal-recap-brief'}>
        <Typography variant="h3" style={{ marginBottom: '2rem' }}>
          {t('brief')}
        </Typography>

        <Row sm={12} className={'images'} justifyCenter>
          <div dangerouslySetInnerHTML={{ __html: brief }} />
        </Row>

        <Row justifyEnd>
          <Button onClick={onClose} variant={'outlined'}>
            {t('close')}
          </Button>
        </Row>
      </Card>
    </BaseModal>
  );
};
