import React, { useEffect } from 'react';
import './MyCampaignPage.scss';
import { Grid, Typography } from '@mui/material';
import { BaseDialog, Button, Col, Row } from '../../components/ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../features/userSlice';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../app/routers';
import { getBusinessUnitCampaigns } from '../../api';
import { businessUnitSelectors } from '../../features/businessUnitSlice';
import _ from 'lodash';
import { CampaignCartRow } from '../../components/layout/campaign/CampaignCartRow';

const MyCampaignPage = () => {
  const userDetail = useSelector(userSelectors.detail);
  const businessUnitSelected = useSelector(businessUnitSelectors.selected);
  const businessUnitCampaigns = useSelector(businessUnitSelectors.campaigns);
  const [openModal, setOpenModal] = React.useState({});
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    getBusinessUnitCampaigns();
  }, [businessUnitSelected]);

  const goToCreateCampaign = () => {
    history.push(AppRoutes.campaign('new'));
  };

  const onOpenRejectedMessages = (selectedCampaign) => {
    const rejectedMessage = _.get(selectedCampaign, 'rejectNotes', []);
    let description = '';
    rejectedMessage.forEach((mes) => {
      description += `- ${mes} <br>`;
    });
    setOpenModal({
      title: t('rejected messages'),
      description: description,
    });
  };

  const onCloseModal = () => {
    setOpenModal({});
  };

  return (
    <Grid container id={'my-campaign-page'} spacing={4}>
      <BaseDialog open={!_.isEmpty(openModal)} onClose={onCloseModal} title={openModal.title} text={openModal.description} />
      <Grid item xs={11} md={9}>
        <Row md={12} flex spaceBetween alignCenter>
          <Col md={7} xs={12}>
            {_.isEmpty(businessUnitCampaigns) ? (
              <Typography variant={'h1'}>{t('not campaigns yet')}</Typography>
            ) : (
              <Typography variant={'h1'}>
                {t('campaign of')} {userDetail.firstName} {userDetail.lastName}
              </Typography>
            )}
          </Col>
          <Col md={5} xs={12} flexEnd justifyCenter>
            <Button text={t('create new campaign')} onClick={goToCreateCampaign} fullWidth style={{ paddingLeft: '1rem', paddingRight: '1rem' }} />
          </Col>
        </Row>

        <Col mt="2rem">
          {_.map(businessUnitCampaigns, (campaign) => (
            <CampaignCartRow campaign={campaign} detailPage onOpenRejectedMessages={onOpenRejectedMessages} />
          ))}
        </Col>
      </Grid>
    </Grid>
  );
};

export default MyCampaignPage;
