import { store } from '../app/store';
import { campaignSelectors } from '../features/campaignSlice';
import _ from 'lodash';
import { businessUnitSelectors } from '../features/businessUnitSlice';
import { metricSelectors, setHomeCounters, setHomeGanttCampaigns } from '../features/metricSlice';
import { campaignStatus, DATE_TIME_UNITS_API_NEW, defaultListObj, FRAME_TYPE } from '../utils/const';
import { DateTime } from 'luxon';
import { campaignStatisticUnitBasedOnFiltersDate, dateFromKey, fromMillisToDate, getTimeUnitFromFilters, normalizedDataBasedOnDates, normalizeFilters } from '../utils';
import axios from 'axios';
import { emptyDataFromDate, mergeEmptyDataAndApiResult, normalizeDataFromApi } from '../utils/metrics';

export const getMetricsGanttHome = async (ganttLocalFilter) => {
  const state = store.getState();
  const filters = _.cloneDeepWith(campaignSelectors.homeGanttFilter(state));
  const businessUnitSelected = businessUnitSelectors.selected(state);
  const businessUnitId = businessUnitSelected._id;

  const params = {
    params: normalizeFilters({
      pipes: [
        {
          $match: {
            bunId: businessUnitId,
            cmpId: _.isEqual(_.get(filters, 'cmpId'), 'allCampaign') ? undefined : _.get(filters, 'cmpId'),
          },
        },
        {
          $lookup: {
            from: 'Campaign',
            localField: 'cmpId',
            foreignField: '_id',
            as: 'campaign',
          },
        },
        {
          $unwind: {
            path: '$campaign',
            preserveNullAndEmptyArrays: false,
          },
        },
        {
          $project: {
            cmpId: 1,
            bunId: 1,
            campaign: {
              budget: 1,
              completionRate: 1,
              duration: 1,
              name: 1,
              typeof: 1,
              campaignStatus: 1,
              _id: 1,
            },
          },
        },
        {
          $match: normalizeFilters({
            'campaign.typeof': _.isEqual(_.get(filters, 'typeof'), 'allTypes') ? undefined : _.get(filters, 'typeof'),
            'campaign.campaignStatus': _.isEqual(_.get(filters, 'campaignStatus'), 'allStatus') ? undefined : _.get(filters, 'campaignStatus'),
            'campaign.duration.begin.ts': normalizeFilters({
              $gte: _.get(filters, 'duration.begin.ts', undefined),
            }),
            'campaign.duration.end.ts': normalizeFilters({
              $lte: _.get(filters, 'duration.end.ts', undefined),
            }),
            'campaign.completionRate': {
              $gte: Number(Number(_.get(filters, 'minCompletionRate', 0)) / 100),
              $lte: Number(Number(_.get(filters, 'maxCompletionRate', 100)) / 100),
            },
          }),
        },
        {
          $lookup: {
            from: 'InfluencerCampaignRelation',
            localField: 'cmpId',
            foreignField: 'cmpId',
            as: 'influencer',
          },
        },
        {
          $project: {
            bunId: 1,
            cmpId: 1,
            campaign: 1,
            numberOfInfluencers: { $size: '$influencer' },
          },
        },
      ],
      // native: normalizeFilters(native),
    }),
  };
  try {
    const { data } = await axios.get(`/public/ql/business-units-campaigns`, { params });
    const mapped = _.map(data.data, (res) => {
      return {
        ...res.campaign,
        numberOfInfluencers: _.get(res, 'numberOfInfluencers'),
      };
    });
    console.debug({ data, mapped, params });
    store.dispatch(setHomeGanttCampaigns(mapped));

    return mapped;
  } catch (e) {
    console.log({ getHomeCounterError: e, params });
  }
};

export const getMetricsHistogramHome = async (filters) => {
  const state = store.getState();
  console.debug({ filters });
  const businessUnitSelected = businessUnitSelectors.selected(state);
  const bunId = businessUnitSelected._id;

  const params = {
    params: normalizeFilters({
      params: _.omitBy(
        {
          query: _.omitBy(
            {
              cmpId: _.isEqual(_.get(filters, 'cmpId'), 'allCampaign') ? undefined : _.get(filters, 'cmpId'),
              campaignType: _.isEqual(_.get(filters, 'typeof'), 'allTypes') ? undefined : _.get(filters, 'typeof'),
              campaignStatus: _.isEqual(_.get(filters, 'campaignStatus'), 'allStatus') ? undefined : _.get(filters, 'campaignStatus'),
              startTs: _.get(filters, 'from') || undefined,
              endTs: _.get(filters, 'to') || undefined,
            },
            _.isUndefined
          ),
        },
        _.isEmpty
      ),
    }),
  };

  console.debug({ homeHistogramParams: params });

  try {
    const { data } = await axios.get(`/private/business-units/${bunId}/histogram`, params);
    const histogramHomeData = data.data;
    console.debug({ histogramHomeData });
    return histogramHomeData;
  } catch (e) {
    console.debug({ histogramHomeDataError: e });
    return [];
  }
};

//UTILS
//metricFilter passed to this function must have created field
const normalizedDataBasedOnFilterDate = (units, initialData, metricsFilters) => {
  const state = store.getState();

  const from = fromMillisToDate(metricsFilters?.created?.$gt);
  const to = fromMillisToDate(metricsFilters?.created?.$lte);

  const diffObj = to.diff(from, [units.unit]).toObject();
  const numberUnits = new Array(_.round(diffObj[units.unit]));
  // console.log({diffObj, numberUnits});

  const result = _.reduce(
    numberUnits,
    (acc, it, index) => {
      // console.log('plus', { [units.unit]: index});
      const dateAtIterationNumber = from.plus({ [units.unit]: index });
      const date = dateAtIterationNumber.diff(DateTime.fromObject({ years: from.year }).startOf('years'), [units.unit]);
      const baseUnit = from.toObject();
      // console.log({acc, baseUnit, date: dateAtIterationNumber.toISODate()});

      //KEY FORMAT = gg:set:month:quarter:anno:
      let key;
      if (units.apiUnit === FRAME_TYPE.DAILY) {
        key = `${_.ceil(date[units.unit])}:${dateAtIterationNumber.weekNumber}:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
      }
      if (units.apiUnit === FRAME_TYPE.WEEKLY) {
        key = `*:${dateAtIterationNumber.weekNumber}:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
      }
      if (units.apiUnit === FRAME_TYPE.MONTHLY) {
        key = `*:*:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
      }
      if (units.apiUnit === FRAME_TYPE.QUARTERLY) {
        key = `*:*:*:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
      }
      if (units.apiUnit === FRAME_TYPE.YEARLY) {
        key = `*:*:*:*:${dateAtIterationNumber.year}`;
      }
      const dataFromApi = _.find(initialData, (data) => _.isEqual(data._id.key, key));
      const obj = {
        _id: { key },
        count: 0,
        total: 0,
        ...dataFromApi,
      };

      acc.push(obj);
      return acc;
    },
    []
  );

  console.log({ result });
  return result || [];
};

export const getHomeCounters = async () => {
  const state = store.getState();
  const { _id: bunId } = businessUnitSelectors.selected(state);

  const params = {
    params: {
      pipes: [
        {
          $match: {
            bunId,
          },
        },
        {
          $lookup: {
            from: 'Campaign',
            localField: 'cmpId',
            foreignField: '_id',
            as: 'campaign',
          },
        },
        {
          $unwind: {
            path: '$campaign',
            preserveNullAndEmptyArrays: false,
          },
        },
        {
          $project: {
            cmpId: 1,
            bunId: 1,
            campaignType: 1,
            'campaign.campaignStatus': 1,
          },
        },
        {
          $lookup: {
            from: 'InfluencerCampaignRelation',
            localField: 'cmpId',
            foreignField: 'cmpId',
            as: 'influencer',
          },
        },
        {
          $project: {
            bunId: 1,
            cmpId: 1,
            campaign: 1,
            numberOfInfluencers: { $size: '$influencer' },
          },
        },
      ],
    },
  };

  try {
    const {
      data: { data },
    } = await axios.get('/public/ql/business-units-campaigns', {
      params,
    });
    console.log({ homeCampaign: data });
    store.dispatch(setHomeCounters(data));
    return data;
  } catch (e) {
    console.log({ getHomeCounterError: e });
  }
};

export const getHomeHistogramPartial = async () => {
  const state = store.getState();
  const { _id: bunId } = businessUnitSelectors.selected(state);
  const params = {
    params: {
      pipes: [
        {
          $match: {
            bunId,
          },
        },
        {
          $lookup: {
            from: 'Campaign',
            localField: 'cmpId',
            foreignField: '_id',
            as: 'campaign',
          },
        },
        {
          $unwind: {
            path: '$campaign',
            preserveNullAndEmptyArrays: false,
          },
        },
        {
          $group: {
            _id: {
              cmpType: '$campaignType',
            },
            performance: {
              $sum: '$campaign.performance',
            },
            total: {
              $sum: 1,
            },
          },
        },
      ],
    },
  };
  console.debug({ getHomeHistogramPartialParams: params });
  try {
    const {
      data: { data },
    } = await axios.get('/public/ql/business-units-campaigns', {
      params,
    });
    const filteredData = _.filter(data, (d) => !_.isEmpty(d._id));

    const finalResult = _.reduce(
      filteredData,
      (res, acc) => {
        res[acc._id.cmpType] = {
          total: acc.total,
          performance: acc.performance,
        };
        return res;
      },
      {}
    );
    console.debug({
      getHomeHistogramPartial: finalResult,
    });
    return finalResult;
  } catch (e) {
    console.debug({ e });
  }
};

export const getMetricCampaign = async (cmpId, filters) => {
  if (!filters.from || !filters.to) {
    return;
  }

  console.debug({
    fromDate: DateTime.fromMillis(filters.from).toISODate(),
    toDate: DateTime.fromMillis(filters.to).toISODate(),
  });

  const params = {
    params: {
      params: {
        query: {
          startTs: _.get(filters, 'from') || undefined,
          endTs: _.get(filters, 'to') || undefined,
        },
      },
    },
  };

  const from = DateTime.fromMillis(filters.from);
  const to = DateTime.fromMillis(filters.to);
  console.debug({ campaignParams: params, filters });

  try {
    const { data } = await axios.get(`/private/campaigns/${cmpId}/histogram`, params);
    const frameUnit = _.get(data.data, '[0].frame');
    const convertedFrameUnit = DATE_TIME_UNITS_API_NEW[frameUnit];
    console.debug({ data: data.data, convertedFrameUnit });
    const emptyData = emptyDataFromDate(from, to, convertedFrameUnit);
    const normalizedApiData = normalizeDataFromApi(data.data, convertedFrameUnit);
    const mergedData = mergeEmptyDataAndApiResult(emptyData, normalizedApiData);

    console.debug({ emptyData, normalizedApiData, mergedData });
    // const normalizedData = normalizedDataBasedOnDates(units, data, DateTime.fromMillis(filters.from), DateTime.fromMillis(filters.to));
    // console.debug({ normalizedData });
    // return normalizedData.map((metric) => ({
    //   key: dateFromKey(metric._id.key, units),
    //   ...metric,
    // }));
    return mergedData;
  } catch (e) {
    console.debug({ e });
  }
};

export const getCampaignKpi = async (cmpId) => {
  try {
    const { data } = await axios.get(`private/campaigns/${cmpId}/kpi`);
    console.debug({ kpiData: data });
    return data;
  } catch (e) {
    console.debug({ e });
    return {
      accepted: 0,
      average: 0,
      invited: 0,
      totalPerformances: 0,
      withPerformances: 0,
    };
  }
};

export const getMetricCampaignInfluencer = async (cmpId, filters) => {
  console.debug({ cmpId, filters });

  const params = {
    skip: 0,
    limit: 10000,
  };

  try {
    const { data: campaignTable } = await axios.get(`private/campaigns/${cmpId}/table`, { params });
    console.debug({ campaignTable });
    return campaignTable;
  } catch (e) {
    return defaultListObj;
  }
};
