import React, { memo, useEffect, useMemo, useState } from 'react';
import './CampaignStatisticFilters.scss';
import { dateOptionsAndValuesFromTo, fromMillisToDate } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { metricSelectors, setCampaignStatisticFilter } from '../../features/metricSlice';
import _ from 'lodash';
import { Col, Icon, InputDatePicker, InputSelect, Row } from '../../components/ui';
import { IconButton } from '@mui/material';
import { Animated } from 'react-animated-css';

const CampaignStatisticFilters = ({ campaign }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dateValues = useMemo(() => {
    return {
      ...dateOptionsAndValuesFromTo,
      allDates: 'allDates',
    };
  }, []);

  const campaignFilters = useSelector(metricSelectors.campaignStatisticFilter);

  useEffect(() => {
    onChangeDateFilters('allDates');
  }, [campaign]);

  const dates = useMemo(() => {
    const min = fromMillisToDate(_.get(campaign, 'duration.begin.ts'));
    const max = fromMillisToDate(_.get(campaign, 'duration.end.ts'));
    return { min, max };
  }, [campaign]);

  const [showFilters, setShowFilters] = useState(true);
  const showPickerDates = useMemo(() => {
    return _.isEqual(_.get(campaignFilters, 'date'), 'custom');
  }, [campaignFilters]);

  const onToggleShowFilters = () => setShowFilters((prevState) => !prevState);

  const onChangeDateFilters = (value) => {
    if (['custom', 'allDates'].includes(value)) {
      dispatch(
        setCampaignStatisticFilter({
          from: _.get(campaign, 'duration.begin.ts'),
          to: _.get(campaign, 'duration.end.ts'),
          date: value,
        })
      );
      return;
    }

    dispatch(
      setCampaignStatisticFilter({
        ...dateValues[value],
        date: value,
      })
    );
  };

  const onChangeCustomDate = (value, dateField) => {
    console.debug({ value, dateField });
    dispatch(setCampaignStatisticFilter({ [dateField]: value.toMillis() }));
  };

  return (
    <Col>
      <Row id={'campaign-statistic-filters'} alignCenter fullWidth noFlex spaceBetween>
        <IconButton className={'button'} onClick={onToggleShowFilters}>
          <Icon icon={showFilters ? 'far fa-times' : 'far fa-filter'} color={'white'} sx={{ padding: '0.5rem' }} style={{ width: '1.5rem', height: '1.5rem' }} />
        </IconButton>
        <Row flex spaceBetween container md={12}>
          <Col md={9}>
            <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={showFilters}>
              <Row>
                <Col md={4}>
                  <InputSelect
                    variant={'outlined'}
                    label={t('date')}
                    emitValue
                    value={_.get(campaignFilters, 'date')}
                    onChange={(value) => onChangeDateFilters(value)}
                    options={_.map(_.keys(dateValues), (o) => ({
                      _id: o,
                      label: t(o),
                    }))}
                  />
                </Col>
                {showPickerDates && (
                  <>
                    <Col md={3}>
                      <InputDatePicker
                        label={t('from')}
                        value={fromMillisToDate(_.get(campaignFilters, 'from'))}
                        handleChange={(newDate) => onChangeCustomDate(newDate, 'from')}
                        minDate={dates.min}
                        maxDate={dates.max}
                      />
                    </Col>
                    <Col md={3}>
                      <InputDatePicker
                        label={t('to')}
                        value={fromMillisToDate(_.get(campaignFilters, 'to'))}
                        handleChange={(newDate) => onChangeCustomDate(newDate, 'to')}
                        minDate={dates.min}
                        maxDate={dates.max}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Animated>
          </Col>
        </Row>
      </Row>
    </Col>
  );
};

export default memo(CampaignStatisticFilters);
