import React, { useState } from 'react';
import { Col } from '../col/col';
import { Image } from './image';
import { BackgroundBaseImage } from '../../../assets';
import { IconButton } from '@mui/material';
import { Icon } from '../icon/icon';
import './ImageContainer.scss';
import _ from 'lodash'

export const ImageContainer = ({ image, onClickDelete }) => {
  const [showDelete, setShowDelete] = useState(false);

  const onMouseEnter = () => {
    setShowDelete(true);
  };

  const onMouseLeave = () => {
    setShowDelete(false);
  };

  return (
    <Col sm={4} md={3} key={image?.uri} style={{ position: 'relative' }} className={'image-wrapper'} pr={'0.5rem'} pt={'0.5rem'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Col style={{ position: 'relative' }} className={'image-container'}>
        <Image source={BackgroundBaseImage} className={'image-background'} />
        <Image path={_.get(image, 'uri', '')} uri={_.get(image, 'uri', '')} className={'image'} />
      </Col>
      {showDelete && !!onClickDelete && (
        <Col className={'icon-container'} alignCenter justifyCenter>
          <IconButton className={'icon-delete-image'} onClick={() => onClickDelete(image)}>
            <Icon icon={'far fa-times'} color={'black'} />
          </IconButton>
        </Col>
      )}
    </Col>
  );
};
