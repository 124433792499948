import React, {useEffect, useState} from 'react';
import './image.scss'
import clsx from "clsx";
import {getUrlImageFromPath} from '../../../api/upload';

export const Image = ({path, uri, className, source, style, ...rest}) => {

  const [localUri, setLocalUri] = useState(uri)

  useEffect(() => {
    if (uri) {
      setLocalUri(uri)
    }
  }, [uri])

  useEffect(() => {
    if (path) {
      getUrlImageFromPath(path).then(newUri => {
        if (newUri) {
          setLocalUri(newUri)
        }
      })
    }
  }, [path])

  const finalSource = localUri ? localUri : source;


  return (
    <img src={finalSource} className={className} style={style} {...rest}/>
  )
}
