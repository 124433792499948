import variables from './../app/colors.module.scss';

const palette = {
  primary: {
    main: variables['brand-purple']
  },
  secondary: {
    main: variables['brand-yellow']
  },
  error: {
    main: variables['error']
  },
  success: {
    main: variables['success']
  }
}

export default palette
