import React, { useState, memo, useEffect } from 'react';
import { TextField, MenuItem } from '@mui/material';
import clsx from 'clsx';
import './inputTelephone.scss';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';

export const InputTelephone = memo(
  ({ prefixes, numberValue, prefixValue, label, onChange, errors, disabled, prefixVariant = 'standard', placeholder = '', containerStyle, inputLabelColor = 'black', ...props }) => {
    const [telephone, setTelephone] = useState([prefixValue, numberValue] || []);
    const { t } = useTranslation();

    const [prefixOptions, setPrefixOptions] = useState(prefixes);

    useEffect(() => {
      let opt = [];
      const countries = getCountries();
      // console.debug({ countries });
      _.forEach(countries, (c) => {
        const callingCode = getCountryCallingCode(c);
        // console.debug({ callingCode });
        opt.push(callingCode);
      });
      // console.debug(opt);
      const mapped = _.map(_.uniq(opt), (o) => {
        return {
          label: `+${o}`,
          value: o,
        };
      });
      // console.debug({ mapped });
      setPrefixOptions(_.orderBy(mapped, 'value', ['asc']));
    }, []);

    const handleChangePrefix = (event) => {
      setTelephone(([, number]) => {
        const _prefix = event?.target?.value;
        if (typeof onChange === 'function') {
          onChange([_prefix, number, `${_prefix ? '+' + _prefix : ''}${number}`]);
        }
        return [_prefix, number];
      });
    };

    const handleChangeNumber = (event) => {
      setTelephone(([prefix]) => {
        const _number = event?.target?.value;
        if (typeof onChange === 'function') {
          onChange([prefix, _number, `${prefix ? '+' + prefix : ''}${_number}`]);
        }
        return [prefix, _number];
      });
    };

    return (
      <div className="e-input-telephone" style={containerStyle}>
        {!!label && (
          <span className={clsx('e-input-telephone__label boldLabel')} style={{ fontWeight: 'bold' }} color="black">
            {label}
          </span>
        )}
        <div className={clsx('e-input-telephone__container')}>
          <TextField
            className={clsx(['e-select', 'e-input-telephone__select'])}
            select
            color="primary"
            variant={prefixVariant}
            value={telephone[0]}
            onChange={handleChangePrefix}
            error={!!(errors && errors[0])}
            {...props}
            helperText={t(errors[0])}
            disabled={disabled}>
            {prefixOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={placeholder ? placeholder : ''}
            className={clsx(['e-input-text', 'e-input-telephone__input'])}
            variant="standard"
            type="tel"
            pattern="[0-9]+"
            value={telephone[1]}
            {...props}
            onChange={handleChangeNumber}
            error={!!(errors && errors[1])}
            helperText={t(errors[1])}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
);
