import React from 'react';
import { BaseModal, Button, Col, Row } from '../../../ui';
import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import './CashoutIdentityModal.scss';
import { Image } from '../../../ui/image/image';

export const CashoutIdentityModal = ({ open, onClose, source }) => {
  const { t } = useTranslation();

  console.debug({ source });
  const identityID = _.get(source, 'cashoutInfo.identityID.source');
  console.debug({ identityID });

  return (
    <BaseModal open={open} propStyle={{ minWidth: '50vh' }}>
      <Card id={'modal-confirm'}>
        <Typography variant="h2">{t('identityCard')}</Typography>
        <Col>{!!identityID && <Image path={identityID.uri} />}</Col>
        <Row spaceBetween>
          <Button onClick={onClose} variant={'outlined'}>
            {t('undo')}
          </Button>
        </Row>
      </Card>
    </BaseModal>
  );
};
