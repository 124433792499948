import React from 'react'
import {Route, Switch, useParams, useLocation, useHistory} from 'react-router-dom'
import './CreateCampaignPage.scss'
import {Container} from "@mui/material";
import {campaignTypes} from "../../utils/const";
import {PayPerClickCampaign} from "../../components/layout/campaign/PayPerClickCampaign";
import {PayPerSalesCampaign} from "../../components/layout/campaign/PayPerSalesCampaign";
import {NewCampaign} from "../../components/layout/campaign/NewCampaign";
import {PayPerPostEngagementCampaign} from "../../components/layout/campaign/PayPerPostEngagementCampaign";
import {PayPerContactCampaign} from "../../components/layout/campaign/PayPerContactCampaign";

const CreateCampaignPage = ({}) => {

  let { cmpId } = useParams();

  return (
    <Container id={'create-campaign'}>
      <Switch>
        <Route exact path={`/campaigns/new/`} component={NewCampaign}/>
        <Route path={`/campaigns/${cmpId}/${campaignTypes.PayPerClick}`} component={PayPerClickCampaign}/>
        <Route path={`/campaigns/${cmpId}/${campaignTypes.PayPerSales}`} component={PayPerSalesCampaign}/>
        <Route path={`/campaigns/${cmpId}/${campaignTypes.PayPerPostEngagement}`} component={PayPerPostEngagementCampaign}/>
        <Route path={`/campaigns/${cmpId}/${campaignTypes.PayPerContact}`} component={PayPerContactCampaign}/>
      </Switch>
    </Container>
  )
}

export default CreateCampaignPage
