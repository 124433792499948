export const cashoutEntityFields = {
  cashoutStatus: { align: 'left' },
  amount: { align: 'left' },
  infType: { align: 'left' },
  electronicInvoicing: { align: 'left' },
  paymentProvider: { align: 'left' },
  under16: { align: 'left' },
  createdCashout: { align: 'left' },
  actions: { align: 'center' },
};

export const campaignsEntityFields = {
  company: { align: 'left', sortable: true },
  'creation date': { align: 'left', sortable: true },
  'budget.budget.value': { align: 'left', sortable: true },
  'duration.begin': { align: 'left', sortable: true },
  'duration.end': { align: 'left', sortable: true },
  campaignStatus: { align: 'left', sortable: true },
  typeof: { align: 'left', sortable: true },
  actions: { align: 'right' },
};

export const influencerStatisticFields = {
  influencer: { align: 'left' },
  sum1h: { align: 'left' },
  sum6h: { align: 'left' },
  sum24h: { align: 'left' },
};

export const salesFields = {
  email: { align: 'left' },
  firstName: { align: 'left' },
  lastName: { align: 'left' },
  quantity: { align: 'left' },
  phone: { align: 'left' },
  vat: { align: 'left' },
  shippingAddress: { align: 'left' },
};
export const contactsFields = {
  infNickname: { align: 'left' },
  phone: { align: 'left' },
  date: { align: 'left' },
};

export const influencerEntityFields = {
  nickname: { align: 'left' },
  'careerLevel.level.label': { align: 'left', sortable: true },
  'careerLevel.score': { align: 'left', sortable: true },
  categories: { align: 'left' },
  socials: { align: 'left' },
  'created.ts': { align: 'left', sortable: true },
  'dateOfBirth.date.ts': { align: 'left', sortable: true },
  'address.place': { align: 'left', sortable: true },
  'mission.newEnchorer': { align: 'left', sortable: false },
  'wallet.value': { align: 'left', sortable: true },
  actions: { align: 'right' },
};

export const bunEntityFields = {
  'businessUnit.name': { align: 'left', sortable: true },
  businessUnitStatus: { align: 'left' },
  vat: { align: 'left' },
  actions: { align: 'right' },
};
