import { Grid, Avatar, Typography } from '@mui/material';
import clsx from 'clsx';
import './CampaignStepper.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { campaignSelectors } from '../../../features/campaignSlice';

const CampaignStepper = ({ items, campaignType }) => {
  const { t } = useTranslation();
  const step = useSelector(campaignSelectors.currentStep);
  const completedSteps = useSelector(campaignSelectors.completedSteps);

  return (
    <div id={'campaign-stepper'}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        rowSpacing={{ xs: '20', md: '0' }}
        className={clsx('c-campaign-stepper__list')}
      >
        {items?.map((item, i) => (
          <Grid
            item
            xs={12}
            md={4}
            className={clsx('c-campaign-stepper__item', {
              'is-selected': item === step,
              // 'is-completed': completedSteps?.includes(item)
            })}
            key={i}
          >
            <Avatar className={clsx('c-campaign-stepper__index')}>
              {i + 1}
            </Avatar>
            <Typography
              component="p"
              className={clsx('c-campaign-stepper__label')}
            >
              {t(item)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CampaignStepper;
