import React, { useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NewCampaign.scss';
import { Container, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from '../../../api';
import { AppRoutes } from '../../../app/routers';
import { campaignTypes, STEPS, TOAST } from '../../../utils/const';
import {
  setCampaignCurrentStep,
  setCampaignSelected,
  setCampaignSelectedType,
} from '../../../features/campaignSlice';
import { Button } from '../../ui';
import { getAppConfig } from '../../../api/configurations';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { userSelectors } from '../../../features/userSlice';

export const NewCampaign = () => {
  const { t } = useTranslation();
  const searchParams = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const businessUnitSelected = useSelector(businessUnitSelectors.selected);
  const userToken = useSelector(userSelectors.token);
  console.log({ path });
  console.log({ searchParams });

  useEffect(() => {
    getMe();
    getAppConfig();
  }, []);

  const onPressCampaign = (type) => {
    if (!userToken) {
      dispatch(setCampaignSelected({}));
      dispatch(setCampaignSelectedType(type));
      dispatch(setCampaignCurrentStep(STEPS[type][0]));
      history.push(AppRoutes.campaign('new') + `/${type}`);
      return;
    }
    //
    // if (_.isEmpty(businessUnitSelected)) {
    //   toast(
    //     t('you need to create a company before you can proceed'),
    //     TOAST.ERROR
    //   );
    //   return;
    // }

    // if(businessUnitSelected.editStatus !== EDIT_STATUSES.COMPLETE) {
    //   toast(t('you need to complete a company before you can proceed'), TOAST.ERROR);
    // }
    dispatch(setCampaignSelected({}));
    dispatch(setCampaignSelectedType(type));
    dispatch(setCampaignCurrentStep(STEPS[type][0]));
    history.push(AppRoutes.campaign('new') + `/${type}`);
  };

  const campaignOptions = [
    {
      _id: campaignTypes.PayPerPostEngagement,
      onClick: () => onPressCampaign(campaignTypes.PayPerPostEngagement),
      disabled: false,
    },
    {
      _id: campaignTypes.PayPerClick,
      onClick: () => onPressCampaign(campaignTypes.PayPerClick),
      disabled: false,
    },
    {
      _id: campaignTypes.PayPerContact,
      onClick: () => onPressCampaign(campaignTypes.PayPerContact),
      disabled: false,
    },
    {
      _id: campaignTypes.PayPerSales,
      onClick: () => onPressCampaign(campaignTypes.PayPerSales),
      disabled: false,
    },
  ];

  return (
    <Container id={'create-campaign'}>
      <Typography
        className="c-select-campaign__title"
        component="h1"
        variant="h1"
      >
        {t('create new campaign')}
      </Typography>
      <Typography component="p">{t('select campaign type')}</Typography>
      <Grid
        className="c-select-campaign__grid"
        container
        rowSpacing="60"
        columnSpacing="32"
        alignItems="center"
        sx={{
          pt: '10px',
        }}
      >
        {campaignOptions.map((c) => (
          <>
            <Grid item xs={12} md={6}>
              <Button className="c-select-campaign__button" {...c}>
                {t(`${c._id}.title`)}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="p">
                {t(`${c._id}.textDescription`)}
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>
    </Container>
  );
};
