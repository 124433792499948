import React from 'react';
import { Table as MuiTable, TableSortLabel } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import './Table.scss';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../ui';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';

const Table = ({ entityFields, order, orderBy, onRequestSort, data, children, setSelected, selected, bodyCellComponent, showCheckbox = true, sortEnable }) => {
  const { t } = useTranslation();
  const onSelectAll = () => {
    const all = data.map((row) => row._id);
    setSelected(all);
  };

  const onUpdateSelectedItems = (id) => {
    const index = _.findIndex(selected, (s) => s === id);
    if (index > -1) {
      const newItems = _.filter(selected, (s) => s !== id);
      setSelected(newItems);
    } else {
      setSelected((prevState) => [...prevState, id]);
    }
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <div id={'table'}>
      <TableContainer component={Paper}>
        <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {showCheckbox && (
                <TableCell padding="normal">
                  <Checkbox checked={false} onChange={() => console.log('selectAll')} />
                </TableCell>
              )}
              {_.keys(entityFields).map((key) => (
                <TableCell {...entityFields[key]} key={key}>
                  {sortEnable && entityFields[key] && entityFields[key].sortable ? (
                    <TableSortLabel active={orderBy === key} key={key} direction={orderBy === key ? order : 'asc'} onClick={createSortHandler(key)}>
                      {t(key)}
                      {orderBy === key ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <span>{t(key)}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {
                  <>
                    {showCheckbox && (
                      <TableCell padding="normal">
                        <Checkbox checked={_.findIndex(selected, (s) => s === row._id) > -1} onChange={() => onUpdateSelectedItems(row._id)} />
                      </TableCell>
                    )}
                    {_.keys(entityFields).map((key) => (
                      <TableCell>{bodyCellComponent[key] ? bodyCellComponent[key](row) : _.get(row, `${key}`, ' - ')}</TableCell>
                    ))}
                  </>
                }
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
        {children}
      </TableContainer>
    </div>
  );
};

export default Table;
