import React from 'react';
import { Button, InputText } from '../../ui';
import { useTranslation } from 'react-i18next';
import '../cards/CardLogin.scss';
export const FIELD_NAME_EMAIL = 'email';

const StepEmail = ({
  value,
  loading,
  onChange,
  onSubmit,
  errorMessage,
  buttonText,
}) => {
  const { t } = useTranslation();
  const handleChange = (value) => {
    onChange(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const value = e.target[FIELD_NAME_EMAIL].value;
    onSubmit(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputText
        label={t(FIELD_NAME_EMAIL)}
        fullWidth
        type="text"
        name={FIELD_NAME_EMAIL}
        onChange={handleChange}
        value={value}
        error={!!errorMessage}
      />
      {errorMessage && (
        <div className="b-card-login__warn">{t(errorMessage)}</div>
      )}
      <Button
        type="submit"
        fullWidth
        loading={loading}
        text={t(buttonText)}
        sx={{
          mt: '10px',
        }}
      />
    </form>
  );
};

export default StepEmail;
