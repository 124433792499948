import {memo, useState} from "react";
import {BaseModal, Button, Row} from "../../ui";
import {Card, CardContent, Typography, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import spacing from './../../../../src/app/spacing.module.scss'
import {appSelectors, setAppForgotPasswordModal, setAppLoginModal} from "../../../features/appSlice";
import './forgotPasswordModal.scss'
import {forgotPassword} from "../../../api/auth";
import {AppRoutes} from "../../../app/routers";
import {useHistory} from "react-router-dom";

export const ForgotPasswordModal = () => {

  const { t } = useTranslation()
  const open = useSelector(appSelectors.forgotModal)
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const style = {
    maxWidth: 350
  }

  const onCancel = () => {
    dispatch(setAppForgotPasswordModal(false))
    dispatch(setAppLoginModal(true))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const email = localStorage.getItem('email');
    setLoading(true)
    try {
      const res = await forgotPassword(email)
      setLoading(false)
      history.push({
        pathname: AppRoutes.sendEmail(),
        state: { action: 'forgot_password' }
      })//da creare rotta apposta
      dispatch(setAppForgotPasswordModal(false))
      dispatch(setAppLoginModal(true))
      console.log({res})
    } catch (e) {
      console.log({e})
      setLoading(true)
    }
  }

  return (
    <BaseModal open={open}>
      <Card style={style}>
        <CardContent>
          <Typography variant={'h4'}>
            {t('forgot password title')}
          </Typography>
          <Box sx={{mt: spacing.md}}>
            <Typography variant={'body2'}>
              {t('forgot password text')}
            </Typography>
          </Box>
          <Row flex spaceBetween className={'button-container'}>
            <Button variant={'outlined'} onClick={onCancel} style={{width: '40%'}} size={'small'}>
              {t('cancel')}
            </Button>
            <Button variant={'contained'} style={{width: '40%'}} size={'small'} loading={loading} onClick={onSubmit}>
              {t('send')}!
            </Button>
          </Row>
        </CardContent>
      </Card>
    </BaseModal>
  )

}
