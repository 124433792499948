import { sendPasswordResetEmail, signInWithPopup, signOut, signInWithEmailAndPassword, signInWithEmailLink, sendSignInLinkToEmail, confirmPasswordReset, updatePassword } from 'firebase/auth';
import { auth, facebookAuthProvider, googleAuthProvider } from '../lib/firebase';
import { store } from '../app/store';
import { setUserDetail, setUserRefreshToken, setUserToken, userSelectors } from '../features/userSlice';
import _ from 'lodash';
import { getMe } from './user';
import { patchBusinessUser, postBusinessUser } from './businessUser';
import { prefixUrl } from '../utils';
import { AppRoutes } from '../app/routers';
import { toast } from 'react-toastify';
import { translate } from '../i18n';
import { TOAST } from '../utils/const';
import { setBusinessUnitCampaigns, setBusinessUnitSelected, setUserBusinessUnitList } from '../features/businessUnitSlice';
import { resetGanttFilter, setAreas, setCampaignSelected, setCart, setHomeGanttFilter, setHomePerformanceFilter } from '../features/campaignSlice';
import { setHomeCounters, setHomeGanttCampaigns } from '../features/metricSlice';

export const currentUserProvider = () => {
  try {
    const providerData = auth.currentUser.providerData;
    console.log({ providerData });
    return providerData[0].providerId;
  } catch (e) {
    return '';
  }
};

export const forgotPassword = async (email) => {
  try {
    const sent = await sendPasswordResetEmail(auth, email);
    console.log({ sent });
    store.dispatch(
      setUserDetail({
        email,
      })
    );
    toast(translate('send mail reset password successfully'), TOAST.SUCCESS);
    return true;
  } catch (error) {
    toast(translate('send mail reset password error'), TOAST.ERROR);
    console.log({ error });
    return false;
  }
};

export const businessUserExist = async () => {
  try {
    await getMe();
    return true;
  } catch (e) {
    console.log({ e });
    return false;
  }
};

const createBusinessUserFromToken = async (token, userCredential, extraInfo = {}) => {
  try {
    const firebaseLongToken = _.get(userCredential, 'user.refreshToken');
    // const signed = await signInWithCredential(auth, userCredential);
    // console.log({signed})
    // const additionalUserInfo = signed.additionalUserInfo;
    // const { isNewUser, profile } = additionalUserInfo;
    // console.log({isNewUser, profile});

    console.log({ firebaseLongToken, userCredential, token });
    store.dispatch(setUserToken(token));
    store.dispatch(setUserRefreshToken(firebaseLongToken));

    // const businessUserAlreadyExist = await businessUserExist();
    // if (businessUserAlreadyExist) {
    //   const alreadyUser = await getMe();
    //   return alreadyUser;
    // }

    let firstName, lastName, email;
    // firstName = _.get(profile,'first_name') || _.get(profile,'given_name') || extraInfo.firstName;
    // lastName = _.get(profile,'last_name') || _.get(profile,'family_name') || extraInfo.lastName;
    // email = _.get(profile,'email') || _.get(signed,'user._user.email') || extraInfo.email;
    email = extraInfo.email;

    const createdUser = await postBusinessUser();
    const refreshedServerUser = await getMe();
    const newAccountLocal = refreshedServerUser.account;
    if (email) {
      newAccountLocal.email = email;
    }
    await patchBusinessUser({
      // firstName,
      // lastName,
      account: refreshedServerUser.account,
    });

    store.dispatch(setUserDetail(createdUser));
    console.log({ createdUser });
    return createdUser;
  } catch (e) {
    console.log({errorCreateBusinessUserFromToken: e })
  }

};

export const setPassword = async (password) => {
  if (!auth.currentUser) {
    return;
  }
  try {
    await updatePassword(auth.currentUser, password);
  } catch (error) {
    console.log({ error });
  }
};

export const signIn = async (email, password) => {
  console.log({ email, password });
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log({ userCredential });
    const userToken = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(userToken));
    return await createBusinessUserFromToken(userToken, userCredential);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const signInGoogle = async () => {
  try {
    const credential = await signInWithPopup(auth, googleAuthProvider);
    const userToken = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(userToken));
    console.log({ credential });

    await createBusinessUserFromToken(userToken, credential);
    return credential;
  } catch (e) {
    console.log({ googleError: e });
    return Promise.reject(e);
  }
};

export const createFirebaseUser = async (email) => {
  try {
    await sendSignInLinkToEmail(auth, email, {
      url: `${process.env.REACT_APP_APP_BASE_URL}${AppRoutes.completeSignup(email)}`,
      handleCodeInApp: true,
    });
    window.localStorage.setItem('email', email);
  } catch (error) {
    console.log({ error });
    return error.code;
  }
};

export const signInEmailLink = async (email, href) => {
  try {
    const credential = await signInWithEmailLink(auth, email, href);
    window.localStorage.removeItem('email');
    const userToken = await auth.currentUser.getIdToken();
    console.log({ signInEmail: userToken });
    await createBusinessUserFromToken(userToken, credential);
  } catch (error) {
    console.log({ signInEmailLinkError: error });
    return Promise.reject(error)
  }
};

export const resetPassword = async (newPassword, oobCode) => {
  try {
    if (!oobCode) {
      throw new Error('No oobCode');
    }
    await confirmPasswordReset(auth, oobCode, newPassword);
  } catch (error) {
    console.log({ error });
    return false;
  }
  return true;
};

export const signInFacebook = async () => {
  try {
    const credential = await signInWithPopup(auth, facebookAuthProvider);
    // const facebookProfile = await getFacebookUserInfo(credential.token);
    // const email = facebookProfile.email;
    const userToken = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(userToken));
    console.log({ credential });

    await createBusinessUserFromToken(userToken, credential);
    return credential;
  } catch (error) {
    console.log({ error });
  }
  return null;
};

export const signInMicrosoft = async () => {
  try {
    const credential = await signInWithPopup(auth, facebookAuthProvider);
    const userToken = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(userToken));
    console.log({ credential });

    await createBusinessUserFromToken(userToken, credential);
    return credential;
  } catch (error) {
    console.log({ error });
  }
  return null;
};

export const refreshToken = async () => {
  try {
    const state = store.getState();
    const refreshToken = userSelectors.refreshToken(state);
    const token = userSelectors.token(state);
    console.log({ refreshToken });
    const response = await fetch(process.env.REACT_APP_API_URL + prefixUrl() + '/public/me/refresh-token', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: refreshToken,
    });
    const json = await response.json();
    console.log({ json });
    const newToken = json.data[0].access_token;
    store.dispatch(setUserToken(newToken));
    return newToken;
  } catch (e) {
    console.log({ RefreshTokenRes: e });
  }
};

// const getFacebookUserInfo = async (token) => {
//   try {
//     const {data: facebookProfile} = await axiosFacebook.get(`me?fields=email&access_token=${token}`);
//     console.log({facebookProfile});
//     return facebookProfile;
//   } catch (e) {
//     console.log({errorLongToken: e});
//     return null;
//   }
// };

export const logout = async () => {
  await signOut(auth);
  store.dispatch(setUserDetail({}));
  store.dispatch(setUserToken(null));
  store.dispatch(setBusinessUnitSelected({}));
  store.dispatch(setUserBusinessUnitList([]));
  store.dispatch(setBusinessUnitCampaigns([]));
  store.dispatch(setHomeCounters([]));
  store.dispatch(setHomeGanttCampaigns([]));
  store.dispatch(resetGanttFilter());
  store.dispatch(setHomePerformanceFilter({}));
  store.dispatch(setCart());
  store.dispatch(setAreas([]));
  store.dispatch(setCampaignSelected({}));
  localStorage.clear();
};
