import React, { useEffect, useMemo, useState } from 'react';
import './CartPage.scss';
import { useTranslation } from 'react-i18next';
import { BaseDialog, Button, Col, Icon, Row } from '../../components/ui';
import { Accordion, AccordionDetails, AccordionSummary, Card, Link, Typography } from '@mui/material';
import _ from 'lodash';
import { CREATE_COMPANY_STEPS } from '../../utils/const';
import { campaignSelectors } from '../../features/campaignSlice';
import { useSelector } from 'react-redux';
import { CampaignCartRow } from '../../components/layout/campaign/CampaignCartRow';
import { getBusinessUnitCampaigns, getCurrency } from '../../api';
import { userSelectors } from '../../features/userSlice';
import { businessUnitSelectors } from '../../features/businessUnitSlice';
import { CreateCompanyData1, CreateCompanyData2, CreateCompanyData3 } from '../../components/layout/createCompany';
import { CampaignModalPayment } from '../../components/layout/modals';
import { useModal } from '../../utils/hook';
import { getCartTotal, getParameterFromUrl, normalizeCurrency, theme } from '../../utils';
import { AppRoutes } from '../../app/routers';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const CartPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userToken = useSelector(userSelectors.token);
  const [modal, { open: openModal, close }] = useModal();
  const [selectedCampaigns, setSelectedCampaigns] = React.useState([]);
  const [showModalPayment, setShowModalPayment] = React.useState('');
  const selectedBusinessUnit = useSelector(businessUnitSelectors.selected);
  const { search } = useLocation();
  const params = getParameterFromUrl(search);
  const payment = _.get(params, 'payment', '');

  console.log({ selectedBusinessUnit });
  const campaignsCart = useSelector(campaignSelectors.cart);
  console.log({ campaignsCart });

  useEffect(() => {
    if (userToken) {
      getBusinessUnitCampaigns();
    }
  }, []);

  useEffect(() => {
    if (payment === 'success') {
      setShowModalPayment('success');
    }
    if (payment === 'reject') {
      setShowModalPayment('reject');
    }
    history.push({
      pathname: '/cart',
      search: '?',
    });
  }, [payment]);

  const onClickCampaignCheckbox = (campaign) => {
    console.log({ campaign });
    if (selectedCampaigns.find((camp) => camp._id === campaign._id)) {
      setSelectedCampaigns(selectedCampaigns.filter((camp) => camp._id !== campaign._id));
    } else {
      setSelectedCampaigns([...selectedCampaigns, campaign]);
    }
  };
  const onConfirmPayment = () => {
    getBusinessUnitCampaigns();
    setSelectedCampaigns([]);
  };

  const goToCreateCampaign = () => {
    history.push(AppRoutes.campaign('new'));
  };

  const disablePay = useMemo(() => {
    return _.isEmpty(selectedCampaigns) || _.get(selectedBusinessUnit, 'businessUnitStatus') !== 'billable';
  }, [selectedCampaigns, selectedBusinessUnit]);

  return (
    <div id={'cart-page'}>
      <BaseDialog open={showModalPayment === 'success'} onClose={() => setShowModalPayment('')} text={t('campaign payment success description')} title={t('campaign payment success title')} />
      <BaseDialog open={showModalPayment === 'reject'} onClose={() => setShowModalPayment('')} text={t('campaign payment reject description')} title={t('campaign payment reject title')} />
      <CampaignModalPayment open={modal} onClose={close} onConfirmPayment={onConfirmPayment} selectedCampaigns={selectedCampaigns} />
      <Row justifyCenter md={12}>
        <Col md={10} flexStart>
          <Typography variant={'h2'}>{t('cart')}</Typography>
        </Col>
      </Row>
      <Row container justifyCenter>
        <Col item md={8} style={{ marginTop: '1rem' }}>
          <Col alignCenter justifyCenter style={{ marginBottom: '1rem' }}>
            <Row md={10}>
              <Typography variant={'h3'}>{t('companyDetail')}</Typography>
            </Row>
          </Col>
          <BusinessUnitDetail step={CREATE_COMPANY_STEPS.COMPANY_DATA} />
          <BusinessUnitDetail step={CREATE_COMPANY_STEPS.COMPANY_PROFILE} />
          <BusinessUnitDetail step={CREATE_COMPANY_STEPS.BILLING_INFO} />
          <Col alignCenter justifyCenter style={{ marginBottom: '1rem' }}>
            <Row md={10}>
              {_.isEmpty(campaignsCart) && (
                <Row alignCenter>
                  <Typography variant={'h3'}>{t('empty cart')}</Typography>
                  <Button onClick={goToCreateCampaign} text={t('create new campaign')} style={{ marginLeft: '1rem' }} />
                </Row>
              )}
              {_.map(campaignsCart, (campaign) => (
                <CampaignCartRow campaign={campaign} onClickCheckbox={onClickCampaignCheckbox} />
              ))}
              {!_.isEmpty(campaignsCart) && (
                <Col md={4}>
                  <Button onClick={openModal} text={t('pay')} disabled={disablePay} />
                </Col>
              )}
            </Row>
          </Col>
        </Col>
        <Col item md={4}>
          <Review selectedCampaigns={selectedCampaigns} openModal={openModal} disablePay={disablePay} />
        </Col>
      </Row>
      <Col style={{ marginBlock: '1rem' }} />
    </div>
  );
};

export default CartPage;

const BusinessUnitDetail = ({ step }) => {
  const selectedBusinessUnit = useSelector(businessUnitSelectors.selected);
  const { t } = useTranslation();

  const isOk = useMemo(() => {
    switch (step) {
      case CREATE_COMPANY_STEPS.COMPANY_DATA:
        return true;
      case CREATE_COMPANY_STEPS.COMPANY_PROFILE:
        return selectedBusinessUnit && selectedBusinessUnit.description && _.get(selectedBusinessUnit, 'avatar.uri', '');
      case CREATE_COMPANY_STEPS.BILLING_INFO:
        return _.get(selectedBusinessUnit, 'billing.address', '');
    }
  }, [selectedBusinessUnit]);

  return (
    <Col alignCenter justifyCenter style={{ marginBottom: '1rem' }}>
      <Row md={10} flex container spaceBetween>
        <Accordion md={12} style={{ width: '100%', borderRadius: '1rem' }}>
          <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
            <Row>
              <Row alignCenter flex={0} xs={6}>
                <Typography variant={'bold'} mr="0.5rem">
                  {' '}
                  {t(step)}{' '}
                </Typography>
                {isOk ? <Icon icon={'fal fa-check-circle'} color={'green'} /> : <Icon icon={'fal fa-times-circle'} color={'red'} />}
              </Row>
              <Row xs={6} justifyEnd>
                <Link className={'link'}>{step === CREATE_COMPANY_STEPS.COMPANY_DATA ? t('view') : t('edit')}</Link>
              </Row>
            </Row>
          </AccordionSummary>
          <AccordionDetails>
            {step === CREATE_COMPANY_STEPS.COMPANY_DATA && <CreateCompanyData1 hideAction={true} />}
            {step === CREATE_COMPANY_STEPS.COMPANY_PROFILE && <CreateCompanyData2 fromCart />}
            {step === CREATE_COMPANY_STEPS.BILLING_INFO && <CreateCompanyData3 fromCart />}
          </AccordionDetails>
        </Accordion>
      </Row>
    </Col>
  );
};

const Review = ({ selectedCampaigns, openModal, disablePay }) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState({});

  console.log({ selectedCampaigns });

  useEffect(() => {
    getCurrency().then((res) => setCurrency(res));
  }, []);

  const total = normalizeCurrency({
    value: getCartTotal(selectedCampaigns),
    currency,
  });

  return (
    <Card>
      <Col>
        <Typography variant={'h3'}>{t('order review')}</Typography>
        <hr />
        {selectedCampaigns?.map((campaign) => (
          <Row style={{ marginTop: '0.5rem' }}>
            <Col style={{ marginRight: '0.5rem' }} alignCenter justifyCenter>
              <Icon icon={'far fa-bells'} color={theme['primaryColor']} style={{ width: 25, height: 25 }} />
            </Col>
            <Col flex>
              <span className={'reviewType'}>{t(`${campaign?.typeof}.title`)}</span>
              {!!campaign.name && <div className={'text'}>{campaign?.name}</div>}
            </Col>
          </Row>
        ))}
        <hr />
        <Row flexEnd spaceBetween alignEnd>
          <Col md={4}>
            <Button onClick={openModal} size={'small'} text={t('pay')} disabled={disablePay} />
          </Col>
          <Typography variant={'bold'} mr="0.5rem">
            {t('total').toUpperCase()}: {total}
          </Typography>
        </Row>
      </Col>
    </Card>
  );
};
