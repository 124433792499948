import React from 'react';
import { BaseModal, Button, Col, Row } from '../../ui';
import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './confirmModal.scss';

export const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  title,
  subtitle,
  children,
  notCloseOnConfirm,
  textUndo = 'undo',
  textConfirm = 'confirm',
}) => {
  const { t } = useTranslation();

  const onClickConfirm = () => {
    if (!notCloseOnConfirm) {
      onClose();
    }
    onConfirm();
  };

  return (
    <BaseModal open={open} propStyle={{ minWidth: '50vh' }}>
      <Card id={'modal-confirm'}>
        <Typography variant="h2">{t(title)}</Typography>
        <Col className={'subtitle'}>
          <Typography variant="body">{t(subtitle)}</Typography>
        </Col>
        {children}
        <Row spaceBetween>
          <Button onClick={onClose} variant={'outlined'}>
            {t(textUndo)}
          </Button>
          <Button onClick={onClickConfirm}>{t(textConfirm)}</Button>
        </Row>
      </Card>
    </BaseModal>
  );
};
