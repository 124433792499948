import React from 'react'
import './container.scss'

export const Container = ({ children, className, style }) => {
  return (
    <section className={'container'} style={style}>
      <div className="content-block">
        {children}
      </div>
    </section>
  )
}
