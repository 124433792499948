import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './StepCategory.scss';
import { Button, Checkbox, InputDateTimePicker, InputRadio, InputText, Row } from '../../ui';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import _ from 'lodash';
import { patchCampaign } from '../../../api';
import { CATALOGUES_TYPES, DATE_AND_TIME_FORMAT, DATE_FORMAT, STEP_BUDGET, TOAST } from '../../../utils/const';
import { campaignSelectors, setCampaignCurrentStep } from '../../../features/campaignSlice';
import { useDispatch, useSelector } from 'react-redux';
import { campaignValidations, ERR } from '../../../utils/validations';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { StepCategoryMap } from './StepCategoryMap';
import { CampaignModalName, ConfirmModal } from '../modals';
import { store } from '../../../app/store';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import { appSelectors } from '../../../features/appSlice';
import { userSelectors } from '../../../features/userSlice';
import { AppRoutes } from '../../../app/routers';
import { fromDateToIso, fromDateToMillis, fromMillisToDate } from '../../../utils';
// import { DATE_FULL } from 'luxon/src/impl/formats';
import { ENVIRONMENTS } from '../../../app/App';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';

const minutesToAdd = 10;
export const StepCategory = ({ campaignType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCampaign = useSelector(campaignSelectors.selected);
  const appConfig = useSelector(appSelectors.config);
  const environment = useSelector(appSelectors.localEnvironment);
  const [showModal, setShowModal] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalBu, setShowModalBu] = useState(false);
  const [asSoonAsPossible, setAsSoonAsPossible] = useState(false);
  const [isValidMap, setIsValidMap] = useState(false);
  const expertises = useSelector(appSelectors.catalogueByType(CATALOGUES_TYPES.EXPERTISE));
  const minDuration = _.get(appConfig, 'campaign.minDaysDurationCampaign', 1);
  const hoursRevision = _.get(appConfig, 'campaign.startAfterXHoursBeforeNow', 0);
  const maxDuration = _.get(appConfig, 'campaign.maxDaysDurationCampaign', 30);

  // console.debug({ stepCategory: selectedCampaign });

  const { handleSubmit, setFieldValue, setFieldError, errors, validateField, validateForm, values } = useFormik({
    initialValues: {
      expertise: _.get(selectedCampaign, 'category._id'),
      dateFrom: selectedCampaign.duration?.begin?.ts ? fromMillisToDate(_.get(selectedCampaign, 'duration.begin.ts')) : null,
      duration: _.get(selectedCampaign, 'duration.duration', '') || minDuration || 1,
    },
    validationSchema: campaignValidations[campaignType].category,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      if (DateTime.fromJSDate(data.dateFrom).toFormat('dd/MM/yyyy') === DateTime.now().toFormat('dd/MM/yyyy')) {
        data.dateFrom = DateTime.now();
      }

      validateForm().then(async () => {
        const state = store.getState();
        const areas = campaignSelectors.areas(state);
        if (_.isEmpty(areas) || _.isEmpty(areas.areas)) {
          toast(t('selected area are not valid'), TOAST.ERROR);
          return;
        }

        let newData = {
          category: _.find(expertises, (expertise) => _.isEqual(data.expertise, expertise._id)),
          polygons: areas.areas,
        };

        if (asSoonAsPossible) {
          newData.duration = {
            duration: data.duration,
          };
        }

        if (!asSoonAsPossible) {
          newData.duration = {
            begin: {
              iso: data.dateFrom.toISO(),
              ts: data.dateFrom.toMillis(),
            },
            duration: data.duration,
            end: {
              iso: data.dateFrom.plus({ days: data.duration }).toISO(),
              ts: data.dateFrom.plus({ days: data.duration }).toMillis(),
            },
          };
        }

        console.debug({ newData });
        await patchCampaign(selectedCampaign._id, newData);
        const userDetail = userSelectors.detail(state);
        const selectedBu = businessUnitSelectors.selected(state);
        if (_.isEmpty(userDetail)) {
          setShowModalLogin(true);
          return;
        }
        if (_.isEmpty(selectedBu)) {
          setShowModalBu(true);
          return;
        }
        setShowModal(true);
        // dispatch(setCampaignCurrentStep(STEP_CATEGORY_TIME_PLACE))
        // dispatch(setCampaignCompletedSteps([STEP_SOCIAL, STEP_BUDGET]))
      });
    },
  });

  useEffect(() => {
    if (!selectedCampaign.duration?.begin && selectedCampaign?.duration?.duration) {
      setAsSoonAsPossible(true);
      onChangeValue(DateTime.now().plus({ hours: hoursRevision }), 'dateFrom');
    } else {
      setAsSoonAsPossible(false);
    }
  }, []);

  const onChangeValueAsSoonAsPossible = async () => {
    setAsSoonAsPossible(!asSoonAsPossible);
    // console.debug({ asSoonAsPossible });
    if (!asSoonAsPossible) {
      await onChangeValue(DateTime.now().plus({ hours: hoursRevision }), 'dateFrom');
    }
  };

  const onConfirmLogin = () => {
    setShowModalLogin(false);
    history.push(AppRoutes.login());
  };

  const onConfirmSetupBusinessUnit = () => {
    setShowModalBu(false);
    history.push(AppRoutes.createCompany());
  };

  const onSubmitForm = async () => {
    const errors = await validateForm(values);
    console.debug(
      values.dateFrom.toFormat('dd/MM/yyyy HH:mm'),
      DateTime.now()
        .plus({ hours: Number(hoursRevision), minutes: minutesToAdd })
        .toFormat('dd/MM/yyyy HH:mm'),
      hoursRevision,
      minutesToAdd
    );
    if (values.duration < minDuration) {
      setFieldError('duration', 'minDurationRequired');
      errors.duration = 'minDurationRequired';
    }
    if (values.duration > maxDuration) {
      setFieldError('duration', 'maxDurationRequired');
      errors.duration = `minDurationRequired`;
    }
    if (
      values.dateFrom.plus({ minutes: minutesToAdd }).toMillis() <
      DateTime.now()
        .plus({ hours: Number(hoursRevision) })
        .toMillis()
    ) {
      setFieldError('dateFrom', 'dateMustBeGraterTheNow');
      errors.dateFrom = `dateMustBeGraterTheNow`;
    }
    console.debug({ errors });
    if (_.isEmpty(errors)) {
      handleSubmit();
    } else {
      toast(translate('check required fields'), TOAST.ERROR);
    }
  };

  const durationError = useMemo(() => {
    if (errors.duration === 'maxDurationRequired') {
      return t(`maxDurationRequired`, { maxDuration });
    }
    if (errors.duration === 'minDurationRequired') {
      return t(`minDurationRequired`, { minDuration });
    }
    return t(errors.duration);
  }, [errors.duration, maxDuration, minDuration]);

  const dateError = useMemo(() => {
    if (asSoonAsPossible) {
      return '';
    }
    if (errors.dateFrom === 'dateMustBeGraterTheNow') {
      const date = DateTime.now().plus({ hours: hoursRevision }).toFormat(DATE_AND_TIME_FORMAT);
      return t('dateMustBeGraterTheNow', { date });
    }
    return t(errors.dateFrom);
  }, [errors.dateFrom, hoursRevision, asSoonAsPossible]);

  useEffect(() => {
    setTimeout(() => {
      !_.get(selectedCampaign, 'duration.begin.ts') && onChangeValue(DateTime.now(), 'dateFrom');
      onChangeValue(_.get(selectedCampaign, 'category._id'), 'expertise');
    }, 200);
  }, []);

  const onExit = () => {
    dispatch(setCampaignCurrentStep(STEP_BUDGET));
  };

  const onChangeValue = async (value, fieldName) => {
    await setFieldValue(fieldName, value);
    await validateField(fieldName);
  };

  const onChangeDuration = async (e) => {
    await onChangeValue(Number(e.target.value), 'duration');
  };

  const onSetMapResponse = async (isValid) => {
    if (environment === ENVIRONMENTS.DEV) {
      setIsValidMap(true);
      return;
    }
    setIsValidMap(isValid);
  };

  return (
    <div id={'step-category'}>
      <ConfirmModal
        open={showModalLogin}
        title={t('modal finish campaign login title')}
        subtitle={t('modal finish campaign login description')}
        onClose={() => setShowModalLogin(false)}
        onConfirm={onConfirmLogin}
      />
      <ConfirmModal
        open={showModalBu}
        title={t('modal finish campaign set up business unit title')}
        subtitle={t('modal finish campaign set up business unit description')}
        onClose={() => setShowModalLogin(false)}
        onConfirm={onConfirmSetupBusinessUnit}
      />
      <CampaignModalName open={showModal} onClose={() => setShowModal(false)} />
      <Box mt="16px">
        <Typography variant="h3" mb="8px">
          {t(`step category title 1`)}
        </Typography>
        <Typography variant="body2">{t(`step category description 1`)}</Typography>

        <Grid xs={12} className={'grid-radio'}>
          {expertises.map((expertise) => {
            return (
              <InputRadio
                label={expertise.label}
                variant={'h5'}
                onChange={() => {
                  onChangeValue(expertise._id, 'expertise');
                }}
                checked={expertise._id === values.expertise}
              />
            );
          })}
          <Typography variant={'error'}>{!!errors.expertise && `*${t(errors.expertise)}`}</Typography>
        </Grid>
      </Box>
      <Box mt="16px">
        <Typography variant="h3" mb="8px">
          {t(`step category title 2`)}
        </Typography>
        <Typography variant="body2">
          {t(`step category description 2`, {
            minDuration,
            hoursRevision,
            maxDuration,
          })}
        </Typography>
        <Row alignCenter className={'row-date'}>
          <Grid sm={6} md={5}>
            <Checkbox checked={asSoonAsPossible} onChange={onChangeValueAsSoonAsPossible} label={t('asSoonAsPossible')} />
            {!asSoonAsPossible && (
              <InputDateTimePicker
                value={values.dateFrom}
                handleChange={async (newDate) => {
                  await onChangeValue(newDate, 'dateFrom');
                  // setAsSoonAsPossible(false);
                }}
                error={errors.dateFrom}
                helperText={dateError}
                minDate={DateTime.now().plus({ hours: hoursRevision })}
              />
            )}
          </Grid>
          {!asSoonAsPossible && (
            <Grid sm={6} md={5}>
              <Typography variant="body">{t('campaign start date')}</Typography>
            </Grid>
          )}
        </Row>
        <Row alignCenter className={'row-date'}>
          <Grid sm={6} md={5}>
            <InputText
              value={_.toString(values.duration)}
              onChange={onChangeDuration}
              InputProps={{
                inputProps: {
                  min: Number(minDuration),
                  max: Number(maxDuration),
                },
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              error={errors.duration}
              helperText={durationError}
              type="number"
            />
          </Grid>
          <Grid sm={6} md={5}>
            <Typography variant="body">{t('campaign days duration')}</Typography>
          </Grid>
        </Row>
      </Box>
      <StepCategoryMap onSetMapResponse={onSetMapResponse} />
      <Row spaceAround mt="16px" style={{ marginTop: 20 }}>
        <Button
          onClick={onExit}
          variant={'outlined'}
          text={t('go back')}
          size={'small'}
          sx={{
            minWidth: '12rem',
          }}
        />
        <Button
          onClick={onSubmitForm}
          text={t('continue')}
          size={'small'}
          sx={{
            minWidth: '12rem',
          }}
          disabled={!isValidMap}
        />
      </Row>
    </div>
  );
};
