import { createSlice } from '@reduxjs/toolkit'
import _, { get } from 'lodash'

const initialState = {
  filters: {},
  page: 1,
}

export const influencerSlice = createSlice({
  name: 'influencer',
  initialState,
  reducers: {
    setInfluencerFilters: (state, action) => {
      state.filters = action.payload;
    },
    setInfluencerPagination: (state, action) => {
      state.page = action.payload;
    },
  },
})

export const {
  setInfluencerFilters,
  setInfluencerPagination
} = influencerSlice.actions

export default influencerSlice.reducer

export const influencerSelectors = {
  filters: (state) => _.get(state, 'influencer.filters') || {},
  pagination: (state) => _.get(state, 'influencer.page') || 1,
}


