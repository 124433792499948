import React, { memo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { campaignSelectors } from '../../../features/campaignSlice';
import { Button, Col, Icon, InputFile, InputNumber, InputText, Row, TextEditor, ImageContainer, VideoContainer } from '../../ui';
import { shallowEqual, useSelector } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';
import './ProductDetail.scss';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../app/routers';
import { Video } from '../../ui/image/video';
import { uploadFile, uploadImage } from '../../../api/upload';
import { deleteAsset } from '../../../utils';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';

const uploadButtonStyle = {
  borderRadius: '0.5rem',
  width: '8rem',
  height: '2.5rem',
};

const ProductDetail = ({ errors, setError, hasCustomContents, onOpenPreview, productValues, setProductValues, children }) => {
  const selectedCampaign = useSelector(campaignSelectors.selected, shallowEqual);
  const businessUnitSelected = useSelector(businessUnitSelectors.selected);
  const productCampaign = productValues;
  const history = useHistory();
  const [loadingFile, setLoadingFile] = useState({
    terms: false,
    privacy: false,
  });

  const { t } = useTranslation();

  const changeProductField = (productFieldName, productFieldValue) => {
    setProductValues((prevProd) => {
      return {
        ...prevProd,
        [productFieldName]: productFieldValue,
      };
    });
  };

  const onShowPreview = () => {
    onOpenPreview();
    history.push({
      pathname: AppRoutes.product(selectedCampaign?._id || 'new'),
      state: {
        product: productCampaign,
        businessUnit: businessUnitSelected,
      },
    });
  };

  const onChangeField = async (value, fieldName) => {
    changeProductField(fieldName, value);
    setError((prevError) => _.omit(prevError, `${fieldName}`));
  };

  const onChangePrice = (value, fieldName) => {
    setProductValues((prevProd) => {
      return {
        ...prevProd,
        price: {
          ...productCampaign.price,
          [fieldName]: value,
        },
      };
    });
    setError((prevError) => _.omit(prevError, `price.${fieldName}`));
  };

  const onChangePriceValue = async (e) => {
    const { value } = e.target;
    await onChangePrice(value, 'value');
  };

  const onChangeDelivery = (value, fieldName) => {
    setProductValues((prevProd) => {
      return {
        ...prevProd,
        delivery: {
          ...productCampaign.delivery,
          [fieldName]: value,
        },
      };
    });
    setError((prevError) => _.omit(prevError, `delivery.${fieldName}`));
  };

  const onDeleteField = (fieldName) => {
    console.log({ fieldName });
    setProductValues((prevProd) => {
      return { ...prevProd, [fieldName]: [] };
    });
  };

  const onUploadFile = async (uploaded, fieldName) => {
    setLoadingFile((prevState) => ({ ...prevState, [fieldName]: true }));
    const uploadedFile = await uploadFile(uploaded.uri, `campaign/${selectedCampaign._id}/${fieldName}`, fieldName);
    setProductValues((prevProd) => {
      return {
        ...prevProd,
        [fieldName]: {
          ...uploadedFile,
        },
      };
    });
    setLoadingFile((prevState) => ({ ...prevState, [fieldName]: false }));
  };

  return (
    <Box mt="16px">
      <Typography variant="h3" mb="8px">
        {hasCustomContents ? '4. ' : '3. '}
        {t('campaign type product')}
      </Typography>
      <Typography variant="lead" component="p" mb="16px">
        {t(`campaign product title`)}
      </Typography>
      <Col fullWidth>
        <InputText
          label={t('name')}
          fullWidth
          onBlur={(v) => console.log(v)}
          onChange={(v) => onChangeField(v.target.value, 'name')}
          value={_.get(productCampaign, 'name')}
          error={errors?.name}
          helperText={t(errors?.name)}
          style={{ marginBottom: 8 }}
          variant={'outlined'}
        />
        <TextEditor
          error={errors?.description}
          style={{ marginTop: '0.5rem' }}
          initial={_.get(productCampaign, 'description')}
          placeholder={'description'}
          onChange={(v) => onChangeField(v, 'description')}
          toolbarOptions={{
            options: ['inline'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
          }}
        />
        <UploadProfileImages onChangeField={onChangeField} productValues={productCampaign} setProductValues={setProductValues} errors={errors} setError={setError} />
        <Section title={'price'} subtitle={'priceSubtitle'}>
          <Row fullWidth>
            <Col md={8}>
              <InputNumber
                label={t('')}
                fullWidth
                onChange={onChangePriceValue}
                value={_.toString(productCampaign?.price?.value)}
                error={errors['price.value']}
                helperText={t(errors['price.value'])}
                type="number"
                variant={'outlined'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{_.get(productCampaign, 'price.currency.values[0]')}</InputAdornment>,
                }}
              />
            </Col>
          </Row>
        </Section>
        <Section title={'deliveryCondition'} subtitle={'deliveryConditionSubtitle'}>
          <InputText
            label={t('')}
            fullWidth
            error={errors['delivery.condition']}
            helperText={t(errors['delivery.condition'])}
            onChange={(v) => onChangeDelivery(v.target.value, 'condition')}
            value={_.get(productCampaign, 'delivery.condition')}
            variant={'outlined'}
          />
        </Section>
        <Section title={'deliveryTime'} subtitle={'deliveryTimeSubtitle'}>
          <InputText
            label={t('')}
            fullWidth
            error={errors['delivery.note']}
            helperText={t(errors['delivery.note'])}
            onChange={(v) => onChangeDelivery(v.target.value, 'note')}
            value={_.get(productCampaign, 'delivery.note')}
            variant={'outlined'}
          />
        </Section>
        <Section title={'productsTermsAndConditions'} subtitle={'productsTermsAndConditionsSubtitle'}>
          {_.get(productCampaign, 'terms.uri') ? (
            <FileRow title={'productsTermsAndConditions'} file={_.get(productCampaign, 'terms')} onDelete={() => onDeleteField('terms')} />
          ) : (
            <Col>
              <InputFile onUpload={(v) => onUploadFile(v, 'terms')} returnAll accept={'.pdf'} buttonStyle={uploadButtonStyle} loadingProp={loadingFile.terms} />
              {!!errors['terms'] && <span className={'errorColor text'}>{t(errors['terms'])}</span>}
            </Col>
          )}
        </Section>
        <Section title={'productsPrivacyPolicy'} subtitle={'productsPrivacyPolicySubtitle'}>
          {_.get(productCampaign, 'privacy.uri') ? (
            <FileRow title={'productsPrivacyPolicy'} file={_.get(productCampaign, 'privacy')} onDelete={() => onDeleteField('privacy')} />
          ) : (
            <Col>
              <InputFile onUpload={(v) => onUploadFile(v, 'privacy')} returnAll accept={'.pdf'} buttonStyle={uploadButtonStyle} loadingProp={loadingFile.privacy} />
              {!!errors['privacy'] && <span className={'errorColor text'}>{t(errors['privacy'])}</span>}
            </Col>
          )}
        </Section>
        <Section title={'productPayPal'} subtitle={'productPayPalSubtitle'}>
          {children}
        </Section>
        <Row fullWidth className={'section'}>
          <Button
            onClick={onShowPreview}
            fullWidth
            variant={'outlined'}
            text={t('show preview')}
            disabled={!_.isEmpty(errors)}
            style={{
              borderRadius: '0.5rem',
            }}
          />
        </Row>
      </Col>
    </Box>
  );
};

const Section = ({ title, subtitle, children, error }) => {
  const { t } = useTranslation();

  return (
    <Col className={'section'}>
      <Row fullWidth>
        <Typography variant="h3">
          <span>{t(title)}</span>
          <span className={`${!!error && 'errorColor'}`}>&nbsp;*&nbsp;</span>
        </Typography>
      </Row>
      {!!subtitle && (
        <Row>
          <Typography variant="body2" mb="0.4rem">
            <span>{t(subtitle)}</span>
          </Typography>
        </Row>
      )}
      {children}
    </Col>
  );
};

const FileRow = ({ title, file, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <span>{t(title)}</span>
      <div className={'close-icon'}>
        <Icon icon={'fas fa-times'} onClick={onDelete} />
      </div>
    </Row>
  );
};

export const UploadProfileImages = ({ productValues, onChangeField, setProductValues, errors, setError }) => {
  const { t } = useTranslation();
  const selectedCampaign = useSelector(campaignSelectors.selected);
  const [loading, setLoading] = useState(false);
  const images = productValues?.images || [];
  const video = productValues?.video || [];

  const onUploadImage = async (uploaded) => {
    console.debug({ uploaded });
    setLoading(true);
    try {
      const uploadedFile = await uploadImage(uploaded.uri, `campaign/${selectedCampaign._id}/product/${(Math.random() * 10000).toFixed(0)}`, uploaded.type.includes('image') ? 'image' : 'video');
      console.debug({ uploadedFile });
      if (!uploadedFile) {
        setLoading(false);
        return Promise.reject('error');
      }

      setError((prevError) => _.omit(prevError, `assets`));

      if (uploaded.type.includes('image')) {
        const newImages = [...images, { ...uploadedFile }];
        onChangeField(newImages, 'images');
      }

      if (uploaded.type.includes('video')) {
        const newVideos = [...video, { ...uploadedFile }];
        onChangeField(newVideos, 'video');
      }
      setLoading(false);
    } catch (e) {
      console.debug({ e });
      setLoading(false);
    }
  };

  const onClickDelete = async (assetToDelete) => {
    setProductValues((prevProd) => {
      return {
        ...prevProd,
        images: images.filter((image) => image.uri !== assetToDelete.uri),
        video: video.filter((singleVideo) => singleVideo.uri !== assetToDelete.uri),
      };
    });
  };

  console.debug({ errors });

  return (
    <Col style={{ marginTop: '1rem' }}>
      <Section title={t(`imagesVideoProduct`)} subtitle={t('upload images product description')}>
        <InputFile onUpload={onUploadImage} returnAll accept={'.png,.jpeg,.jpg,.webp,.mp4'} multiple loadingProp={loading} buttonStyle={uploadButtonStyle} />
        {!!errors['assets'] && <span className={'errorColor text'}>{t(errors['assets'])}</span>}
        <Row sm={12} md={12}>
          {images && images.map((image) => <ImageContainer image={image} onClickDelete={onClickDelete} />)}
        </Row>
        <Row sm={12} md={12}>
          {video && video.map((asset) => <VideoContainer asset={asset} onClickDelete={onClickDelete} />)}
        </Row>
      </Section>
    </Col>
  );
};

export default memo(ProductDetail);
