import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './StepBudget.scss';
import { Col, InputText, Row } from '../../ui';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { checkPromoCode, deletePromo } from '../../../api';
import { campaignSelectors } from '../../../features/campaignSlice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../utils/hook';
import { userSelectors } from '../../../features/userSlice';
import { AuthRequiredModal } from '../modals';
import { AppRoutes } from '../../../app/routers';
import { campaignStatus } from '../../../utils/const';

export const StepBudgetPromo = ({ values, setFieldValue, onChangeValue, onSubmitForm, totalTaxedCost, campaignType }) => {
  const history = useHistory();
  const userToken = useSelector(userSelectors.token);
  const [modal, { open: openModal, close: closeModal }] = useModal();
  const selectedCampaign = useSelector(campaignSelectors.selected);
  const promoId = _.get(selectedCampaign, 'budget.promo._id');
  const totalCalculatedCost = _.get(selectedCampaign, 'budget.total.value', 0);
  const { t } = useTranslation();
  const disablePromo = promoId || selectedCampaign.campaignStatus === campaignStatus.NOT_APPROVED

  const onCheckCode = async () => {
    if (!userToken) {
      openModal();
      return;
    }
    if (!values.promoCode) {
      return;
    }
    const submitRes = await onSubmitForm();
    console.log({ submitRes });
    if (submitRes) {
      console.debug({ values });
      await checkPromoCode(values.promoCode, selectedCampaign._id);
    }
  };

  const onDeletePromo = async () => {
    if (!userToken) {
      openModal();
      return;
    }
    if (promoId) {
      await setFieldValue('promoCode', '');
      await deletePromo(promoId, selectedCampaign._id);
    }
  };

  const onConfirmCloseModal = () => {
    history.push(AppRoutes.login());
  };

  const onChangePromoCode = (e) => {
    onChangeValue(e.target.value, 'promoCode');
  };

  return (
    <Box mt="16px">
      <AuthRequiredModal onClose={closeModal} open={modal} onConfirm={onConfirmCloseModal} />
      <Typography variant="h3" mb="8px">
        {t(`${campaignType}.budget.title3`)}
      </Typography>
      <Typography variant="body2" mb="16px">
        {promoId ? t(`remove promo to edit budget`) : t(`complete all budget to set promo`)}
      </Typography>
      <Row alignCenter>
        <Grid sm={5} md={3}>
          <InputText label={t('code')} name="promoCode" value={values.promoCode} variant={'outlined'} onChange={onChangePromoCode} disabled={disablePromo} />
        </Grid>
        <Grid sm={5} md={3}>
          {!promoId ? (
            <Typography variant="underline" onClick={onCheckCode}>
              {t('insert')}
            </Typography>
          ) : (
            <Typography variant="underline" onClick={onDeletePromo}>
              {t('delete')}
            </Typography>
          )}
        </Grid>
      </Row>
      {promoId && (
        <Row alignCenter>
          <Grid sm={5} md={3} />
          <Grid sm={5} md={3}>
            <Typography variant="h5">{t('total cost')}</Typography>
            <div className={'spacer'} />
            <Typography variant="h5">{t('total cost with promo')}</Typography>
          </Grid>
          <Col flexEnd sm={5} md={6}>
            <Typography variant="h5" style={{ textDecoration: 'line-through' }}>
              {totalTaxedCost.toFixed(2)} €
            </Typography>
            <div className={'spacer'} />
            <Typography variant="h4">{totalCalculatedCost.toFixed(2)} €</Typography>
          </Col>
        </Row>
      )}
    </Box>
  );
};
