import * as yup from 'yup';
import { dismissMode } from '../components/layout/modals/CampaignModalDismiss';
import { campaignTypes, CONTACT_TYPES, LANDING_PAGE } from './const';
import _ from 'lodash';

export const ERR = {
  required: 'required field',
  password: 'password rules',
  minLength: 'password must be at least 8 characters',
  maxLength: 'maxLengthError',
  invalid: 'invalid',
  email: 'field must be a valid email',
  phoneInvalid: 'phoneInvalid',
  invalidNumber: 'invalidNumber',
  invalidDate: 'invalidDate',
};

export const REGEX = {
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+.-])[A-Za-z\d@$!%*?&+.-]{8,}$/,

  // con il +
  TELEPHONE: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const requiredSchema = yup.string().required(ERR.required);
export const passwordSchema = yup.string().matches('^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}', ERR.password).required(ERR.required);

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string(),
  newPassword: yup.string().matches('^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}', ERR.password).required(ERR.required),
});

export const createCompanySchema = {
  page1: yup.object().shape({
    name: yup.string().required(ERR.required),
    country: yup.object().nullable().required(ERR.required),
    vat: yup.string().required(ERR.required),
  }),
  page2: yup.object().shape({
    description: yup.string().required(ERR.required),
  }),
  page3: yup.object().shape({
    isItalian: yup.boolean(),
    address: yup.string().required(ERR.required),
    hasIVA: yup.boolean(),
    electronicInvoice: yup.boolean(),
    pec: yup
      .string()
      .email(ERR.email)
      .when(['electronicInvoice', 'isItalian'], {
        is: (hasIVA, isItalian) => !!hasIVA && !!isItalian,
        then: yup.string().required(ERR.required),
      }),
    sdi: yup.string().when(['electronicInvoice', 'isItalian'], {
      is: (hasIVA, isItalian) => !!hasIVA && isItalian,
      then: yup.string().required(ERR.required),
    }),
  }),
  page4: yup.object().shape({}),
  page5: yup.object().shape({
    email: yup.string().required(ERR.required).email(),
    prefix: yup.string().required(ERR.required),
    number: yup.string().matches(phoneRegExp, ERR.phoneInvalid),
  }),
  page6: yup.object().shape({
    email: yup.string().required(ERR.required).email(),
  }),
};

export const campaignModalName = yup.object().shape({
  name: yup.string().required(ERR.required),
});
export const baseCampaignSchema = {
  social: yup.object().shape({
    brief: yup.string().required(ERR.required).max(300, ERR.maxLength),
    hasCustomContents: yup.string(),
    instructions: yup.string(),
    socialSelected: yup.array(),
    link: yup.string(),
    info: yup.string(),
    otherSocial: yup.boolean(),
  }),
  budget: yup.object().shape({
    extraTip: yup.number().min(0).required(ERR.required),
    // extraTip: yup.string().matches(phoneRegExp, 'phone number is not valid')
    promoCode: yup.string(),
    quantity: yup.number().positive().typeError(ERR.invalidNumber).required(ERR.required),
    totalCost: yup.number().positive().required(ERR.required),
  }),
  category: yup.object().shape({
    expertise: yup.string().required(ERR.required),
    dateFrom: yup.date().typeError(ERR.invalidDate).nullable().required(ERR.required),
    duration: yup.number().typeError(ERR.invalidNumber).required(ERR.required),
  }),
};

const link = yup.object().shape({
  mimeType: yup.string().required(ERR.required),
  uri: yup.string().required(ERR.required),
});

export const productSchema = yup.object().shape({
  name: yup.string().required(ERR.required),
  description: yup.string().required(ERR.required),
  price: yup.object().shape({
    currency: yup.mixed().required(ERR.required),
    value: yup.number().positive().required(ERR.required),
  }),
  delivery: yup.object().shape({
    condition: yup.string().required(ERR.required),
    note: yup.string().required(ERR.required),
  }),
  images: yup.array().min(1, ERR.required),
  video: yup.array(),
  privacy: yup.object().nullable().required(ERR.required),
  terms: yup.object().nullable().required(ERR.required),
});

export const campaignValidations = {
  [campaignTypes.PayPerClick]: {
    ...baseCampaignSchema,
    social: yup.object().shape({
      brief: yup.string().required(ERR.required).max(300, ERR.maxLength),
      hasCustomContents: yup.boolean(),
      landingPage: yup.string().required(ERR.required),
      hash: yup.string().when(['landingPage'], {
        is: (landingPage) => landingPage === LANDING_PAGE.INTERNAL,
        then: yup.string().required(ERR.required),
      }),
      instructions: yup.string(),
      socialSelected: yup.array(),
      link: yup.string().when(['landingPage'], {
        is: (landingPage) => landingPage === LANDING_PAGE.EXTERNAL,
        then: yup.string().required(ERR.required),
      }),
      info: yup.string().when(['hasCustomContents'], {
        is: (hasCustomContents) => hasCustomContents,
        then: yup.string().required(ERR.required),
      }),
      otherSocial: yup.boolean(),
    }),
  },
  [campaignTypes.PayPerSales]: {
    ...baseCampaignSchema,
    social: yup.object().shape({
      brief: yup.string().required(ERR.required).max(300, ERR.maxLength),
      paypalId: yup.string().required(ERR.required),
      hasCustomContents: yup.boolean(),
      instructions: yup.string(),
      socialSelected: yup.array(),
      link: yup.string(),
      info: yup.string().when(['hasCustomContents'], {
        is: (hasCustomContents) => hasCustomContents,
        then: yup.string().required(ERR.required),
      }),
      otherSocial: yup.boolean(),
    }),
    product: {
      ...productSchema,
    },
  },
  [campaignTypes.PayPerPostEngagement]: {
    ...baseCampaignSchema,
  },
  [campaignTypes.PayPerContact]: {
    ...baseCampaignSchema,
    social: yup.object().shape({
      brief: yup.string().required(ERR.required).max(300, ERR.maxLength),
      hasCustomContents: yup.boolean(),
      instructions: yup.string(),
      socialSelected: yup.array(),
      link: yup.string(),
      info: yup.string().when(['hasCustomContents'], {
        is: (hasCustomContents) => hasCustomContents,
        then: yup.string().required(ERR.required),
      }),
      otherSocial: yup.boolean(),
      contactType: yup.string().required(ERR.required),
    }),
  },
};

export const dismissModalSchema = yup.object().shape({
  mode: yup.string().required(ERR.required),
  notes: yup.string().when('mode', {
    is: (mode) => mode === dismissMode.DECLINE,
    then: yup.string().required(ERR.required),
  }),
});

export const productRegisterForm = yup.object().shape({
  firstName: yup.string().required(ERR.required),
  lastName: yup.string().required(ERR.required),
  email: yup.string().required(ERR.required).email(),
  vat: yup.string().required(ERR.required),
  // number: yup.string().required(ERR.required).matches(REGEX.TELEPHONE, ERR.phoneInvalid),
  number: yup.string().required(ERR.required).min(4, ERR.phoneInvalid),
  prefix: yup.string().required(ERR.required),
  shippingAddress: yup.string().required(ERR.required),
  quantity: yup.number().required(ERR.required),
});

export const contactFormSchema = {
  [CONTACT_TYPES.EMAIL]: yup.object().shape({
    email: yup.string().required(ERR.required).email(),
  }),
  [CONTACT_TYPES.PHONE]: yup.object().shape({
    prefix: yup.string().required(ERR.required),
    number: yup.string().required(ERR.required).matches(phoneRegExp, ERR.phoneInvalid),
  }),
  [CONTACT_TYPES.EMAIL_PHONE]: yup.object().shape({
    email: yup.string().required(ERR.required).email(),
    prefix: yup.string().required(ERR.required),
    number: yup.string().required(ERR.required).matches(phoneRegExp, ERR.phoneInvalid),
  }),
};

export const validateProduct = async (productValues) => {
  const regex = /(<([^>]+)>)/gi;
  try {
    const parsedProduct = productSchema.cast({ ...productValues, description: productValues.description.replace(regex, '') });
    console.debug({ parsedProduct });
    await productSchema.validate(parsedProduct, { abortEarly: false });
    return Promise.resolve({});
  } catch (e) {
    console.debug({ e });
    const errors = _.reduce(
      e.inner,
      (result, error) => {
        result[error.path] = error.message;
        return result;
      },
      {}
    );
    return Promise.resolve(errors);
  }
};

export const profileSchema = yup.object().shape({
  firstName: yup.string().required(ERR.required),
  lastName: yup.string().required(ERR.required),
  companyRole: yup.string().required(ERR.required),
});

export const standardFormikProps = {
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
};
