import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCampaignToCard,
  campaignSelectors,
} from '../../../features/campaignSlice';
import { BaseModal, Button, Col, InputText, Row } from '../../ui';
import './CampaignModalName.scss';
import { Card, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { campaignModalName } from '../../../utils/validations';
import { businessUnitResubmitCampaign, getBusinessUnitCampaigns, getCampaignDetail, patchCampaign } from '../../../api';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../app/routers';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { campaignStatus } from '../../../utils/const';

export const CampaignModalName = ({ open, onClose }) => {
  const campaign = useSelector(campaignSelectors.selected);
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const showResubmit = campaign.campaignStatus === campaignStatus.NOT_APPROVED

  const date = DateTime.now();
  const initialName =
    _.get(campaign, 'name') ||
    `${date.day}${date.month}${date.year}_${_.toUpper(campaign.typeof)}_${_.get(
      businessUnit,
      'name'
    )}`;

  const {
    handleSubmit,
    setFieldValue,
    isValid,
    errors,
    validateField,
    validateForm,
    values,
  } = useFormik({
    initialValues: {
      name: initialName,
    },
    validationSchema: campaignModalName,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async ({ name }) => {
      console.log({ name });
      await patchCampaign(campaign._id, { name });
      const res = await getCampaignDetail(campaign._id);
      dispatch(addCampaignToCard(res));
      history.replace(AppRoutes.cart());
      onClose();
    },
  });

  const onResubmit = async () => {
    await patchCampaign(campaign._id, { name: values.name });
    await getCampaignDetail(campaign._id);
    await businessUnitResubmitCampaign(campaign._id)
    await getBusinessUnitCampaigns()
    history.replace(AppRoutes.cart());
    onClose();
  }

  const onChangeName = async (e) => {
    const value = e.target.value;
    await setFieldValue('name', value);
    await validateField('name');
  };

  return (
    <BaseModal open={open} onClose={onClose}>
      <Card id={'campaign-modal-name'}>
        <Typography variant="h2">{t('select title campaign')}</Typography>
        <Typography variant="body2">
          {t('select title campaign description')}
        </Typography>
        <Col style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <InputText
            label={t('name')}
            fullWidth
            type="text"
            onChange={onChangeName}
            value={values.name}
            error={!!errors.name}
            helperText={t(errors.name)}
          />
        </Col>
        <Row alignCenter spaceAround>
          <Button onClick={handleSubmit} style={{ minWidth: '10rem' }}>
            {t('confirm')}
          </Button>
          {showResubmit && <Button onClick={onResubmit} style={{ minWidth: '10rem' }}>
            {t('resubmit to enchora')}
          </Button> }
        </Row>
      </Card>
    </BaseModal>
  );
};
