import React, { memo } from 'react';
import './companyData3.scss';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Button, Checkbox, Col, InputSelect, InputText, Row } from '../../ui';
import { patchBusinessUnit } from '../../../api';
import { useFormik } from 'formik';
import { createCompanySchema } from '../../../utils/validations';
import { useSelector } from 'react-redux';
import { CATALOGUES_TYPES } from '../../../utils/const';
import _ from 'lodash';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { isAdminOfBusinessUnit } from '../../../utils';

export const CreateCompanyData3 = memo(({ fromCart }) => {
  const { t } = useTranslation();
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const disableForm = !isAdminOfBusinessUnit();

  const isItalian = _.get(businessUnit, 'country.values[0]') === 'IT';

  const { handleSubmit, setFieldValue, errors, validateField, validateForm, values } = useFormik({
    initialValues: {
      isItalian: isItalian,
      address: businessUnit?.billing?.address,
      pec: businessUnit?.billing?.pec,
      sdi: businessUnit?.billing?.sdi,
      hasIVA: businessUnit?.billing ? businessUnit?.billing?.hasIVA : true,
      electronicInvoice: businessUnit?.billing?.electronicInvoice,
    },
    validationSchema: createCompanySchema.page3,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      // console.log({ data });
      const newData = {
        ...data,
        hasIVA: isItalian || !!data.hasIVA,
        electronicInvoice: !!data.electronicInvoice,
        isItalian: undefined,
      };
      validateForm().then(async () => {
        await patchBusinessUnit({ billing: newData });
      });
    },
  });

  const onChangeValue = async (value, fieldName) => {
    await setFieldValue(fieldName, value);
    await validateField(fieldName);
  };

  return (
    <Col id={'create-company-data3'}>
      {!fromCart && (
        <>
          <Typography variant={'h1'}>{t('billing')}</Typography>
          <Typography variant={'h6'}>{t('company billing subtitle')}</Typography>
        </>
      )}
      <Row className={'row-address'}>
        <Row xs={12}>
          <Typography variant={'body2'}>{t('insert all address billing information')}</Typography>
        </Row>
        <Grid xs={12} md={8}>
          <InputText
            label={t('billing address')}
            name="pec"
            value={values.address}
            onChange={(e) => onChangeValue(e.target.value, 'address')}
            variant="standard"
            error={errors.address}
            helperText={t(errors.address)}
            disabled={disableForm}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <InputSelect
            type={CATALOGUES_TYPES.COUNTRIES}
            label={t('country')}
            name="country"
            disabled={true}
            onChange={(value) => onChangeValue(value, 'country')}
            value={businessUnit?.country?._id}
            variant="standard"
          />
        </Grid>
      </Row>

      {isItalian ? (
        <Checkbox
          checked={values.electronicInvoice}
          label={t('electronic invoicing obligation')}
          name={'electronicInvoice'}
          onChange={(e) => onChangeValue(e.target.checked, 'electronicInvoice')}
          disabled={disableForm}
        />
      ) : (
        <Checkbox checked={values.hasIVA} label={t('apply VAT')} name={'hasIVA'} onChange={(e) => onChangeValue(e.target.checked, 'hasIVA')} disabled={disableForm} />
      )}

      {values.electronicInvoice && isItalian && (
        <Row xs={12} className={'row-pec'}>
          <Grid xs={12} md={6}>
            <InputText
              label={t('pec')}
              name="pec"
              value={values.pec}
              onChange={(e) => onChangeValue(e.target.value, 'pec')}
              variant="standard"
              error={errors.pec}
              helperText={t(errors.pec)}
              disabled={disableForm}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <InputText
              label={t('sdi')}
              name="sdi"
              value={values.sdi}
              onChange={(e) => onChangeValue(e.target.value, 'sdi')}
              variant="standard"
              error={errors.sdi}
              helperText={t(errors.sdi)}
              disabled={disableForm}
            />
          </Grid>
        </Row>
      )}
      {fromCart ? (
        <Row alignCenter justifyCenter>
          <Button text={t('edit')} size={'small'} onClick={handleSubmit} className={'save-button'} disabled={disableForm} variant={'outlined'} />
        </Row>
      ) : (
        <Button text={t('save')} onClick={handleSubmit} className={'save-button'} disabled={disableForm} />
      )}
    </Col>
  );
});
