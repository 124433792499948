import React, { useEffect, useState } from 'react';
import { BaseModal, Button, Col, Icon, Row } from '../../ui';
import './ProfileDeleteModal.scss';
import { Card, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  deleteMe,
  getBusinessUnitsCurrentUser,
  sendBusinessUnitOwnership,
} from '../../../api';
import _ from 'lodash';
import { theme } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../app/routers';
import { CREATE_COMPANY_STEPS } from '../../../utils/const';
import { logout } from '../../../api/auth';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';

export const ProfileDeleteModal = ({ open, onClose, onDelete }) => {
  const allBusinessUnits = useSelector(businessUnitSelectors.list);
  const userDetail = useSelector(userSelectors.detail);
  const [businessAdmin, setBusinessAdmin] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const notOtherAdmin = !businessAdmin.length;

  console.log({ userDetail });

  useEffect(() => {
    const getData = async () => {
      console.log({ allBusinessUnits });
      const unitsAdmin = allBusinessUnits?.filter((businessUnit) => {
        return businessUnit.businessUsers.some((businessUser) => {
          return businessUser.role === 'admin';
        });
      });
      console.log({ unitsAdmin });
      setBusinessAdmin(unitsAdmin);
    };
    getData();
  }, []);

  const { t } = useTranslation();

  const onClickConfirm = async () => {
    try {
      setLoading(true);
      if (!notOtherAdmin) {
        // const promises = businessAdmin.map(bu => {
        //   return sendBusinessUnitOwnership(bu._id, 'admin@enchora.com')
        // })
        // const results = await Promise.all(promises)
        // console.log({results})
        // const responses
      }
      await deleteMe();
      await logout();
      history.replace(AppRoutes.home());
    } catch (e) {
      console.log({ e });
    }
    setLoading(false);
    onClose();
  };

  console.log({ notOtherAdmin });
  return (
    <BaseModal open={open} onClose={onClose}>
      <Card id={'profile-delete-modal'}>
        <Typography variant="h2">{t('delete profile')}</Typography>
        <Col className={'subtitle'}>
          <Typography variant="body">{t('delete profile subtitle')}</Typography>
          {!notOtherAdmin && (
            <Typography variant="body">{t('delete profile tip')}</Typography>
          )}
        </Col>
        <Col>
          {businessAdmin?.map((businessUnit) => (
            <BusinessUnit key={businessUnit._id} businessUnit={businessUnit} />
          ))}
        </Col>
        <Row spaceBetween mt={'2rem'}>
          <Button onClick={onClose} variant={'outlined'}>
            {t('cancel')}
          </Button>
          <Button onClick={onClickConfirm}>{t('confirm')}</Button>
        </Row>
      </Card>
    </BaseModal>
  );
};

const BusinessUnit = ({ businessUnit }) => {
  const style = { width: '1.5rem', height: '1.5rem' };
  const history = useHistory();

  const goToCompany = () => {
    history.push(
      `${AppRoutes.createCompany()}/${CREATE_COMPANY_STEPS.REFERENTS}`
    );
  };

  return (
    <Row spaceBetween alignCenter>
      <Typography variant="bold">{businessUnit.name}</Typography>
      <Typography variant="body">{businessUnit.tenant}</Typography>
      <IconButton onClick={goToCompany}>
        <Icon
          icon={'far fa-location-arrow'}
          color={theme['primaryColor']}
          sx={{ padding: '0.5rem' }}
          style={style}
        />
      </IconButton>
    </Row>
  );
};
