import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './inputFile.scss';
import { Button } from '../button/button';
import { Col } from '../col/col';
import { EditImage } from '../editImage/editImage';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import { TOAST } from '../../../utils/const';
import Resizer from 'react-image-file-resizer';
import { b64toBlob, resizeFile } from '../../../utils';
import { Link } from '@mui/material';

export const InputFile = ({
  onUpload,
  editImage,
  className,
  maxDimension,
  resizeToStandard,
  disabled,
  accept,
  getFileName = false,
  label,
  buttonStyle,
  multiple,
  returnAll,
  link,
  loadingProp = false,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(loadingProp);
  const [imageUrl, setImageUrl] = useState('');
  const [file, setFile] = useState();

  useEffect(() => {
    setLoading(loadingProp);
  }, [loadingProp]);

  const onFileInput = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    setFile(file);
    let url = URL.createObjectURL(file);
    if (editImage) {
      setImageUrl(url);
      return;
    }
    if (resizeToStandard) {
      // const newQuality = resizeToQuality / file.size * 100
      // console.log({newQuality})
      const resizedBlob = await resizeFile(file);
      console.log({ resizedBlob });
      url = URL.createObjectURL(resizedBlob);
      setLoading(false);
    }

    if (getFileName) {
      onUpload(url, file.name);
      setLoading(false);
      return;
    }

    if (returnAll) {
      onUpload({ type: file.type, uri: url });
      setLoading(false);
      return;
    }

    onUpload(url);
    setLoading(false);
  };

  const handleUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const onEditImage = async (croppedImage) => {
    console.log({ maxDimension });
    if (maxDimension) {
      let blob = await fetch(croppedImage)
        .then((r) => r.blob())
        .then(
          (blobFile) => new File([blobFile], 'logo', { type: 'image/png' })
        );
      console.log({ blob });
      console.log({ maxDimension });
      if (blob.size > maxDimension) {
        toast(
          translate('business unit resize image dimension error'),
          TOAST.ERROR
        );
        return;
      }
    }
    // const croppedImage = URL.createObjectURL(imageBlob)
    onUpload(croppedImage);
    setLoading(false);
    console.log({ croppedImage });
  };

  return (
    <Col id={'input-file'} className={className}>
      {!!imageUrl && (
        <EditImage
          url={imageUrl}
          onClose={() => setImageUrl('')}
          onEdit={onEditImage}
          image={file}
        />
      )}
      <input
        type="file"
        name="file"
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        onChange={onFileInput}
        accept={accept}
        // multiple={multiple}
      />
      {!link && (
        <Button
          className={'button'}
          onClick={handleUploadClick}
          loading={loading}
          style={buttonStyle}
          text={label || t('select file')}
          disabled={disabled}
        />
      )}
      {link && (
        <Link onClick={handleUploadClick} className={'link'}>
          {t('edit profile image')}
        </Link>
      )}
    </Col>
  );
};
