import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  campaignSelectors,
  editCampaignSelected,
} from '../../../features/campaignSlice';
import { Col, InputFile, Row } from '../../ui';
import { Typography } from '@mui/material';
import { Video } from '../../ui/image/video';
import React from 'react';

export const InstructionVideo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaign = useSelector(campaignSelectors.selected);
  const video = campaign?.contents?.influencerContent?.instruction?.uri;

  const onUploadVideo = (uploaded) => {
    console.log({ uploaded });
    const contents = {
      ...campaign.contents,
      influencerContent: {
        ...campaign.contents.influencerContent,
        instruction: {
          mimeType: 'mp4',
          uri: uploaded,
          toUpload: true,
        },
      },
    };
    dispatch(editCampaignSelected({ contents }));
  };

  return (
    <Col>
      <Typography variant="h5" mt={'2rem'} mb={'1rem'}>
        {t(`instruction`)}
      </Typography>
      <Typography variant="body2" mb={'1rem'}>
        {t(`upload instruction description`)}
      </Typography>
      <InputFile onUpload={onUploadVideo} accept={'.mp4,.mov,.avi'} />
      <Row sm={12} mt={'1rem'}>
        {video && (
          <Video
            path={video}
            uri={video}
            loop={true}
            playing={true}
            muted={true}
          />
        )}
      </Row>
    </Col>
  );
};
