import React, {useEffect, useMemo, useState} from 'react'
import {BaseModal, Button, Row, Col, InputText, TextEditor, InputFile, Icon} from '../../ui';
import {Card, IconButton, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import './LandingPageCreator.scss'
import {LandingContent} from "../campaign/LandingContent";
import {useFormik} from "formik";
import {editorContent, theme} from './../../../utils/index'
import "draft-js/dist/Draft.css";
import {useHistory} from "react-router-dom";
import _ from 'lodash'
import {getLanding, patchLanding, postLanding} from "../../../api";
import {useSelector} from "react-redux";
import {campaignSelectors} from "../../../features/campaignSlice";


export const landingThemes = {
  enchora: {
    textColor: theme['black']
  }
}

const LandingPageCreator = () => {
  const { t } = useTranslation()
  const campaignSelected = useSelector(campaignSelectors.selected)
  const history = useHistory()
  const [landing, setLandingContent] = useState({})

  useEffect(() => {
    getData()
  }, [])


  const getData = async () => {
    console.log({campaignSelected})
    if(campaignSelected.advice.hash) {
      const landing = await getLanding(campaignSelected.advice.hash)
      console.log({landing})
      setLandingContent(landing)
    }
  }

  useEffect(() => {
    if(landing) {
      _.get(landing, 'description1') && setFieldValue('description1', landing.description1)
    }
  }, [landing])


  const {
    handleSubmit,
    setFieldValue,
    isValid,
    errors,
    validateField,
    validateForm,
    values,
  } = useFormik({
    initialValues: {
      title: _.get(landing, 'title', ''),
      description1: _.get(landing, 'description1', ''),
      description2: _.get(landing, 'description2', ''),
      description3: _.get(landing, 'description3', ''),
      callToAction: _.get(landing, 'callToAction', ''),
      callToActionLabel: _.get(landing, 'callToActionLabel', ''),
      background: _.get(landing, 'background', {}),
      logo: _.get(landing, 'logo', {}),
      layout: landingThemes['enchora'],
      theme: undefined
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      console.log({data})

      if(!!_.get(campaignSelected, 'advice.hash')) {
        await patchLanding(campaignSelected.advice.hash, data)
      } else {
        await postLanding(data)
      }

      onClose()
    },
  });

  const onClickConfirm = () => {
    handleSubmit()
  }

  const contentLanding = useMemo(() => {
    return _.cloneDeepWith(values)
  } ,[values])

  const onClose = () => {
    history.goBack()
  }

  const onChangeField = async (field, value) => {
    console.log(field, value)
    await setFieldValue(field, value)
    await validateField(field)
  }

  const onUploadLogo = async (uri, fileName) => {
    await onChangeField('logo', { uri, fileName, toUpload: true})
  }

  const onUploadBackgroundImage = async (uri, fileName) => {
    await onChangeField('background', {uri, fileName, toUpload: true})
  }

  return (
    <div id={'landing-page-creator'}>
      <Card>
        <Typography variant='h2'>{t('landing page')}</Typography>
        <Col className={'subtitle'}>
          <Typography variant='body'>{t('landing page subtitle')}</Typography>
        </Col>
        <Col>
          <LandingContent content={contentLanding} />
          <Col>
            <InputText
              label={t('title')}
              fullWidth
              onChange={(v) => onChangeField('title', v.target.value)}
              value={values.title}
              touched={true}
              style={{marginTop: 8}}
              variant={'outlined'}
            />
            <Row spaceBetween flex container md={12} style={{marginTop: '0.5rem'}}>
              <Col flex item style={{marginRight: '4px'}}>
                <InputText
                  label={t('callToAction')}
                  fullWidth
                  onChange={(v) => onChangeField('callToAction', v.target.value)}
                  value={values.callToAction}
                  variant={'outlined'}
                />
              </Col>
              <Col flex item style={{marginLeft: '4px'}}>
                <InputText
                  label={t('callToActionLabel')}
                  fullWidth
                  onChange={(v) => onChangeField('callToActionLabel', v.target.value)}
                  value={values.callToActionLabel}
                  variant={'outlined'}
                />
              </Col>
            </Row>
            <TextEditor
              style={{marginTop: '0.5rem'}}
              initial={values.description1}
              placeholder={'add description'}
              onChange={v => onChangeField('description1', v)}
            />
            <TextEditor
              style={{marginTop: '0.5rem'}}
              initial={values.description2}
              onChange={v => onChangeField('description2', v)}
              placeholder={'add description'}
            />
            <TextEditor
              style={{marginTop: '0.5rem'}}
              initial={values.description2}
              onChange={v => onChangeField('description3', v)}
              placeholder={'add description'}
            />
            <Row alignCenter spaceBetween>
              <InputFile onUpload={onUploadLogo} resizeToStandard getFileName accept={'.png,.jpeg'} className={'file-button'} label={t('select logo')} buttonStyle={{minWidth: '12rem'}}/>
              <div>
                <span>{_.get(values, 'logo.fileName', '')}</span>
                {!_.isEmpty(values.logo) && <IconButton className={'button'} onClick={() => onChangeField('logo', {})}>
                  <Icon icon={'fas fa-trash'} color={theme['errorColor']} sx={{padding: '0.5rem'}}/>
                </IconButton>}
              </div>
            </Row>
            <Row alignCenter spaceBetween>
              <InputFile onUpload={onUploadBackgroundImage} resizeToStandard getFileName accept={'.png,.jpeg'} className={'file-button'} label={t('select background')} buttonStyle={{minWidth: '12rem'}}/>
              <div>
                <span>{_.get(values, 'background.fileName', '')}</span>
                {!_.isEmpty(values.background) && <IconButton className={'button'} onClick={() => onChangeField('background', {})}>
                  <Icon icon={'fas fa-trash'} color={theme['errorColor']} sx={{padding: '0.5rem'}}/>
                </IconButton>}
              </div>
            </Row>
          </Col>
        </Col>
        <Row spaceBetween style={{marginTop: '1rem'}}>
          <Button onClick={onClose} variant={'outlined'}>{t('undo')}</Button>
          <Button onClick={onClickConfirm}>{t('confirm')}</Button>
        </Row>
      </Card>
    </div>
  )
}

export default LandingPageCreator
