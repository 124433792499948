import React, { useEffect, useState } from 'react';
import './BusinessUnitDetail.scss';
import { useParams } from 'react-router-dom';
import { getEnchorerBunDetail, getEnchorerInfluencerDetail } from '../../../api/enchorer';
import { Image, Row } from '../../../components/ui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const BusinessUnitDetailPage = () => {
  const [data, setData] = useState({});
  const { t } = useTranslation();
  let { bunId } = useParams();

  useEffect(() => {
    getBunData(bunId);
  }, [bunId]);

  const getBunData = async (bunId) => {
    if (!bunId) {
      return;
    }
    const data = await getEnchorerBunDetail(bunId);
    setData(data);
  };

  const onChangeValue = (fieldName) => (e) => {
    console.debug({ fieldName, e });
  };

  return (
    <div id={'bun-detail'}>
      <Row alignCenter>
        <Image path={_.get(data, 'avatar.uri')} className={'bun-detail-image'} />
        <span>{_.get(data, 'name', ' - ')}</span>
      </Row>
      <hr />
      <Row md={12} sm={12} xs={12} spaceBetween fullWidth></Row>
    </div>
  );
};

export default BusinessUnitDetailPage;
