import React from 'react';
import './TaskTooltipTemplate.scss';
import _ from 'lodash';
import { Icon, Row } from '../../ui';
import { normalizeCurrency, theme } from '../../../utils';
import { fromMillisToFormat } from '../../../utils';

const TaskTooltipTemplate = (task, campaign, t) => {
  const { duration, budget } = campaign;

  return (
    <div className="custom-task-edit-tooltip">
      <div className="custom-tooltip-title">{_.get(campaign, 'name')}</div>
      <div className="custom-tooltip-type">{_.get(campaign, 'typeof')}</div>
      <Row>
        <Icon icon={'far fa-calendar'} color={theme['white']} style={{ marginRight: '0.5rem' }} />
        <span>{t('from date')}&nbsp;</span>
        <span>{fromMillisToFormat(_.get(duration, 'begin.ts'))}&nbsp;</span>
        <span>{t('to date').toLowerCase()}&nbsp;</span>
        <span>{fromMillisToFormat(_.get(duration, 'end.ts'))} </span>
      </Row>
      <div className="custom-tooltip-budget">
        <span>{normalizeCurrency(budget?.total)} </span>(
        <span>
          {budget?.quantity} {t(`${campaign.typeof}.unit`).toLowerCase()}
        </span>
        )
      </div>
      <div className="custom-tooltip-influencer">
        {/*<span className={'influencer'}>*/}
        {/*  {_.get(campaign, 'numberOfInfluencers', 0)}&nbsp;*/}
        {/*  {t('influencers')}*/}
        {/*</span>*/}
        {/*<span> {t('active on this campaign')}</span>*/}
      </div>
    </div>
  );
};

export default TaskTooltipTemplate;
