import React from 'react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { headerMenu } from '../../../utils/const';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import { Col } from '../../ui';
import './header.scss';
import { AppRoutes } from '../../../app/routers';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import _ from 'lodash';

const Menu = () => {
  const [open, setOpen] = React.useState(false);
  const userRole = useSelector(userSelectors.role);
  const userToken = useSelector(userSelectors.token);
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleMenuClick = (menuItem) => {
    if (menuItem?.onClick) {
      menuItem.onClick();
      handleClick();
      return;
    }

    if (!userToken && menuItem.href === 'profile') {
      history.push(AppRoutes.login());
      handleClick();
      return;
    }

    history.push(AppRoutes[menuItem.href]());
    handleClick();
  };

  const filteredHeaderMenu = useMemo(() => {
    if (userToken) {
      return headerMenu[userRole];
    } else {
      return _.filter(headerMenu[userRole], (h) => !['createCompany'].includes(h.href));
    }
  }, [userToken, userRole]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative' }}>
        <Link underline="none" onClick={handleClick} role="link" color="inherit" className="e-header__link" aria-current={open ? 'page' : undefined}>
          {t('menu')}
        </Link>
        {open ? (
          <Col className={`menu ${userRole}`} flexEnd spaceAround>
            {filteredHeaderMenu.map((menuItem) => (
              <BaseLink onClick={() => handleMenuClick(menuItem)}>{t(menuItem.label)}</BaseLink>
            ))}
          </Col>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export default Menu;

const BaseLink = ({ children, onClick }) => {
  return (
    <Link underline="none" onClick={onClick} role="link" color="inherit" className="e-header__link">
      {children}
    </Link>
  );
};
