import React from 'react';
import './sidebar.scss';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { Icon, NavRegistration } from '../../ui';
import { AppRoutes } from '../../../app/routers';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { CREATE_COMPANY_STEPS } from '../../../utils/const';
import { useSelector } from 'react-redux';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';

const STEP_ICONS = {
  [CREATE_COMPANY_STEPS.COMPANY_DATA]: 'far fa-tasks',
  [CREATE_COMPANY_STEPS.COMPANY_PROFILE]: 'far fa-file-user',
  [CREATE_COMPANY_STEPS.BILLING_INFO]: 'far fa-receipt',
  [CREATE_COMPANY_STEPS.PAYMENT_INFO]: 'far fa-credit-card-front',
  [CREATE_COMPANY_STEPS.CONTACTS]: 'far fa-phone',
  [CREATE_COMPANY_STEPS.REFERENTS]: 'far fa-user-friends',
};

export const CreateCompanySidebar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const enableAllSidebar = !_.isEmpty(businessUnit);

  const handleClickNav = (step) => {
    history.push(AppRoutes.createCompany() + `/${step.href}`);
  };

  return (
    <Grid item xs={12} md={3} id={'create-company-sidebar'}>
      <Box sx={{ display: 'flex' }} justifyContent="space-between" flexDirection="column" height="100%">
        <NavRegistration
          onClick={handleClickNav}
          items={_.map(CREATE_COMPANY_STEPS, (stepName) => ({
            label: t(stepName.replace('-', ' ')),
            href: stepName,
            icon: <Icon icon={STEP_ICONS[stepName]} />,
            selected: location.pathname.search(stepName) !== -1,
            disabled: !(enableAllSidebar || stepName === CREATE_COMPANY_STEPS.COMPANY_DATA),
          }))}
        />
      </Box>
    </Grid>
  );
};
