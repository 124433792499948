import React, { memo, useState } from 'react';
import './companyData6.scss';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Button, Col, Icon, InputText, Row } from '../../ui';
import { deleteBusinessUnitInvitation, getBusinessUnitInvitations, postBusinessUnitInvitation, sendBusinessUnitOwnership } from '../../../api';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import { useFormik } from 'formik';
import { createCompanySchema } from '../../../utils/validations';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { isAdminOfBusinessUnit } from '../../../utils';
import { ConfirmModal } from '../modals';

export const CreateCompanyData6 = memo(({ showBack = true }) => {
  const { t } = useTranslation();
  const businessUnitInvitations = useSelector(businessUnitSelectors.selectedInvitations);
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const userDetail = useSelector(userSelectors.detail);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalSend, setShowModalSend] = useState(false);
  const users = businessUnit?.businessUsers || [];
  const disableForm = !isAdminOfBusinessUnit();

  const { handleSubmit, setFieldValue, errors, validateField, validateForm, values } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: createCompanySchema.page6,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      // console.log({ data });
      validateForm().then(async () => {
        setIsLoading(true);
        await postBusinessUnitInvitation(data.email);
        await getBusinessUnitInvitations();
        await setFieldValue('email', '');
        setIsLoading(false);
      });
    },
  });

  const onChangeValue = async (value, fieldName) => {
    console.log({ value, fieldName });
    await setFieldValue(fieldName, value);
    await validateField(fieldName);
  };

  const onClickDeleteUser = async (invitationId) => {
    await deleteBusinessUnitInvitation(invitationId);
  };

  const onClickSendOwner = async (invitationId) => {
    setShowModalSend(invitationId);
  };
  const onConfirmSendOwner = async () => {
    await sendBusinessUnitOwnership(showModalSend);
    setShowModalSend(false);
  };

  return (
    <Grid id={'create-company-data6'}>
      <ConfirmModal
        open={showModalSend}
        onConfirm={onConfirmSendOwner}
        onClose={() => setShowModalSend(false)}
        title={t('modal confirm change owner')}
        subtitle={t('modal confirm change owner description')}
      />
      <Typography variant={'h1'}>{t('referents')}</Typography>
      <Typography variant={'h6'}>{t('company referents subtitle')}</Typography>
      <Grid xs={12} className={'users'}>
        <Typography variant={'subtitle'}>{t('company users')}</Typography>
        {users.map((user) => {
          const isMyUser = user.account.email === userDetail.account.email;
          const isAdmin = user.role === 'admin';
          const userRole = user.role === 'admin' ? t('admin') : t('collaborator');
          return (
            <Row key={user.account.email} spaceBetween sm={12} md={10} className={'row-user'}>
              <Col sm={6}>
                <Typography variant={'body'}>{user.account.email}</Typography>
              </Col>
              <Col sm={4}>
                <Typography variant={'body'}>{userRole}</Typography>
              </Col>
              <Row xs={2} justifyEnd>
                {isAdmin && <Icon icon={'fad fa-user-crown'} className={'primary'} style={{ marginLeft: '0.5rem' }} />}
                {!isMyUser && !disableForm && !isAdmin && <Icon icon={'fas fa-paper-plane'} className={'primary'} onClick={() => onClickSendOwner(user._id)} style={{ marginLeft: '0.5rem' }} />}
                {!isMyUser && !disableForm && !isAdmin && <Icon icon={'fas fa-trash-alt'} className={'primary'} style={{ marginLeft: '0.5rem' }} onClick={() => onClickDeleteUser(user._id)} />}
                {isMyUser && !disableForm && <Icon icon={'fas fa-user-tie'} className={'primary'} style={{ marginLeft: '0.5rem' }} />}
              </Row>
            </Row>
          );
        })}
      </Grid>
      {!!businessUnitInvitations.length && (
        <Grid xs={12} className={'users'}>
          <Typography variant={'subtitle'}>{t('invited users')}</Typography>
          {businessUnitInvitations.map((user) => {
            return (
              <Row key={user._id} spaceBetween sm={12} md={10} className={'row-user'}>
                <Typography variant={'body'}>{user.opaque.mail}</Typography>
                <Typography variant={'body'}>{user.companyRole}</Typography>
                {!disableForm && <Icon icon={'fas fa-trash-alt'} className={'primary icon-delete'} onClick={() => onClickDeleteUser(user._id)} />}
              </Row>
            );
          })}
        </Grid>
      )}
      <Col className={'add-access'}>
        <Typography variant={'subtitle'}>{t('add access')}</Typography>
        <Grid xs={12} md={10}>
          <InputText
            label={t('email')}
            name="email"
            onChange={(e) => onChangeValue(e.target.value, 'email')}
            variant="standard"
            value={values.email}
            error={t(errors.email)}
            helperText={t(errors.email)}
            disabled={disableForm}
          />
        </Grid>
        <Button disabled={disableForm} text={t('invite')} onClick={handleSubmit} className={'invite-button'} loading={isLoading} />
      </Col>
    </Grid>
  );
});
