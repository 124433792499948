import {Radio, FormControlLabel, Grid, Typography} from '@mui/material'
import clsx from 'clsx'
import './inputRadio.scss'
import {Row} from '../row/row';

export const InputRadio = ({ label, checked, onChange, variant = 'h6', disabled, ...props }) => {

  const labelStyle = {

  }
  if (disabled) {
    labelStyle.color = 'grey'
  }

  return (
    <Row spaceBetween alignCenter className={clsx('input-radio')}>
      {label && <Typography variant={variant} style={labelStyle}>{label}</Typography>}
      <Radio
        checked={checked}
        onChange={onChange}
        value={label}
        name="radio-buttons"
        disabled={disabled}
        // inputProps={{ 'aria-label': 'A' }}
      />
    </Row>
)}


