import React, { memo, useEffect, useState } from 'react';
import { BaseModal } from '../../ui';
import './loginModal.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { appSelectors, setAppForgotPasswordModal, setAppLoginModal } from '../../../features/appSlice';
import { Link } from '@mui/material';
import CardLogin, { CARD_LOGIN_STEP_CHECK_ACCOUNT, CARD_LOGIN_STEP_SIGN_IN, CARD_LOGIN_VARIANT_SIGN_IN, CARD_LOGIN_VARIANT_SIGN_UP } from '../cards/CardLogin';
import { AUTH_ERROR_INVALID_PASSWORD, regexEmail, USER_NOT_EXIST_ERROR } from '../../../utils/const';
import { userExist } from '../../../api';
import { forgotPassword, signIn, createFirebaseUser, signInFacebook, signInGoogle } from '../../../api/auth';
import { AppRoutes } from '../../../app/routers';
import _ from 'lodash';
import { setUserDetail, userSelectors } from '../../../features/userSlice';
import { store } from '../../../app/store';
import { CARD_SIGNUP_STEP_WELCOME } from '../cards/CardSignup';
import { isCurrentUserEnchorer } from '../../../api/enchorer';

export const LoginModal = memo(() => {
  const { t } = useTranslation();
  const showModal = useSelector(appSelectors.loginModal);
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [variant, setVariant] = useState(CARD_LOGIN_VARIANT_SIGN_IN);
  const [step, setStep] = useState(CARD_LOGIN_STEP_CHECK_ACCOUNT);
  const [firebaseUserExists, setFirebaseUserExists] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = React.useState(true);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (error?.code === AUTH_ERROR_INVALID_PASSWORD) {
      setErrorMessage(t('wrong password'));
      return;
    }
    if (error?.code) {
      setErrorMessage(t('generic error'));
    }
  }, [error]);

  useEffect(() => {
    setEmail('');
    setErrorMessage(null);
    setStep(CARD_LOGIN_STEP_CHECK_ACCOUNT);
  }, []);

  const handleClearEmail = () => {
    setStep(CARD_LOGIN_STEP_CHECK_ACCOUNT);
  };

  const handleChangeEmail = (e) => {
    setErrorMessage(null);
    setErrorMessage(null);
    setEmail(e.target.value);
  };

  const handleSubmitEmail = async (email) => {
    const isValid = regexEmail.test(email);
    setLoading(true);

    if (!isValid) {
      setErrorMessage(t('invalid email'));
      setLoading(false);
      return;
    }

    //REGISTRAZIONE
    if (variant === CARD_LOGIN_VARIANT_SIGN_UP) {
      setLoading(true);
      const exists = await userExist(email);
      if (exists) {
        setErrorMessage('email already exist');
        setLoading(false);
      }

      if (!exists) {
        console.log('Invio link per registrazione');
        store.dispatch(setUserDetail({ email: email }));
        await createFirebaseUser(email);
        setLoading(false);
        history.push({
          pathname: AppRoutes.sendEmail(),
          search: `?email=${email}`,
        });
      }
    }

    //ACCEDI
    if (variant === CARD_LOGIN_VARIANT_SIGN_IN) {
      console.log('CARD_LOGIN_VARIANT_SIGN_IN');

      if (errorMessage === USER_NOT_EXIST_ERROR) {
        console.log('REGISTER E POI VADO ALLA PAGINA DI VERIFY SIGNUP');
        dispatch(setUserDetail({ email }));
        await createFirebaseUser(email);
        setLoading(false);
        history.push({
          pathname: AppRoutes.sendEmail(),
          search: `?email=${email}`,
        });
      }

      // const exists = await checkFirebaseUser(email)

      const exists = await userExist(email);

      console.debug({ exists });
      if (exists) {
        setLoading(false);
        setStep(CARD_LOGIN_STEP_SIGN_IN);
      } else {
        setErrorMessage(USER_NOT_EXIST_ERROR);
        setLoading(false);
      }
      setLoading(false);
    }

    if (firebaseUserExists === false) {
      // const success = await createFirebaseUser(email)
      // if (success) {
      //   setModalOpen(false)
      //   navigate({
      //     pathname: ROUTE_SIGNUP_EMAIL,
      //     search: `?${createSearchParams({
      //       email
      //     })}`
      //   })
      // }
      // return
    }
  };
  const handleSubmitPassword = async ({ email, password }) => {
    console.log({ email, password });
    try {
      setLoading(true);
      const user = await signIn(email, password);

      const isEnchorer = await isCurrentUserEnchorer();
      if (isEnchorer) {
        history.replace(AppRoutes.campaignList());
        setLoading(false);
        return;
      }

      const { companyRole } = user;
      if (!_.isEmpty(companyRole)) {
        history.replace(AppRoutes.home());
      } else {
        history.push(AppRoutes.completeSignup());
      }
      console.log({ user });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log({ e });
      setErrorMessage(e.code);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    localStorage.setItem('email', email);
    // const success = await forgotPassword(email)
    // if (success) {
    dispatch(setAppLoginModal(false));
    dispatch(setAppForgotPasswordModal(true));
    // }
  };

  const handleChangePassword = (e) => {
    setErrorMessage(null);
    setPassword(e);
  };

  const handleClickSocialLogin = async (service) => {
    console.log({ service });
    try {
      service === 'google' && (await signInGoogle());
      service === 'facebook' && (await signInFacebook());

      const state = store.getState();
      const userDetail = userSelectors.detail(state);
      console.log({ socialLoginUserDetail: userDetail });

      const isEnchorer = await isCurrentUserEnchorer();
      if (isEnchorer) {
        history.replace(AppRoutes.campaignList());
        return;
      }

      if (!userDetail.companyRole) {
        history.push({
          pathname: AppRoutes.completeSignup(),
          state: { step: CARD_SIGNUP_STEP_WELCOME },
        });
      } else {
        history.push(AppRoutes.home());
      }
    } catch (e) {
      setErrorMessage('social login error');
    }
  };

  const onClickTipLink = () => {
    setEmail('');
    setErrorMessage(null);
    if (variant === CARD_LOGIN_VARIANT_SIGN_UP) {
      setVariant(CARD_LOGIN_VARIANT_SIGN_IN);
      setStep(CARD_LOGIN_STEP_CHECK_ACCOUNT);
    }
    if (variant === CARD_LOGIN_VARIANT_SIGN_IN) {
      setVariant(CARD_LOGIN_VARIANT_SIGN_UP);
      setStep(CARD_LOGIN_STEP_CHECK_ACCOUNT);
    }
  };

  return (
    <BaseModal open={showModal} width={400}>
      <CardLogin
        variant={variant}
        email={email}
        errorMessage={errorMessage}
        loading={loading}
        onClickLoginSocial={handleClickSocialLogin}
        onChangeEmail={handleChangeEmail}
        onChangePassword={handleChangePassword}
        onSubmitEmail={handleSubmitEmail}
        onClickTipLink={onClickTipLink}
        onSubmitPassword={handleSubmitPassword}
        onClearEmail={handleClearEmail}
        step={step}
        ForgotPasswordLink={<Link onClick={handleForgotPassword}>{t('click here')}</Link>}
      />
    </BaseModal>
  );
});
