import axios from "axios";
import {store} from "../app/store";
import {setUserDetail, userSelectors} from "../features/userSlice";
import _ from 'lodash'

export const getAccounts = async ({ mail }) => {
  return await axios.get(`/public/accounts`, {
    params: {
      mail
    }
  })
}

export const getMe = async () => {
  try {
    const { data } = await axios.get(`/private/me/business-users`, {params: {
      editStatus: "!deleted"
    }})
    console.log({myUserDetail: data})
    store.dispatch(setUserDetail(_.first(data.data)))
    return _.first(data.data)
  } catch (e) {
    console.log({e})
    return Promise.reject(e)
  }
}

export const deleteMe = async () => {
  const state = store.getState()
  const { _id } = userSelectors.detail(state)
  try {
    await axios.delete(`/private/business-users?ids=${_id}`)
  } catch (e) {
    console.log({errorDeleteMe: e})
  }
}

export const userExist = async (email) => {
  console.log('userExist')
  try {
    const res = await axios.get(`/public/accounts?mail=${email}`);
    console.log({res})
    return true;
  } catch (e) {
    console.log({e})
    return false
  }
};
