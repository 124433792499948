import React, { useMemo } from 'react';
import './HomePerformances.scss';
import { Col, Icon, InputDatePicker, InputSelect, Row } from '../../ui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { DateTime } from 'luxon';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Typography } from '@mui/material';
import { filterCounters, theme } from '../../../utils';
import { statusesOption } from '../../../pages/admin/campaign/CampaignFilters';
import { campaignStatus, campaignTypes } from '../../../utils/const';
import variables from '../../../app/spacing.module.scss';

export const campaignTypeOpt = {
  ...campaignTypes,
  allTypes: undefined,
};

const inputStyle = {
  color: 'black',
  marginBottom: '0.5rem',
};
const HomePerformanceFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const businessUnitCampaign = useSelector(businessUnitSelectors.campaigns, shallowEqual);
  const options = useMemo(() => {
    const campaignStatusesOptions = [
      ..._.map([campaignStatus.FINALIZED, campaignStatus.ACTIVE, campaignStatus.CLOSED], (o) => ({
        _id: o,
        label: t(o),
      })),
      { _id: 'allStatus', label: t('allStatus') },
    ];
    //solo campagne acquistate e approvte
    const campaignOptions = [
      ..._(businessUnitCampaign)
        .filter((cmp) => [campaignStatus.FINALIZED, campaignStatus.ACTIVE, campaignStatus.CLOSED].includes(cmp.campaignStatus))
        .map((cmp) => ({
          _id: cmp._id,
          label: cmp.name,
        }))
        .orderBy('label', ['asc']),
      { _id: 'allCampaign', label: t('allCampaign') },
    ];
    const campaignTypeOptions = _.map(_.keys(campaignTypeOpt), (o) => ({
      _id: o,
      label: t(`${o}.title`),
    }));
    return {
      status: campaignStatusesOptions,
      campaigns: campaignOptions,
      types: campaignTypeOptions,
    };
  }, [businessUnitCampaign, campaignTypeOpt, statusesOption]);

  const onChangeValue = (value, filterName) => {
    const newFilters = {
      ...filters,
      [filterName]: value,
    };
    setFilters(newFilters);
  };

  const onChangeDate = (newDate, filterName) => {
    const newFilters = {
      ...filters,
      [filterName]: newDate ? newDate.toMillis() : undefined,
    };
    setFilters(newFilters);
  };

  const filterCount = useMemo(() => {
    return _.size(filters);
  }, [filters]);

  const dates = useMemo(() => {
    let from = null;
    let to = null;
    if (!!_.get(filters, 'from')) {
      from = DateTime.fromMillis(_.get(filters, 'from')).toJSDate();
    }
    if (!!_.get(filters, 'to')) {
      to = DateTime.fromMillis(_.get(filters, 'to')).toJSDate();
    }
    console.debug({ from, to });
    return { from, to };
  }, [filters]);

  return (
    <Col flex>
      <Accordion TransitionProps={{ unmountOnExit: true }} md={12} style={{ borderRadius: '1rem' }} className={'accordion'}>
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <Row fullWidth alignCenter spaceBetween style={{ paddingTop: '0.4rem', paddingBottom: '0.4rem' }}>
            <span className={'accordion-label'}> {t('performances')} </span>
            <Badge
              color="secondary"
              badgeContent={filterCount}
              className={'pointer'}
              showZero
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Icon icon={'far fa-filter'} color={theme['primaryColor']} sx={{ padding: '0.1rem' }} style={{ width: '1.5rem', height: '1.5rem' }} />
            </Badge>
          </Row>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Row grid container md={12} spacing={1}>
            <Col item md={3} sm={6} xs={12}>
              <InputDatePicker label={t('from')} value={dates.from} handleChange={(newDate) => onChangeDate(newDate, 'from')} />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputDatePicker label={t('to')} value={dates.to} handleChange={(newDate) => onChangeDate(newDate, 'to')} />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputSelect
                variant={'outlined'}
                label={t('campaign')}
                emitValue
                value={_.get(filters, 'cmpId')}
                onChange={(value) => onChangeValue(value, 'cmpId')}
                options={options.campaigns}
                style={inputStyle}
              />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputSelect
                variant={'outlined'}
                label={t('campaignStatus')}
                emitValue
                value={_.get(filters, 'campaignStatus')}
                onChange={(value) => onChangeValue(value, 'campaignStatus')}
                options={options.status}
                style={inputStyle}
              />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputSelect
                variant={'outlined'}
                label={t('campaign type')}
                emitValue
                value={_.get(filters, 'typeof')}
                onChange={(value) => onChangeValue(value, 'typeof')}
                options={options.types}
                style={inputStyle}
              />
            </Col>
          </Row>
        </AccordionDetails>
      </Accordion>
    </Col>
  );
};

export default HomePerformanceFilters;
