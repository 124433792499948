import axios from "axios";
import {setAppConfig} from '../features/appSlice';
import {store} from '../app/store';

export const getAppConfig = async () => {

  try {
    const { data } = await axios.get(`/public/enchora/configurations/config`);
    console.log({data})
    store.dispatch(setAppConfig(data.data[0]))
  } catch (e) {
    console.log({e});
    return null;
  }
};

