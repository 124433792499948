import React, {useEffect, useState} from 'react';
import './video.scss'
import {getUrlImageFromPath} from '../../../api/upload';
import ReactPlayer from 'react-player';

export const Video = ({path, uri, className, style, ...rest}) => {

  const [localUri, setLocalUri] = useState(uri)

  useEffect(() => {
    if (uri) {
      setLocalUri(uri)
    }
  }, [uri])

  useEffect(() => {
    if (path) {
      getUrlImageFromPath(path).then(newUri => {
        if (newUri) {
          setLocalUri(newUri)
        }
      })
    }
  }, [path])

  console.log({localUri})
  return (
    <ReactPlayer url={localUri} loop={true} playing={true} muted={true} className={className} style={style} {...rest}/>
  )
}
