import fonts from './../app/theme.module.scss'

const typography = {
  fontFamily: fonts['family-primary'],
  h1: {
    fontWeight: 800,
    fontSize: '36px'
  },
  h2: {
    fontWeight: 800,
    fontSize: '32px',
    lineHeight: '1.2'
  },
  h3: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '1.2'
  },
  h4: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '1.2'
  },
  h5: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '1.2'
  },
  h6: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '1.2'
  },
  lead: {
    fontSize: '20px',
    color: '#6D6D6D',
    lineHeight: '1.2'
  },
  body2: {
    fontSize: '16px',
    color: '#6D6D6D',
    lineHeight: '1.2'
  },
  label1: {
    fontSize: '14px',
    lineHeight: '1.2',
    marginBottom: '5px'
  },
  label: {
    fontSize: '12px',
    color: '#6D6D6D',
    lineHeight: '1.2',
    marginBottom: '5px'
  },
  subtitle: {
    fontSize: '18px',
    marginBottom: '5px',
    color: '#8E24AA',
    fontWeight: 700,
  },
  bold: {
    fontSize: '16px',
    fontWeight: 700,
  },
  underline: {
    textDecorationLine: 'underline',
    color: '#8E24AA',
    cursor: 'pointer'
  },
  error: {
    color: 'red',
    fontSize: '14px',
    fontWeight: 500,
  }
}

export default typography
