import React, { memo } from 'react'
import "./col.scss"
import clsx from "clsx";
import {Grid} from '@mui/material';
export const Col = memo(({
  children,
  className,
  spaceBetween,
  spaceAround,
  alignCenter,
  justifyCenter,
  flexEnd,
  flexStart,
  flex,
  width,
  fullWidth,
  noFlex,
  style,
  ...props
}) => {

  let alignItems = undefined
  let justifyContent = undefined
  if (alignCenter) {
    alignItems = 'center'
  }
  if (flexEnd) {
    alignItems = 'flex-end'
  }
  if (flexStart) {
    alignItems = 'flex-start'
  }
  if (spaceBetween) {
    justifyContent = 'space-between'
  }
  if (spaceAround) {
    justifyContent = 'space-around'
  }
  if (justifyCenter) {
    justifyContent = 'center'
  }


  const rowStyle = {
    justifyContent,
    alignItems,
    flex: flex ? 1 : noFlex ? 0 : undefined,
    display: 'flex',
    width: fullWidth ? '100%' : width ? width : undefined,
    flexDirection: 'column',
    ...style
  }

  return (
    <Grid {...props} className={clsx(className)} style={rowStyle}>
      {children}
    </Grid>
  )
})

