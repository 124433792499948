import spacing from './../../../src/app/spacing.module.scss'

const MuiCard = (theme) => ({
  styleOverrides: {
    root: {
      borderRadius: spacing['md'],
      padding: spacing['md']
    }
  }
})

export default MuiCard
