import {Box, Link} from '@mui/material'
import {useTranslation} from "react-i18next";
import './SignupVerify.scss'
import React from "react";
import {ImageBg1} from "../../../assets";
import {Row} from "../../../components/ui";


const SignupVerifyPage = () => {
  const { t } = useTranslation()
  const [searchParams] = ''

  const handleClickRetry = async () => {
    const email = searchParams.get('email')
    if (email) {
      console.log({email})
      //await createFirebaseUser(email)
    }
    window.location.reload()
  }

  const handleContactUs = () => {
    //window.open('https://enchora.com/nome-e-logo')
  }


  return (
    <div id={'signup-verify'}>
      <Box
        p={2}
        className={'container-signup-verify'}
      >
        <h1>{t('signup verify title')}</h1>

        <p>
          {t('signup verify text 1')}
        </p>

        <p>
          {t('signup verify text 2')}
        </p>

        <p>
          {t('signup verify text 3')}{'  '}
          <Link href='#' onClick={handleClickRetry}>
            {t('click here')}
          </Link>
          {' '}{t('signup verify text 4')}
        </p>

        <p>
          {t('signup verify text 5')}{'  '}
          <Link href='#' onClick={handleContactUs}>
            {t('contact us').toLowerCase()}
          </Link>
          {' '}{t('signup verify text 6')}
        </p>

        <Row justifyCenter>
          <img src={ImageBg1} alt={''} className={'image'}/>
        </Row>

      </Box>
    </div>
  )
}

export default SignupVerifyPage
