import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { defaultStyleSpacing } from '../../theme';
import { ImageBg1 } from '../../assets';
import './ContactFormSuccess.scss';
import { Row, Container } from '../../components/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../app/routers';

const ContactFormSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const goHome = () => {
    history.replace(AppRoutes.home());
  };

  return (
    <Container id={'contact-form-success'}>
      <Box p={2}>
        <h1 style={{ marginLeft: defaultStyleSpacing * 3 }}>{t('contactSuccessTitle')}</h1>

        <p style={{ marginLeft: defaultStyleSpacing * 3 }}>{t('contactSuccessSubtitle')}</p>
        {/*<p className={'link-success'} onClick={goHome}>*/}
        {/*  {t('goHome')}*/}
        {/*</p>*/}
        <Row justifyCenter>
          <img src={ImageBg1} alt={''} className={''} />
        </Row>
      </Box>
    </Container>
  );
};

export default ContactFormSuccess;
