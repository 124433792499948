import axios from 'axios';
import _ from 'lodash';
import { setUserDetail, userSelectors } from '../features/userSlice';
import { store } from '../app/store';
import { toast } from 'react-toastify';
import { translate } from '../i18n';
import { TOAST } from '../utils/const';
import { logout } from './auth';

export const postBusinessUser = async () => {
  try {
    const { data } = await axios.post(`/private/business-users`);
    const user = _.first(data.data);
    store.dispatch(setUserDetail(user));
    return user;
  } catch (e) {
    console.debug({ e });
  }
};

export const patchBusinessUser = async (data) => {
  console.log({ data });
  const state = store.getState();
  const businessUserDetail = userSelectors.detail(state);
  const id = businessUserDetail._id;
  let res;
  if (id) {
    console.log({ patchUserData: data });
    res = await axios.patch(`/private/business-users/${id}`, data);
  } else {
    console.debug('patchBusinessUser NOT ID');
    const createdUser = await postBusinessUser();
    console.debug({ createdUser });
    res = await axios.patch(`/private/business-users/${createdUser._id}`, data);
  }
  console.debug({ res });
  const businessUser = _.first(res.data.data);
  store.dispatch(setUserDetail({ ...businessUserDetail, ...businessUser }));
  return businessUser;
};

export const getBusinessUnitsCurrentUser = async () => {
  try {
    const { data } = await axios.get('private/business-units');
    return _.cloneDeep(data.data);
  } catch (e) {
    console.log({ e });
  }
};

export const updateCurrentBusinessUser = async (bunData) => {
  const state = store.getState();
  const businessUserDetail = userSelectors.detail(state);
  try {
    const {
      data: { data },
    } = await axios.patch(`/private/business-users/${businessUserDetail._id}`, bunData);
    const updatedBun = _.first(data);
    console.debug({ updatedBun });
    store.dispatch(setUserDetail({ ...updatedBun }));
    toast(translate('profile updated successfully'), TOAST.SUCCESS);
  } catch (e) {
    toast(translate('profile updated error'), TOAST.ERROR);
  }
};

export const deleteCurrentBusinessUser = async () => {
  const state = store.getState();
  const businessUserDetail = userSelectors.detail(state);

  try {
    const { data: response } = await axios.delete(`private/business-users?ids=${businessUserDetail._id}`);
    console.debug({ response });
    await logout();
  } catch (e) {
    toast(translate('profile deleted error'), TOAST.ERROR);
  }
};

export const getReportAbuseReason = async (resourceType) => {
  try {
    const {
      data: { data },
    } = await axios.get('public/report-abuse-reasons');
    console.log({ getReportAbuseReason: data });
    return _.filter(data, (abuse) => abuse.resource === resourceType);
  } catch (e) {
    console.log({ e });
    return [];
  }
};

export const reportAbuse = async (abuseData, resourceType, resourceId) => {
  const state = store.getState();
  const userDetail = userSelectors.detail(state);
  const email = _.get(userDetail, 'account.email');

  console.log({ abuseData });

  try {
    await axios.post(`private/report-abuses/${resourceType}/${resourceId}`, {
      ...abuseData,
      reporter: email,
    });
    toast(translate('thanks for report'), TOAST.SUCCESS);
  } catch (e) {
    toast(translate('reportError'), TOAST.ERROR);
  }
};
