import React, { useState } from 'react';
import { Col } from '../col/col';
import { IconButton } from '@mui/material';
import { Icon } from '../icon/icon';
import './VideoContainer.scss';
import { Video } from '../image/video';

export const VideoContainer = ({ asset, onClickDelete }) => {
  const [showDelete, setShowDelete] = useState(false);

  const onMouseEnter = () => {
    setShowDelete(true);
  };

  const onMouseLeave = () => {
    setShowDelete(false);
  };

  return (
    // <div id={'video-container'}>
    <Col sm={4} md={3} key={asset?.uri} style={{ position: 'relative' }} className={'video-wrapper'} pr={'0.5rem'} pt={'0.5rem'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Col style={{ position: 'relative' }} className={'video-container'}>
        <Video height={'10rem'} width={'10rem'} path={asset.uri} uri={asset.uri} loop={true} playing={true} muted={true} className={'video-content'} />
      </Col>
      {showDelete && !!onClickDelete && (
        <Col className={'icon-container'} alignCenter justifyCenter>
          <IconButton className={'icon-delete-video'} onClick={() => onClickDelete(asset)}>
            <Icon icon={'far fa-times'} color={'black'} />
          </IconButton>
        </Col>
      )}
    </Col>
    // </div>
  );
};
