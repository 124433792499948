import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { dateOptionsAndValues } from '../utils';

const initialState = {
  homeGanttCampaigns: [],
  homeHistogramMetrics: [],
  homeCounters: [],
  influencerFilters: {},
  campaignStatisticFilter: {
    date: 'allDates',
  },
};

export const metricSlice = createSlice({
  name: 'metric',
  initialState,
  reducers: {
    setHomeGanttCampaigns: (state, action) => {
      state.homeGanttCampaigns = action.payload;
    },
    setHomeCounters: (state, actions) => {
      state.homeCounters = actions.payload;
    },
    setHomeHistogramCampaigns: (state, action) => {
      state.homeHistogramMetrics = action.payload;
    },
    setCampaignStatisticFilter: (state, action) => {
      state.campaignStatisticFilter = {
        ...state.campaignStatisticFilter,
        ...action.payload,
      };
    },
    setInfluencerFilters: (state, action) => {
      state.influencerFilters = {
        ...state.influencerFilters,
        ...action.payload,
      };
    },
  },
});

export const { setHomeGanttCampaigns, setHomeHistogramCampaigns, setCampaignStatisticFilter, setHomeCounters, setInfluencerFilters } = metricSlice.actions;

export default metricSlice.reducer;

export const metricSelectors = {
  homeGanttCampaigns: (state) => _.get(state, 'metric.homeGanttCampaigns') || [],
  homeHistogramMetrics: (state) => _.get(state, 'metric.homeHistogramMetrics') || [],
  campaignStatisticFilter: (state) => _.get(state, 'metric.campaignStatisticFilter') || {},
  homeCounters: (state) => _.get(state, 'metric.homeCounters') || {},
  influencerFilters: (state) => _.get(state, 'metric.influencerFilters') || {},
};
