import React, { useEffect, useMemo } from 'react';
import './CampaignStatisticInfluencerFilters.scss';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../features/appSlice';
import { CATALOGUES_TYPES } from '../../utils/const';
import { Col, Icon, InputDatePicker, InputSelect, InputText, Row } from '../../components/ui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { fromMillisToDate } from '../../utils';
import { getCatalogueAndSaveState } from '../../api';
import InputAdornment from '@mui/material/InputAdornment';

const inputStyle = {
  color: 'black',
  width: '18rem',
  marginInline: '1rem',
  marginBottom: '0.5rem',
};

const CampaignStatisticInfluencerFilters = ({ campaign, filters, onChangeFilters }) => {
  const { t } = useTranslation();
  const infLevels = useSelector(appSelectors.catalogueByType(CATALOGUES_TYPES.LEVELS)) || [];
  const optionsLevels = [...infLevels, { _id: 'all', label: t('allLevels') }];

  useEffect(() => {
    getCatalogueAndSaveState(CATALOGUES_TYPES.LEVELS);
  }, []);

  const dates = useMemo(() => {
    const min = fromMillisToDate(_.get(campaign, 'duration.begin.ts'));
    const max = fromMillisToDate(_.get(campaign, 'duration.end.ts'));
    return { min, max };
  }, [campaign]);

  const onChangeInfLevel = (infLevel) => {
    onChangeFilters({ infLevel });
  };

  const onChangeDate = (newDate, dateField) => {
    onChangeFilters({ [dateField]: newDate ? newDate.toMillis() : undefined });
  };

  const onChangeFromDate = (newDate) => {
    onChangeDate(newDate, 'from');
  };

  const onChangeToDate = (newDate) => {
    onChangeDate(newDate, 'to');
  };

  const onChangeText = (e) => {
    const nickname = e.target.value;
    onChangeFilters({ nickname });
  };

  return (
    <div id={'cmp-stat-inf-filters'}>
      <Row fullWidth grid container>
        <Col md={3} sm={4} xs={12}>
          <InputSelect variant={'outlined'} label={t('infLevel')} emitValue value={_.get(filters, 'infLevel')} onChange={onChangeInfLevel} options={optionsLevels} style={inputStyle} />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <InputDatePicker label={t('from')} value={fromMillisToDate(_.get(filters, 'from'))} handleChange={onChangeFromDate} minDate={dates.min} maxDate={dates.max} />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <InputDatePicker label={t('to')} value={fromMillisToDate(_.get(filters, 'to'))} handleChange={onChangeToDate} minDate={dates.min} maxDate={dates.max} />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <InputText
            variant={'outlined'}
            fullWidth
            value={_.get(filters, 'nickname', '')}
            onChange={onChangeText}
            label={t('search')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon={'far fa-search'} color={'black'} />
                </InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CampaignStatisticInfluencerFilters;
