import axios from 'axios';
import _ from 'lodash';
import { store } from '../app/store';
import { campaignSelectors, setCampaignList, setCampaignSelected } from '../features/campaignSlice';
import { influencerSelectors } from '../features/influencerSlice';
import { setUserRole } from '../features/userSlice';
import { defaultListObj, TOAST, USER_ROLES } from '../utils/const';
import { masterAxios } from './axios';
import { businessUnitSelectors } from '../features/businessUnitSlice';
import { toast } from 'react-toastify';
import { translate } from '../i18n';
import t from './../i18n';
import { normalizeFilters } from '../utils';
const limitDefault = 10;

export const normalizeCampaignFiltersFromState = (campaignFilters) => {
  console.log({ campaignFilters });
  let sort = _.get(campaignFilters, 'sort') || undefined;
  const fts = _.get(campaignFilters, 'fts.text') ? _.get(campaignFilters, 'fts') : undefined;

  if (_.get(sort, 'fields[0]') === 'company') {
    sort.fields = ['businessUnit.name']
  }
  if (_.get(sort, 'fields[0]') === 'creation date') {
    sort.fields = ['created.ts']
  }


  let query = {
    typeof: _.get(campaignFilters, 'typeof') === 'allTypes' ? undefined : _.get(campaignFilters, 'typeof'),
    name: _.get(campaignFilters, 'name') ? _.get(campaignFilters, 'campaignStatus') : undefined,
    'businessUnit._id': _.get(campaignFilters, 'businessUnitId') === 'all' ? undefined : _.get(campaignFilters, 'businessUnitId', undefined),
  };

  //const cap = _.get(campaignFilters, 'area');
  //TODO: manca da mappare budget, aspetto qualche campagna con dati reali
  let native = _.omitBy(
    {
      'duration.begin.ts': _.omitBy(
        {
          $gte: _.get(campaignFilters, 'fromDate'),
          $lte: _.get(campaignFilters, 'toDate'),
        },
        _.isUndefined
      ),
      'budget.budget.value':
        !campaignFilters?.minBudget && !campaignFilters?.maxBudget
          ? undefined
          : {
              $gte: campaignFilters?.minBudget ? Number(_.get(campaignFilters, 'minBudget')) : undefined,
              $lte: campaignFilters?.maxBudget ? Number(_.get(campaignFilters, 'maxBudget')) : undefined,
            },
      campaignStatus:
        !!campaignFilters.campaignStatus && !!campaignFilters.campaignStatus.length
          ? {
              $in: _.get(campaignFilters, 'campaignStatus', undefined),
            }
          : undefined,
    },
    _.isEmpty
  );

  if (campaignFilters.date === 'allDate') {
    native = undefined;
  }

  console.log({ sort, fts, query, native });

  return { sort, fts, query, native: _.omitBy({ ...native }, _.isEmpty) };
};

export const getEnchorerBusinessUnits = async (t) => {
  try {
    const { data } = await axios.get('private/enchorers/business-units', {
      params: {
        limit: -1,
      },
    });
    const result = _.map(data.data, ({ totalCampaigns, businessUnit }) => ({
      _id: businessUnit._id,
      label: businessUnit.name,
      totalCampaigns,
    }));

    result?.push({
      _id: 'all',
      label: t('all business unit'),
    });

    return _.cloneDeepWith(result);
  } catch (e) {
    console.log({ e });
  }
};

export const getEnchorerCampaignList = async (page, campaignFilters) => {
  console.debug({ campaignFilters });
  const filters = normalizeCampaignFiltersFromState(campaignFilters);
  const queryParams = _.omitBy({ ...filters }, _.isEmpty);

  try {
    const { data } = await axios.get('private/enchorers/campaigns', {
      params: {
        params: {
          ...queryParams,
        },
        skip: page > 0 ? page * limitDefault : page,
        limit: limitDefault,
      },
    });
    // console.log({ data });
    // store.dispatch(setCampaignList(data));
    return data;
  } catch (e) {
    return defaultListObj;
  }
};

export const getEnchorerInfluencerList = async (page, filters) => {
  console.debug({ filters });
  const params = {
    params: {
      skip: page * limitDefault,
      limit: limitDefault,
      params: normalizeFilters({
        fts: _.get(filters, 'fts.text') ? filters.fts : undefined,
        sort: _.get(filters, 'sort.fields', []).length > 0 ? filters.sort : undefined,
      }),
    },
  };

  try {
    const { data } = await axios.get('private/enchorers/influencers', params);
    console.log({ data });
    return data;
  } catch (e) {
    console.log({ e });
    return defaultListObj;
  }
};

export const getEnchorerBunList = async (page, filters) => {
  console.debug({ filters });
  const params = {
    params: {
      skip: page * limitDefault,
      limit: limitDefault,
      params: normalizeFilters({
        // fts: _.get(filters, 'fts.text') ? filters.fts : undefined,
        sort: _.get(filters, 'sort.fields', []).length > 0 ? filters.sort : undefined,
        'businessUnit.businessUnitStatus': _.get(filters, 'businessUnitStatus') === 'allStatus' ? undefined : filters.businessUnitStatus,
        regex: {
          options: 'i',
          'businessUnit.name': `${_.get(filters, 'fts.text') ? filters.fts.text : ''}`,
        },
      }),
    },
  };

  try {
    const { data } = await axios.get('private/enchorers/business-units', params);
    console.log({ data });
    return data;
  } catch (e) {
    console.log({ e });
    return defaultListObj;
  }
};

export const getEnchorerInfluencerDetail = async (infId) => {
  const params = {
    params: {
      params: {
        ids: [infId],
      },
    },
  };
  try {
    const { data } = await axios.get('private/enchorers/influencers', params);
    console.debug({ data });
    return data.data[0];
  } catch (e) {
    console.debug({ e });
    return {};
  }
};

export const getEnchorerBunDetail = async (bunId) => {
  const params = {
    params: {
      params: {
        ids: [bunId],
      },
    },
  };
  try {
    const { data } = await axios.get('private/enchorers/business-units', params);
    console.debug({ data });
    return data.data[0];
  } catch (e) {
    console.debug({ e });
    return {};
  }
};

export const isCurrentUserEnchorer = async () => {
  try {
    const { status } = await masterAxios.get('private/enchorers/campaigns', {
      params: {
        params: JSON.stringify({}),
        limit: 1,
      },
    });
    console.log({ status });
    if (status === 200) {
      store.dispatch(setUserRole(USER_ROLES.ENCHORER));
    }
    return true;
  } catch (e) {
    store.dispatch(setUserRole(USER_ROLES.BUSINESS_USER));
    return false;
  }
};

export const getEnchorerCampaignDetail = async (campaignId) => {
  try {
    console.log({ getEnchorerCampaignDetail: campaignId });
    const { data } = await masterAxios.get(`private/enchorers/campaigns`, {
      params: {
        params: JSON.stringify({ _id: campaignId }),
        limit: 1,
      },
    });
    console.log({ data });
  } catch (e) {
    console.log({ campaignDetailError: e });
  }
};

export const enchorerApproveCampaign = async (campaignId) => {
  try {
    const params = {
      state: 'approve',
      typeof: 'CampaignTriggerState',
    };
    const { data } = await axios.put(`private/enchorers/campaigns/${campaignId}/states`, params);
    console.log({ putStatusCampaign: data });
    store.dispatch(setCampaignSelected(data.data[0]));
    toast(translate('campaign approve success'), TOAST.SUCCESS);
    return data.data[0];
  } catch (e) {
    toast(translate('campaign approve error'), TOAST.ERROR);
    console.log({ putStatusCampaignError: e });
  }
};

export const enchorerDismissCampaign = async (campaignId, note) => {
  try {
    const params = {
      state: 'reject',
      rejectionNote: note,
      typeof: 'CampaignTriggerState',
    };
    const { data } = await axios.put(`private/enchorers/campaigns/${campaignId}/states`, params);
    console.log({ putStatusCampaign: data });
    store.dispatch(setCampaignSelected(data.data[0]));
    toast(translate('campaign reject success'), TOAST.SUCCESS);
    return data.data[0];
  } catch (e) {
    toast(translate('campaign reject error'), TOAST.ERROR);
    console.log({ putStatusCampaignError: e });
  }
};
export const enchorerDeclineCampaign = async (campaignId) => {
  try {
    // update, purchase, approve, decline, reject, submit, extend, start, close, stop, finalize
    const params = {
      state: 'decline',
      typeof: 'CampaignTriggerState',
    };
    const { data } = await axios.put(`private/enchorers/campaigns/${campaignId}/states`, params);
    console.log({ putStatusCampaign: data });
    store.dispatch(setCampaignSelected(data.data[0]));
    toast(translate('campaign not approve success'), TOAST.SUCCESS);
    return data.data[0];
  } catch (e) {
    toast(translate('campaign not approve error'), TOAST.ERROR);
    console.log({ putStatusCampaignError: e });
  }
};

export const getEnchorerCashout = async (page) => {
  const params = {
    params: {
      skip: page * limitDefault,
      limit: limitDefault,
    },
  };

  try {
    const { data } = await axios.get('private/enchorers/cashout', params);
    console.log({ data });
    return data;
  } catch (e) {
    return defaultListObj;
  }
};

export const updateCashoutStatus = async (cashoutId, newStatus, rejectionNote) => {
  try {
    const updated = await axios.put(`private/enchorers/cashout/${cashoutId}/states`, {
      state: newStatus,
      rejectionNote: rejectionNote,
    });
    console.log({ updated });
    toast(translate('cashout update successfully'), TOAST.SUCCESS);
  } catch (e) {
    toast(translate('cashout update error'), TOAST.ERROR);
    console.debug({ errorUpdateCashout: e });
  }
};

export const downloadCsv = async (type = 'Influencer') => {
  try {
    // BusinessUnit, Influencer, Campaign``
    const params = {
      resource: type,
    };
    const { data: csv } = await axios.get(`private/enchorers/exports`, { params });
    console.log({ csv });

    const url = window.URL.createObjectURL(new Blob([csv]));
    console.log({ url });
    const link = document.createElement('a');
    console.log({ link });
    link.href = url;
    link.setAttribute('download', `${type}.csv`);
    document.body.appendChild(link);
    link.click();
    // toast(translate('cashout update successfully'), TOAST.SUCCESS);
  } catch (e) {
    // toast(translate('cashout update error'), TOAST.ERROR);
    console.debug({ downloadCsvError: e });
  }
};

export const deleteInfluencerUser = async (infId) => {
  try {
    const { data: deletedUser } = await axios.get(`private/enchorers/bun-influencers?ids=${infId}`);
    console.log({ deletedUser });
    toast(translate('user deleted successfully'), TOAST.SUCCESS);
  } catch (e) {
    toast(translate('user deleted error'), TOAST.ERROR);
    console.debug({ errorUpdateCashout: e });
  }
};
