import React, { createRef, useEffect, useMemo, useState } from 'react';
import './ContactFormPage.scss';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../utils/hook';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container, Typography } from '@mui/material';
import { Button, CheckboxRow, Col, InputTelephone, InputText, Row } from '../../components/ui';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import _ from 'lodash';
import { contactFormSchema } from '../../utils/validations';
import { CONTACT_TYPES, TOAST } from '../../utils/const';
import { getPublicPageCampaignInfo } from '../../api';
import { fillContactForm, sendEmailVerification, sendPhoneVerification } from '../../api/contact';
import { toast } from 'react-toastify';
import { translate } from '../../i18n';
import { getParameterFromUrl, openEnchoraCookies, openEnchoraPrivacy } from '../../utils';
import { ContactFormModal } from '../../components/layout/modals';

export const SEND_CODE_TYPES = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};
const TEST_SITE_KEY = '6Lc54FgfAAAAAKlk0VNNMEYY5PQNRzkdLCYi5zH8';

const ContactFormPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [campaign, setCampaign] = useState({});
  const [sendCodeType, setSendCodeType] = useState([]);
  const [chaptaVerified, setChaptaVerified] = useState(false);
  const [modal, { open: openModal, close: closeModal }] = useModal();
  const [loading, setLoading] = useState(false);
  const chaptaRef = createRef();

  const { infId, cmpId } = useMemo(() => {
    return getParameterFromUrl(history.location.search);
  }, [history.location]);
  const showEmail = useMemo(() => {
    return [CONTACT_TYPES.EMAIL, CONTACT_TYPES.EMAIL_PHONE].includes(_.get(campaign, 'contactType', ''));
  }, [campaign]);
  const showPhone = useMemo(() => {
    return [CONTACT_TYPES.PHONE, CONTACT_TYPES.EMAIL_PHONE].includes(_.get(campaign, 'contactType', ''));
  }, [campaign]);

  useEffect(() => {
    const getData = async () => {
      try {
        // console.debug({ cmpId });
        const campaignRes = await getPublicPageCampaignInfo(cmpId);
        // console.debug({ campaignRes });
        _.get(campaignRes, 'contactType').includes('phone') && setSendCodeType((prev) => [...prev, SEND_CODE_TYPES.SMS]);
        _.get(campaignRes, 'contactType').includes('email') && setSendCodeType((prev) => [...prev, SEND_CODE_TYPES.EMAIL]);
        // console.log({ campaignRes });
        setCampaign(campaignRes);
      } catch (e) {
        // console.log({ e });
      }
    };
    getData();
  }, [cmpId]);

  const handleChangeChapta = (value) => {
    console.log({handleChangeChapta: value})
    setChaptaVerified(true);
  };

  const handleExpireChapta = (value) => {
    console.log({handleExpireChapta: value})
    setChaptaVerified(false);
  };

  const onCancel = () => {};

  const inputStyle = {
    marginTop: '2rem',
    marginBottom: '1rem',
  };

  const { handleSubmit, setFieldValue, errors, validateField, setFieldTouched, isValid, values, dirty, touched } = useFormik({
    initialValues: {
      email: '',
      number: '',
      prefix: '39',
      privacyAccepted: false,
      cookiesAccepted: false,
      authorizationGiven: false,
    },
    validationSchema: contactFormSchema[_.get(campaign, 'contactType', CONTACT_TYPES.EMAIL)],
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (data) => {
      setLoading(true);
      // console.log({ setSendCodeType });
      if (_.isEmpty(sendCodeType)) {
        toast(translate('contactForm.selectCodeTypeError'), TOAST.ERROR);
        return;
      }
      sendCodeType.includes(SEND_CODE_TYPES.EMAIL) && (await sendEmailVerification(data, cmpId));
      sendCodeType.includes(SEND_CODE_TYPES.SMS) && (await sendPhoneVerification(data, cmpId));
      openModal();
      setLoading(false);
    },
  });

  const submitDisable = !values.privacyAccepted || !values.cookiesAccepted || !values.authorizationGiven || (!isValid && dirty) || !chaptaVerified;
  // const submitDisable = !values.privacyAccepted || !values.cookiesAccepted || !values.authorizationGiven || (!isValid && dirty);
  console.log({chaptaVerified, submitDisable})

  const onChangeValue = async (fieldName, fieldValue) => {
    await setFieldValue(fieldName, fieldValue);
    await validateField(fieldName);
    await setFieldTouched(fieldName, true);
  };

  const onSubmitForm = () => {
    handleSubmit();
  };

  const onFillContactForm = async () => {
    return await fillContactForm(values, campaign.contactType, cmpId, infId);
  };

  const showError = (field) => {
    return !!errors[field] && !!touched[field];
  };

  return (
    <Container id={'contact-form-page'}>
      <ContactFormModal open={modal} data={values} onClose={closeModal} sendCodeType={sendCodeType} onFillContactForm={onFillContactForm} />
      <Col>
        <Typography variant="h1">{t('contactForm.title')}</Typography>
        <Typography variant="body">{t('contactForm.subtitle')}</Typography>
        <Col>
          {showEmail && (
            <Col>
              <InputText
                style={{ ...inputStyle, marginTop: '1rem' }}
                label={t('email')}
                fullWidth
                error={showError('email')}
                touched={touched.email}
                helperText={t(errors.email)}
                onChange={(v) => onChangeValue('email', v.target.value)}
                value={values.email}
                variant={'standard'}
                autocomplete="new-password"
              />
              {showEmail && !showPhone && <Typography variant="body">{t('contactForm.tip')}</Typography>}
            </Col>
          )}
          {showPhone && (
            <Col>
              <InputTelephone
                containerStyle={{ ...inputStyle, width: '100%' }}
                numberValue={values.number}
                prefixValue={values.prefix}
                touched={touched.email}
                errors={[showError('prefix') ? errors.prefix : '', showError('number') ? errors.number : '']}
                prefixes={[
                  {
                    value: '39',
                    label: '+39',
                  },
                ]}
                onChange={(e) => {
                  onChangeValue('number', e[1]);
                  onChangeValue('prefix', e[0]);
                }}
                label={t('telephone')}
                name="telephone"
              />
              {showPhone && !showEmail && <Typography variant="body">{t('contactForm.tip')}</Typography>}
            </Col>
          )}
          <SectionPrivacyAndConditions setFieldValue={setFieldValue} values={values} businessUnit={_.get(campaign, 'businessUnit', {})} />
          <ReCAPTCHA style={{ display: 'inline-block' }} ref={chaptaRef} sitekey={TEST_SITE_KEY} onChange={handleChangeChapta} onExpired={handleExpireChapta} />
          <Row spaceAround mt="16px" md={12} sm={12} xs={12}>
            <Col md={5} sm={5} xs={5}>
              <Button fullWidth onClick={onCancel} variant={'outlined'} text={t('exit')} size={'small'} />
            </Col>
            <Col md={5} sm={5} xs={5}>
              <Button fullWidth onClick={onSubmitForm} text={t('continue')} size={'small'} disabled={submitDisable} loading={loading} />
            </Col>
          </Row>
        </Col>
      </Col>
    </Container>
  );
};

export default ContactFormPage;

export const SectionPrivacyAndConditions = ({ setFieldValue, values, businessUnit }) => {
  const { t } = useTranslation();

  const onChangeEnchoraPrivacy = () => {
    setFieldValue('privacyAccepted', !values.privacyAccepted);
    setFieldValue('cookiesAccepted', !values.cookiesAccepted);
  };

  const enchoraConditionsChecked = useMemo(() => {
    return values.privacyAccepted && values.cookiesAccepted;
  }, [values.privacyAccepted, values.cookiesAccepted]);

  const onChangeAuthorizationGiven = () => {
    setFieldValue('authorizationGiven', !values.authorizationGiven);
  };

  return (
    <Col style={{ marginTop: '2rem' }}>
      <Typography variant="h2">{t('product.privacyTitle')}</Typography>
      <Typography variant="body">{t('product.privacySubtitle')}</Typography>
      <CheckboxRow checked={enchoraConditionsChecked} onChangeChecked={onChangeEnchoraPrivacy}>
        <span className={'privacyText'}>&nbsp;{t('product.accept1')}&nbsp;</span>
        <span className={'privacyLink'} onClick={openEnchoraPrivacy}>
          {t('product.enchoraPrivacy')}
        </span>
        <span className={'privacyText'}>&nbsp;{t('product.accept2')}&nbsp;</span>
        <span className={'privacyLink'} onClick={openEnchoraCookies}>
          {t('product.enchoraCookies')}
        </span>
        <span className={'privacyText'}>&nbsp;{t('product.accept3')}&nbsp;</span>
      </CheckboxRow>
      <CheckboxRow checked={values.authorizationGiven} onChangeChecked={onChangeAuthorizationGiven}>
        <span className={'privacyText'}>&nbsp;{t('contactForm.accept1')}&nbsp;</span>
        <span>{_.get(businessUnit, 'name', ' - ')}</span>
      </CheckboxRow>
    </Col>
  );
};
