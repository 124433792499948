import React, { useEffect, useState } from 'react';
import { BaseModal, Button, Row, Col } from '../../../ui';
import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../ui/image/image';
import './campaignModalRecapImages.scss';
import ReactPlayer from 'react-player';
import { Video } from '../../../ui/image/video';

export const CampaignModalRecapVideo = ({ open, onClose, video, label = 'video', info }) => {
  const { t } = useTranslation();

  return (
    <BaseModal open={open} propStyle={{ minWidth: '85vw' }}>
      <Card id={'campaign-modal-recap-images'}>
        <Typography variant="h2" style={{ marginBottom: '2rem' }}>
          {t(label)}
        </Typography>

        <Row xs={6} md={12} justifyCenter>
          <Video uri={video} path={video} className={'video'} />
        </Row>

        {!!info && (
          <Row sm={12} className={'images'} justifyCenter>
            <div dangerouslySetInnerHTML={{ __html: info }} />
          </Row>
        )}

        <Row justifyEnd>
          <Button onClick={onClose} variant={'outlined'}>
            {t('close')}
          </Button>
        </Row>
      </Card>
    </BaseModal>
  );
};
