import React from 'react'
import {BaseModal, Button, Col, Row} from '../../ui';
import {Card, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import './AuthRequriedModal.scss'

export const AuthRequiredModal = ({open, onClose, onConfirm, title = 'auth required', subtitle = 'login to procede' }) => {
  const { t } = useTranslation()

  const onClickConfirm = () => {
    onClose()
    onConfirm()
  }

  return (
    <BaseModal open={open} propStyle={{minWidth: '50vh'}}>
      <Card id={'modal-confirm'}>
        <Typography variant='h2'>{t(title)}</Typography>
        <Col className={'subtitle'}>
          <Typography variant='body' >{t(subtitle)}</Typography>
        </Col>
        <Row spaceBetween>
          <Button onClick={onClose} variant={'outlined'}>{t('undo')}</Button>
          <Button onClick={onClickConfirm}>{t('login')}</Button>
        </Row>
      </Card>
    </BaseModal>
  )
}

