import React, { memo } from 'react';
import './modalBankTransfer.scss';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from '@mui/material';
import { BaseModal, Button, Row } from '../../ui';

export const ModalBankTransfer = memo(({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const handleClickCancel = () => {
    onClose();
  };
  const handleClickConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <BaseModal open={open} onClose={() => onClose()}>
      <Card sx={{ width: 530 }} id={'modal-bank-transfer'}>
        <Typography component="span" variant="h1">
          {t('bank transfer')}
        </Typography>
        <p className={'modal-body'}>{t('bank transfer modal text 1')}</p>
        <p className={'modal-reminder'}>{t('bank transfer modal text 2')}</p>
        <Row className={'modal-controls'}>
          <Button text={t('undo')} variant="outlined" onClick={handleClickCancel} className={'button'} />
          <Button text={t('choice')} onClick={handleClickConfirm} className={'button'} />
        </Row>
      </Card>
    </BaseModal>
  );
});
