import React, {memo} from "react";
import { ButtonBranded } from "../../ui";
import './socialButtons.scss'
import {useTranslation} from 'react-i18next';
import {IconFacebook, IconGoogle, IconMicrosoft} from "../../../assets";
import {CARD_LOGIN_VARIANT_SIGN_UP} from "../cards/CardLogin";

export const SocialButtons = memo(({
  onClick,
  disabled,
  action,
  variant,
}) => {
  const { t } = useTranslation()
  const text = variant === CARD_LOGIN_VARIANT_SIGN_UP ? 'register' : 'login'

  const handleClickGoogle = () => {
    onClick('google')
  }

  const handleClickFacebook = () => {
    onClick('facebook')
  }

  const handleClickMicrosoft = () => {
    onClick('microsoft')
  }

  return (
    <p className='c-buttons-social-auth' id={'socialButtons'}>
      <span>
        {t('or')}
      </span>
      <ButtonBranded
        fullWidth
        brand='google'
        text={`${t(text)} ${t('with google')}`}
        className={'button'}
        icon={IconGoogle}
        disabled={disabled}
        onClick={handleClickGoogle}
      />
      <ButtonBranded
        fullWidth
        brand='facebook'
        className={'button'}
        icon={IconFacebook}
        text={`${t(text)} ${t('with facebook')}`}
        disabled={disabled}
        onClick={handleClickFacebook}
      />
      {/*<ButtonBranded*/}
      {/*  fullWidth*/}
      {/*  brand='microsoft'*/}
      {/*  className={'button'}*/}
      {/*  icon={IconMicrosoft}*/}
      {/*  text={`${t(text)} ${t('with microsoft')}`}*/}
      {/*  disabled={disabled}*/}
      {/*  onClick={handleClickMicrosoft}*/}
      {/*/>*/}
    </p>
  )
})

