import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/userSlice'
import appReducer from '../features/appSlice'
import campaignReducer from '../features/campaignSlice'
import influencerReducers from '../features/influencerSlice'
import businessUnitReducers from '../features/businessUnitSlice'
import metricReducers from '../features/metricSlice'
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import thunk from 'redux-thunk';

const reducers = combineReducers({
  user: userReducer,
  app: appReducer,
  influencer: influencerReducers,
  campaign: campaignReducer,
  businessUnit: businessUnitReducers,
  metric: metricReducers
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, logger],
});

export const persistor = persistStore(store)
