import axios from 'axios';
import { toast } from 'react-toastify';
import { translate } from '../i18n';
import { CONTACT_TYPES, ERRORS, TOAST } from '../utils/const';
import _ from 'lodash';
export const sendPhoneVerification = async (telData, cmpId) => {
  const { number, prefix } = telData;
  console.log({ number, prefix });
  try {
    const phoneData = {
      phone: {
        typeof: 'phone',
        prefix,
        number,
      },
      cmpId,
    };
    console.log({ phoneData });
    const { data } = await axios.post('/public/userContact/phone/sendVerificationCode', phoneData);
    console.log({ data });
    toast(translate('contactForm.phoneCodeSentSuccess'), TOAST.SUCCESS);
    return true;
  } catch (e) {
    console.debug({ sendEmailVerificationError: e });
    toast(translate(_.get(e, 'response.data.errors[0]', 'genericError') || 'genericError'), TOAST.ERROR);
  }
};

export const sendEmailVerification = async (form, cmpId) => {
  try {
    const mailData = {
      mail: {
        typeof: 'Mail',
        mail: form.email,
      },
      cmpId,
    };
    console.log({ mailData });
    const { data } = await axios.post('/public/userContact/mail/sendVerificationCode', mailData);
    console.log({ data });
    toast(translate('contactForm.emailCodeSentSuccess'), TOAST.SUCCESS);
    return true;
  } catch (e) {
    console.debug({ sendEmailVerificationError: e });
    const errorMessage = _.get(e, 'response.data.errors[0]', 'genericError') || 'genericError';
    console.debug({ errorMessage });
    toast(translate(_.get(e, 'response.data.errors[0]', 'genericError') || 'genericError'), TOAST.ERROR);
    return false;
  }
};

export const verifyEmailCode = async (data, verificationCode) => {
  try {
    const verificationData = {
      mail: {
        typeof: 'Mail',
        mail: data.email,
      },
      'mail-code': verificationCode,
    };
    const { data: response } = await axios.post('public/userContact/mail/verify', verificationData);
    console.log({ response });
    toast(translate('contactForm.codeValid'), TOAST.SUCCESS);
    return true;
  } catch (e) {
    console.debug({ verifyEmailCodeError: e });
    toast(translate(_.get(e, 'response.data.errors[0]', 'genericError') || 'genericError'), TOAST.ERROR);
    return Promise.reject(e);
  }
};

export const verifySmsCode = async (data, verificationCode) => {
  try {
    const verificationData = {
      phone: {
        typeof: 'phone',
        prefix: data.prefix,
        number: data.number,
      },
      'phone-code': verificationCode,
    };
    const { data: response } = await axios.post('public/userContact/phone/verify', verificationData);
    console.log({ response });
    toast(translate('contactForm.codeValid'), TOAST.SUCCESS);
    return true;
  } catch (e) {
    const errorMessage = _.get(e, 'response.data.errors[0]', 'genericError') || 'genericError';
    console.debug({ errorMessage });
    toast(translate(_.get(e, 'response.data.errors[0]', 'genericError') || 'genericError'), TOAST.ERROR);
    return Promise.reject(e);
  }
};

export const fillContactForm = async (data, contactType, cmpId, infId) => {
  let contactFormData = {
    privacyAccepted: true,
    cookiesAccepted: true,
    authorizationGiven: true,
  };

  console.log({ contactType, cmpId, infId });

  if (contactType.includes(CONTACT_TYPES.PHONE)) {
    contactFormData = {
      ...contactFormData,
      phone: {
        typeof: 'phone',
        prefix: data.prefix,
        number: data.number,
      },
    };
  }

  if (contactType.includes(CONTACT_TYPES.EMAIL)) {
    contactFormData = {
      ...contactFormData,
      email: {
        typeof: 'Mail',
        mail: data.email,
      },
    };
  }

  console.debug({ contactFormData });

  try {
    const res = await axios.post(`public/fillContactForm/${cmpId}/${infId}`, contactFormData);
    console.debug({ res });
  } catch (e) {
    const errorMessage = _.get(e, 'response.data.errors[0]', 'genericError') || 'genericError';
    console.debug({ errorMessage });
  }
};
