import React, { useEffect, useState } from 'react';
import { Button, Col, InputFile, InputText, Row } from '../../ui';
import './Profile.scss';
import { CircularProgress, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { deleteMe, updateCurrentBusinessUser } from '../../../api';
import { currentUserProvider, logout } from '../../../api/auth';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../app/routers';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import { AvatarIcon } from '../../../assets';
import _ from 'lodash';
import { ProfileDeleteModal } from '../modals';
import { uploadBusinessUserImage } from '../../../api/upload';
import { Image } from '../../ui/image/image';
import { theme } from '../../../utils';
import { useFormik } from 'formik';
import { profileSchema } from '../../../utils/validations';

export const Profile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userDetail = useSelector(userSelectors.detail);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState('');

  useEffect(() => {
    const prov = currentUserProvider();
    console.log({ prov });
    setProvider(prov);
  }, []);

  const { setFieldValue, errors, validateField, values, isValid, dirty } = useFormik({
    initialValues: {
      ...userDetail,
    },
    validationSchema: profileSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (data) => {
      console.log({ data });
    },
  });

  // const onDelete = async () => {
  //   await deleteMe();
  //   await logout();
  //   history.replace(AppRoutes.home());
  // };

  const openDeleteModal = () => {
    setShowModal(true);
  };

  const onLogout = async () => {
    await logout();
    history.replace(AppRoutes.home());
  };

  const onClickEditAvatar = () => {
    // console.log('onClickEditAvatar');
  };

  const onSave = async () => {
    await updateCurrentBusinessUser(values);
  };

  const onCancel = () => {};

  const onUploadFile = async (url) => {
    setImage(url);
    setLoading(true);
    await uploadBusinessUserImage(url);
    setLoading(false);
  };

  const onChangeValue = (fieldName, fieldValue) => {
    setFieldValue(fieldName, fieldValue);
    validateField(fieldName);
  };

  return (
    <div id={'profile'}>
      <ProfileDeleteModal open={showModal} onClose={() => setShowModal(false)} />
      <Grid container justifyContent={'center'} md={12} xs={12} sx={{ mr: '1rem' }}>
        <Row md={12} spaceBetween style={{ paddingBlock: '0.5rem' }}>
          <Grid item md={6}>
            <Typography variant={'h2'}>{t('your profile')}</Typography>
          </Grid>
          <Grid container md={6} justifyContent={'flex-end'}>
            <Button text={t('delete profile')} size={'small'} onClick={openDeleteModal} style={{ borderRadius: '0.8rem' }} />
          </Grid>
        </Row>
        <Row md={12} style={{ paddingBlock: '0.5rem' }} spaceBetween>
          <Col md={6} justifyCenter flex style={{ minHeight: '8rem' }}>
            {loading ? <CircularProgress /> : <Image alt={userDetail?.name} source={AvatarIcon} path={_.get(userDetail, 'avatar.uri')} className={'avatar'} />}
          </Col>
          <Grid container md={6} justifyContent={'flex-start'} alignItems={'center'}>
            <InputFile
              onUpload={onUploadFile}
              editImage={true}
              buttonStyle={{
                backgroundColor: 'white',
                color: theme['primaryColor'],
              }}
              maxDimension={2 ** 20}
              accept={'.png,.jpeg,.jpg,.webp'}
              link
            />
          </Grid>
        </Row>
        <Item fieldName={'firstName'} values={values} errors={errors} onChangeValue={onChangeValue} />
        <Item fieldName={'lastName'} values={values} errors={errors} onChangeValue={onChangeValue} />
        <Item fieldName={'companyRole'} values={values} errors={errors} onChangeValue={onChangeValue} />
        <Row md={12} xs={12} spacing={2} style={{ paddingBlock: '1rem' }} spaceBetween>
          <Col item md={6} xs={12} flexStart>
            <Typography variant={'bold'} color={'grey'}>
              {t('user credential')}
            </Typography>
            <Typography variant={'p'}>{_.get(userDetail, 'account.email')}</Typography>
          </Col>
          <Row item md={6} xs={12} alignEnd spaceBetween>
            {provider === 'password' && (
              <Link onClick={onClickEditAvatar} className={'link'}>
                {t('change password')}
              </Link>
            )}
            <Button
              text={t('logout')}
              size={'small'}
              onClick={onLogout}
              style={{
                borderRadius: '0.8rem',
                backgroundColor: '#D7D7D7',
                color: 'black',
              }}
            />
          </Row>
        </Row>
        <Row md={12} xs={12} spacing={2} style={{ paddingTop: '1rem' }} spaceBetween>
          <Grid item md={6} xs={12} alignItems={'center'}>
            <Button text={t('cancel')} variant={'outlined'} fullWidth onClick={onCancel} />
          </Grid>
          <Grid item md={6} xs={12} justifyContent={'flex-start'} alignItems={'center'}>
            <Button text={t('save')} fullWidth onClick={onSave} disabled={!isValid && dirty} />
          </Grid>
        </Row>
      </Grid>
    </div>
  );
};

const Item = ({ fieldName, values, onChangeValue, errors }) => {
  const { t } = useTranslation();

  return (
    <Row md={12} xs={6} style={{ paddingBlock: '1rem' }} spaceBetween>
      <Grid md={6} alignItems={'center'}>
        <Typography variant={'h3'}>{t(fieldName)}</Typography>
      </Grid>
      <Grid container md={6} justifyContent={'flex-start'} alignItems={'center'}>
        <InputText
          value={values[fieldName]}
          error={errors[fieldName]}
          helperText={t(errors[fieldName])}
          onChange={(v) => onChangeValue(fieldName, v.target.value)}
          variant={'standard'}
          style={{ color: theme['primaryColor'] }}
        />
      </Grid>
    </Row>
  );
};
