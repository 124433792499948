import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import './icon.scss'
import clsx from "clsx";
import { Box, CircularProgress } from '@mui/material';

export const Icon = ({icon, className, onClick, color, sx, size, loading, ...rest}) => {
  const type = icon.substr(0, 3);
  const fontIcon = icon.substr(7, icon.length);

  if (
    type === 'fab' ||
    type === 'fas' ||
    type === 'far' ||
    type === 'fal' ||
    type === 'fad'
  ) {
    return (
      <Box id={'icon'} sx={sx}>
        {!loading && <FontAwesomeIcon {...rest} icon={[type, fontIcon]} className={clsx(className, onClick && 'pointer')}
                         onClick={onClick} color={color} size={size}/>}

        {loading && <CircularProgress style={{width: 20, height: 20}} className="loader" color="secondary"/> }

      </Box>
    )
  }
  return <div>Icon error</div>
}
