import axios from 'axios';
import _ from 'lodash';

export const getInfluencerInfo = async (infNickname) => {
  try {
    const { data } = await axios.get(
      `public/influencers/cardByUsername/${infNickname}`
    );
    console.debug({ infData: data });
    return _.cloneDeepWith(data);
  } catch (e) {
    console.log({ e });
  }
};
