import React from 'react'
import {Tooltip} from "@mui/material";
import _ from 'lodash'

export const ProgressBar = ({ bgColor, progress, endLabel, tooltip }) => {

  const height = 30

  const containerStyles = {
    position: 'relative',
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: 10,
    marginTop: 5,
    height: height,
    justifyContent: 'center',
    alignItems: 'center',
  }

  const fillerStyles = {
    height: height,
    justifyContent: 'center',
    alignItems: 'center',
    width: `${_.round(progress)}%`,
    backgroundColor: bgColor,
    borderRadius: 'inherit',
    textAlign: 'right',
    // paddingTop: 5,
  }

  const labelStyles = {
    top: 5,
    marginLeft: 5,
    color: 'white',
    fontWeight: 'bold'
  }

  const endLabelStyle = {
    position: 'absolute',
    padding: 5,
    right: 10,
    top: 0,
    fontWeight: 'bold'
  }

  return (
    <div style={containerStyles}>
      {tooltip ? <Tooltip title={tooltip} placement={'top'}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${Number(progress).toFixed(0)}%`}</span>
        </div>
      </Tooltip>:
        <div style={fillerStyles}>
          <div style={{height: 5}}/>
          <span style={labelStyles}>{`${Number(progress).toFixed(0)}%`}</span>
          {/*<span style={endLabelStyle}>{endLabel}</span>*/}
        </div>
      }
      <span style={endLabelStyle}>{endLabel}</span>
    </div>
  );
};

