import React, { useEffect, useState } from 'react';
import './CampaignListPage.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Col, Icon, InputText, Row } from '../../../components/ui';
import InputAdornment from '@mui/material/InputAdornment';
import CampaignFilters from './CampaignFilters';
import { setCampaignSelected } from '../../../features/campaignSlice';
import { downloadCsv, getEnchorerCampaignList } from '../../../api/enchorer';
import Table from '../../../components/layout/table/Table';
import _ from 'lodash';
import { fromMillisToFormat, theme } from '../../../utils';
import { AppRoutes } from '../../../app/routers';
import TablePagination from '../../../components/layout/table/TablePagination';
import { deleteCampaign } from '../../../api';
import { campaignsEntityFields } from '../../../utils/entityFields';
import { getAppConfig } from '../../../api/configurations';
import { defaultListObj } from '../../../utils/const';

const CampaignListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [campaignFilters, setCampaignFilters] = useState({});
  const [page, setPage] = useState(0);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [data, setData] = useState(defaultListObj);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(undefined);
  const history = useHistory();
  const [sort, setSort] = useState({});
  const [searchText, setSearchText] = useState(_.get(campaignFilters, 'fts.text', ''));

  const onChangeText = (e) => {
    const val = e.target.value;
    setSearchText(val);
    const fts = {
      text: val,
      caseSensitive: false,
    };
    setCampaignFilters((prevState) => ({ ...prevState, fts }));
  };

  const onChangeFilters = (filters) => {
    setCampaignFilters((prevState) => ({ ...prevState, ...filters }));
  };

  useEffect(() => {
    getAppConfig();
  }, []);

  const onDownloadCsv = async () => {
    setLoadingCsv(true);
    await downloadCsv('Campaign');
    setLoadingCsv(false);
  };

  useEffect(() => {
    getInitialData();
  }, [campaignFilters]);

  const getInitialData = async () => {
    const res = await getEnchorerCampaignList(0, { ...campaignFilters, sort });
    setData(res);
    setPage(0);
  };

  const handleChangePage = async (newPage) => {
    const res = await getEnchorerCampaignList(newPage, { ...campaignFilters, sort });
    setData(res);
    setPage(newPage);
  };

  const onRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    let sort = { asc: isAsc };

    sort.fields = [property];

    setSort(sort);

    await getInitialData();
  };

  const goToCampaignDetail = (campaign) => {
    dispatch(setCampaignSelected(campaign));
    history.push(AppRoutes.campaignDetail(campaign._id));
  };

  const onDeleteCampaign = async (campaign) => {
    await deleteCampaign(campaign._id);
    await getEnchorerCampaignList(page, campaignFilters);
  };

  const bodyCellComponent = {
    company: (row) => _.get(row, 'businessUnit.name', ' - '),
    'creation date': (row) => fromMillisToFormat(_.get(row, 'created.ts', 0)),
    'duration.begin': (row) => fromMillisToFormat(_.get(row, 'duration.begin.ts', 0)),
    'duration.end': (row) => fromMillisToFormat(_.get(row, 'duration.end.ts', 0)),
    campaignStatus: (row) => renderStatusComponent(row),
    typeof: (row) => t(`${row.typeof}.title`),
    'budget.budget.value': (row) => renderBudget(row),
    actions: (row) => renderActions(row),
  };

  const renderBudget = ({ budget }) => {
    console.log({ budget });
    return <span>{_.get(budget, 'budget.value') ? `${budget.budget.value.toFixed(2)} ${_.get(budget, 'budget.currency.values[0]')}` : ' - '}</span>;
  };

  const renderStatusComponent = ({ campaignStatus }) => {
    return (
      <Col alignCenter justifyCenter className={`campaignStatus ${campaignStatus}`}>
        <Typography variant="bold">{t(campaignStatus).toUpperCase() || ' - '}</Typography>
      </Col>
    );
  };

  const renderActions = (row) => {
    console.log({ row });
    const style = { width: '1rem', height: '1rem', padding: '0.3rem' };
    return (
      <Row justifyEnd flex spaceBetween>
        <IconButton className={'button'} onClick={() => goToCampaignDetail(row)}>
          <Icon icon={'far fa-eye'} color={theme['primaryColor']} style={style} />
        </IconButton>
        <IconButton className={'button'}>
          <Icon icon={'fas fa-chart-bar'} color={theme['primaryColor']} style={style} />
        </IconButton>
        <IconButton className={'button'} onClick={() => onDeleteCampaign(row)}>
          <Icon icon={'fas fa-trash'} color={theme['errorColor']} style={style} />
        </IconButton>
      </Row>
    );
  };

  return (
    <div id={'campaign-list'}>
      <Row alignCenter flex container md={12}>
        <Grid item md={3}>
          <Typography variant={'h1'}>{t('campaign list')}</Typography>
        </Grid>
        <Row item alignCenter md={9}>
          <Row flex>
            <InputText
              variant={'outlined'}
              fullWidth
              value={searchText}
              onChange={onChangeText}
              label={t('search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon icon={'far fa-search'} color={'black'} />
                  </InputAdornment>
                ),
              }}
            />
          </Row>
          <IconButton className={'button-download'} onClick={onDownloadCsv}>
            <Icon loading={loadingCsv} icon={'far fa-file-csv'} color={'white'} sx={{ padding: '0.5rem' }} style={{ width: '1.5rem', height: '1.5rem' }} />
          </IconButton>
        </Row>
      </Row>
      <CampaignFilters filters={campaignFilters} onChangeFilters={onChangeFilters} />
      <Table onRequestSort={onRequestSort} order={order} orderBy={orderBy} sortEnable showCheckbox={false} entityFields={campaignsEntityFields} data={data.data} bodyCellComponent={bodyCellComponent}>
        <TablePagination rows={data.data} setPage={handleChangePage} totalData={data.total} page={page} />
      </Table>
    </div>
  );
};

export default CampaignListPage;
