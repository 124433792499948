import React from "react";
import {useTranslation} from "react-i18next";
import {BaseModal, Button, Col, InputText, InputTextArea, Row} from "../../ui";
import {Card, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {useFormik} from "formik";
import './CampaignModalDismiss.scss'
import _ from "lodash";
import {dismissModalSchema, payPerClick} from "../../../utils/validations";


export const dismissMode = {
  DECLINE: 'decline',
  DELETE: 'delete'
}
export const CampaignModalDismiss = ({open, onClose, onConfirm}) => {

  const { t } = useTranslation()
  const {
    handleSubmit,
    setFieldValue,
    isValid,
    errors,
    validateField,
    validateForm,
    values,
  } = useFormik({
    initialValues: {
      mode: '',
      motivation: undefined,
      notes: ''
    },
    validationSchema: dismissModalSchema,
    validateOnMount: false,
    isInitialValid: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      onClose()
      onConfirm(data.mode, data.notes)
    },
  });

  const onChangeMode = async (e) => {
    e.preventDefault()
    await setFieldValue('mode', e.target.value)
  }

  const onChangeNotes = async (value) => {
    console.log({value})
    await setFieldValue('notes', value)
  }


  return (
    <BaseModal open={open} onClose={onClose}>
      <Card id={'modal-confirm'}>
        <Typography variant='h2'>{t('dismiss campaign')}</Typography>
        <Col className={'subtitle'}>
          <Typography variant='body' >{t('sure to dismiss campaign')}</Typography>
        </Col>
        <Col>
          <FormControl component='fieldset'>
            <RadioGroup value={values.mode} onChange={onChangeMode}>
              <FormControlLabel
                value={dismissMode.DECLINE}
                control={<Radio sx={{paddingTop: '0px', marginTop: '0px'}}/>}
                label={<Col flex>{t(`dismiss label ${dismissMode.DECLINE}`)}</Col>}
              />
              <FormControlLabel value={dismissMode.DELETE} control={<Radio sx={{paddingTop: '0px', marginTop: '0px'}}/>} label={t(`dismiss label ${dismissMode.DELETE}`)}/>
            </RadioGroup>
          </FormControl>
        </Col>
        {values?.mode && <Col sx={{mt: '1rem', mb: '1rem'}}>
          <Typography variant={'body2'}>
            {t(`dismiss text ${values.mode}`)}
          </Typography>
        </Col>}
        {_.isEqual(values.mode, dismissMode.DECLINE) &&
        <InputTextArea
          style={{borderRadius: 16}}
          rows='4'
          onChange={onChangeNotes}
          value={values.notes}
          placeholder={t('notes')}
          maxLength={200}
        />
        }
        <Row flex spaceBetween style={{marginTop: 16}}>
          <Button onClick={onClose} variant={'outlined'}>{t('undo')}</Button>
          <Button
            onClick={handleSubmit}
          >
            {t('confirm')}
          </Button>
        </Row>
      </Card>
    </BaseModal>
  )
}
