import React, { memo } from 'react';
import { TextField } from '@mui/material';
import clsx from 'clsx';

import './inputText.scss';

export const InputNumber = memo(({ onChange, ...props }) => {
  const onChangeNumber = (e) => {
    const { value } = e.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split('.');
      if (decimal?.length > 2) {
        return;
      }
    }
    onChange(e);
  };

  return <TextField err className={clsx('input-text')} onChange={onChangeNumber} {...props} />;
});
