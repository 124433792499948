import { createTheme } from '@mui/material'

import {
  MuiTextField,
  MuiFormControlLabel,
  MuiCard,
  MuiButton
} from './overrides'
import palette from './palette'
import spacing from './spacing'
import typography from './typography'

const theme = createTheme({
  palette,
  typography,
  spacing,
})

theme.components = {
  MuiTextField: MuiTextField(theme),
  MuiFormControlLabel: MuiFormControlLabel(theme),
  MuiCard: MuiCard(theme),
  MuiButton: MuiButton(theme),
}

export default theme

export const defaultStyleSpacing = 8
