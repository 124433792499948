import React, {useState} from 'react';
import './HomeBanner.scss'
import {Button, InputText, Row} from '../../ui';
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom'
import {FIELD_NAME_EMAIL} from '../steps/_StepEmail';
import {useDispatch, useSelector} from 'react-redux';
import {appSelectors, setAppDebugApiUrl, setLocalEnvironment} from '../../../features/appSlice';
import {ENVIRONMENTS} from '../../../app/App';

const HomeDebug = ({}) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const apiDebug = useSelector(appSelectors.appDebugApiUrl)

  const [newApi, setNewApi] = useState(apiDebug || '')

  const onClickSetNewApi = () => {
    dispatch(setAppDebugApiUrl(newApi))
  }
  const onClickReset = () => {
    setNewApi('')
    dispatch(setAppDebugApiUrl(''))
  }
  const setEnv = (newEnv) => {
    dispatch(setLocalEnvironment(newEnv));
  };

  return (
    <Row alignCenter justifyCenter id={'home-banner'}>
      <InputText
        label={t('base api url')}
        fullWidth
        type='text'
        name={FIELD_NAME_EMAIL}
        onChange={(v) => setNewApi(v.target.value)}
        value={newApi}
      />

      <Button onClick={onClickSetNewApi}>Imposta endpoint custom</Button>
      <Button onClick={onClickReset}>Reset endpoint</Button>

      <Row>
        <ButtonEnv env={ENVIRONMENTS.PROD} setEnv={setEnv}/>
        <ButtonEnv env={ENVIRONMENTS.PRE_PROD} setEnv={setEnv}/>
        <ButtonEnv env={ENVIRONMENTS.DEV} setEnv={setEnv}/>
      </Row>
    </Row>
  )
}

export default HomeDebug

const ButtonEnv = ({env, setEnv}) => {
  const localEnvironment = useSelector(appSelectors.localEnvironment);
  const isSelected = localEnvironment === env;
  const style = {
    backgroundColor: isSelected ? '#ffc527' : undefined
  }
  console.log({style})
  return (
    <Button onClick={() => setEnv(env)} style={style}  text={env} />
  );
};
