
const MuiFormControlLabel = (theme) => ({
  styleOverrides: {
    root: {
      alignItems: 'flex-start',
      '& .MuiCheckbox-root': {
        marginTop: -9
      }
    }
  }
})

export default MuiFormControlLabel
