import React, {useEffect, useMemo} from 'react';
import './CartPageDetail.scss'
import {useTranslation} from "react-i18next";
import {Button, Col, Icon, Row} from "../../components/ui";
import {Card, Link, Typography, Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {CampaignDetail} from "../../components/layout/campaign/CampaignDetail";
import {campaignTypes, CREATE_COMPANY_STEPS, STEP_SOCIAL} from '../../utils/const';
import {setCampaignCompletedSteps, setCampaignCurrentStep, setCampaignSelected} from '../../features/campaignSlice';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppRoutes} from '../../app/routers';
import {isCampaignAvailableForPayment, theme} from '../../utils'
import {CampaignModalPayment} from "../../components/layout/modals";
import {useModal} from "../../utils/hook";
import {CreateCompanyData1, CreateCompanyData2, CreateCompanyData3} from "../../components/layout/createCompany";
import {getBusinessUnitCampaigns} from "../../api";
import {businessUnitSelectors} from '../../features/businessUnitSlice';
import _ from 'lodash';

const CartPageDetail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modal, { open: openModal, close }] = useModal();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const history = useHistory()
  const { id } = useParams();
  console.log({id})

  useEffect(() => {
  }, [])

  const campaign = useSelector(state => {
    return state.campaign.cart[id];
  })

  console.log({campaign})

  const removeToCart = () => {
    dispatch(setCampaignSelected({}))
  }

  const goToEditCampaign = () => {
    dispatch(setCampaignCurrentStep(STEP_SOCIAL))
    dispatch(setCampaignCompletedSteps([]))
    history.push(`${AppRoutes.campaign()}/${campaignTypes.PayPerClick}`)
  }

  const Action = () => {
    return (
      <Row alignCenter justifyCenter className={'action-container'}>
        <Button variant={'outlined'} text={t('edit')} onClick={goToEditCampaign} style={{width: '10rem'}}/>
      </Row>
    )
  }

  const Review = () => {
    return (
      <Card>
        <Col>
          <Typography variant={'h3'}>{t('order review')}</Typography>
          <hr />
          <Row>
            <Col style={{marginRight: '0.5rem'}} alignCenter justifyCenter>
              <Icon icon={'far fa-bells'} color={theme['primaryColor']} style={{width: 25, height: 25}} />
            </Col>
            <Col flex>
              <span className={'reviewType'}>{t(campaign.typeof)}</span>
              {!!campaign.name && <div className={'text'}>{campaign.name}</div>}
            </Col>
          </Row>
          <hr />
          <Col flexEnd>
            <Button
              text={t('pay')}
              onClick={openModal}
              style={{width: '10rem'}}
              disabled={!isCampaignAvailableForPayment(campaign)}
            />
          </Col>
        </Col>
      </Card>
    )
  }

  const onEditData = () => {

  }

  const BusinessUnitDetail = ({step}) => {
    const selectedBusinessUnit = useSelector(businessUnitSelectors.selected)

    const goToEditBusinessUnit = () => {
      console.log('go to edit ')
      history.push(`${AppRoutes.createCompany()}/${step}`)
    }

    const isOk = useMemo(() => {
      switch (step) {
        case CREATE_COMPANY_STEPS.COMPANY_DATA:
          return true
        case CREATE_COMPANY_STEPS.COMPANY_PROFILE:
          return selectedBusinessUnit && selectedBusinessUnit.description && _.get(selectedBusinessUnit, 'avatar.uri', '')
        case CREATE_COMPANY_STEPS.BILLING_INFO:
          return _.get(selectedBusinessUnit, 'billing.address', '')
      }
    }, [selectedBusinessUnit])

    return (
      <Col alignCenter justifyCenter style={{marginBottom: '1rem'}}>
        <Row md={10} flex container spaceBetween>
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            md={12}
            expanded={expanded === step}
            onChange={handleChange(step)}
            style={{width: '100%', borderRadius:'1rem'}}
          >
            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
              <Row>
                <Row alignCenter flex={0} xs={6}>
                  <Typography variant={'bold'} mr="0.5rem"> {t(step)} </Typography>
                  {isOk ?
                    <Icon icon={'fal fa-check-circle'} color={'green'}/> :
                    <Icon icon={'fal fa-times-circle'} color={'red'}/> }
                </Row>
                <Row xs={6} justifyEnd>
                  <Link onClick={handleChange(step)} className={'link'}>
                    {step === CREATE_COMPANY_STEPS.COMPANY_DATA ? t('view') : t('edit')}
                  </Link>
                </Row>
              </Row>
            </AccordionSummary>
            <AccordionDetails>
              {step === CREATE_COMPANY_STEPS.COMPANY_DATA && <CreateCompanyData1 hideAction={true}/>}
              {step === CREATE_COMPANY_STEPS.COMPANY_PROFILE && <CreateCompanyData2 fromCart/>}
              {step === CREATE_COMPANY_STEPS.BILLING_INFO && <CreateCompanyData3 fromCart/>}
            </AccordionDetails>
          </Accordion>
        </Row>
      </Col>
    )
  }

  return (
    <div id={'cart-page-detail'}>
      <CampaignModalPayment open={modal} campaign={campaign} onClose={close}/>
      <Row justifyCenter md={12}>
        <Col md={10} flexStart>
          <Typography variant={'h2'}>{t('cart')}</Typography>
        </Col>
      </Row>
      <Row container justifyCenter>
        <Col item md={8} style={{marginTop: '1rem'}}>
          <Col alignCenter justifyCenter style={{marginBottom: '1rem'}}>
            <Row md={10}>
              <Typography variant={'h3'}>{t('companyDetail')}</Typography>
            </Row>
          </Col>
          <BusinessUnitDetail step={CREATE_COMPANY_STEPS.COMPANY_DATA}/>
          <BusinessUnitDetail step={CREATE_COMPANY_STEPS.COMPANY_PROFILE}/>
          <BusinessUnitDetail step={CREATE_COMPANY_STEPS.BILLING_INFO}/>
          <CampaignDetail action={<Action />}/>
        </Col>
        <Col item md={4}>
          <Review />
        </Col>
      </Row>
      <Col style={{marginBlock: '1rem'}}/>
      {/*<Col alignCenter md={12} style={{marginBlock: '1rem'}}>*/}
      {/*  <Row md={8} spaceBetween>*/}
      {/*    <Button variant={'outlined'} text={t('remove to cart')} onClick={removeToCart} style={{width: '40%'}}/>*/}
      {/*    <Button text={t('buy now')} onClick={openModal} style={{width: '40%'}}/>*/}
      {/*  </Row>*/}
      {/*</Col>*/}
    </div>

  )
}

export default CartPageDetail
