import React, { memo, useEffect, useMemo, useState } from 'react';
import './HomeGantt.scss';
import { Col } from '../../ui';
import _ from 'lodash';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-gantt/dist/dx-gantt.css';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { ganttScaleUnitBasedOnFilters } from '../../../utils';
import { campaignSelectors } from '../../../features/campaignSlice';
import { campaignStatus, campaignTypes } from '../../../utils/const';
import Gantt, { Editing, Item, StripLine, Tasks, Toolbar } from 'devextreme-react/gantt';
import './../gantt/Gantt.scss';
import TaskTemplate from './../gantt/TaskTemplate';
import TaskTooltipTemplate from './../gantt/TaskTooltipTemplate';
import { getMetricsGanttHome } from '../../../api';
import { metricSelectors } from '../../../features/metricSlice';
import { useHistory } from 'react-router-dom';
import HomeGanttFilters from './HomeGanttFilters';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';

export const campaignTypeOpt = {
  ...campaignTypes,
  allTypes: undefined,
};

const inputStyle = {
  color: 'black',
  marginBottom: '0.5rem',
};

const HomeGantt = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const selectedBusinessUnit = useSelector(businessUnitSelectors.selected);
  const ganttFilters = useSelector(campaignSelectors.homeGanttFilter, shallowEqual);
  const campaigns = useSelector(metricSelectors.homeGanttCampaigns, shallowEqual);
  const [ganttConfig, setGanttConfig] = useState({
    scaleType: 'weeks',
    showResources: true,
    showDependencies: true,
    showCustomTaskTooltip: false,
    stripLineStart: null,
    stripLineEnd: null,
  });

  useEffect(() => {
    getMetricsGanttHome();
  }, [ganttFilters, selectedBusinessUnit]);

  const mappedRows = useMemo(() => {
    const filteredCamp = _.cloneDeepWith(campaigns.filter((camp) => [campaignStatus.APPROVED, campaignStatus.ACTIVE, campaignStatus.CLOSED, campaignStatus.TO_BE_APPROVED].includes(camp.campaignStatus)));
    console.log(filteredCamp);
    return _.map(filteredCamp, (campaign) => {
      const start = _.get(campaign, 'duration.begin.ts', DateTime.now().toMillis() );
      const end = _.get(campaign, 'duration.end.ts', DateTime.now().plus({days: campaign.duration.duration}).toMillis());
      return {
        id: campaign._id,
        parentId: null,
        title: campaign.name,
        start: DateTime.fromMillis(start).toJSDate(),
        end: DateTime.fromMillis(end).toJSDate(),
        progress: Number(_.get(campaign, 'completionRate', 0)),
      };
    });
  }, [campaigns]);

  useEffect(() => {
    const scaleType = ganttScaleUnitBasedOnFilters(ganttFilters);
    setGanttConfig({
      showResources: true,
      showDependencies: true,
      showCustomTaskTooltip: false,
      scaleType: scaleType.unit,
      stripLineStart: DateTime.now().startOf(scaleType.unit).toJSDate(),
      stripLineEnd: DateTime.now()
        .plus({ [scaleType.unit]: 1 })
        .startOf(scaleType.unit)
        .toJSDate(),
      //   if (scaleType.unit === 'days') {
      //     return DateTime.now().plus({[scaleType.unit]:1}).endOf(scaleType.unit).toJSDate();
      //   } else {
      //     return DateTime.now().endOf(scaleType.unit).toJSDate();
      //   }
      // },
    });
  }, [ganttFilters]);

  const campaignById = (cmpId) => {
    return campaigns.find((campaign) => campaign._id === cmpId);
  };

  console.debug({ ganttConfig, mappedRows });

  return (
    <Col flex id={'home-gantt'}>
      <HomeGanttFilters />
      <Gantt
        taskListWidth={0}
        taskTooltipContentRender={(task) => TaskTooltipTemplate(task, campaignById(task.id), t)}
        columns={[]}
        height={450}
        firstDayOfWeek={1}
        scaleType={ganttConfig.scaleType}
        allowSelection={false}
        startDateRange={ganttConfig.startDateRange}
        showDependencies={ganttConfig.showDependencies}
        showResources={ganttConfig.showResources}
        showCustomTaskTooltip={ganttConfig.showCustomTaskTooltip}
        taskContentRender={(item) => TaskTemplate(item, campaignById(item.taskData.id), t, history)}
        className={'gantt-subject'}>
        <Tasks dataSource={mappedRows} />
        <Toolbar>
          <Item name="zoomIn" />
          <Item name="zoomOut" />
        </Toolbar>
        <StripLine start={ganttConfig.stripLineStart} end={ganttConfig.stripLineEnd} title="Current Time" cssClass="current-time" />
        <Editing enabled={false} />
      </Gantt>
    </Col>
  );
};

export default memo(HomeGantt);
