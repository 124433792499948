import React, { memo, useEffect, useState } from 'react';
import './companyData1.scss';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button, InputSelect, InputText, Row } from '../../ui';
import { CATALOGUES_TYPES, CREATE_COMPANY_STEPS } from '../../../utils/const';
import { deleteBusinessUnit, getCatalogueAndSaveState, patchBusinessUnit } from '../../../api';
import { useFormik } from 'formik';
import _ from 'lodash';
import spacing from '../../../app/spacing.module.scss';
import { createCompanySchema } from '../../../utils/validations';
import { useSelector } from 'react-redux';
import { ConfirmModal } from '../modals';
import { AppRoutes } from '../../../app/routers';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { appSelectors } from '../../../features/appSlice';

export const CreateCompanyData1 = memo(({ hideAction = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalConfirmCreate, setShowModalConfirmCreate] = useState(false);
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const disableForm = _.get(businessUnit, 'name', false);
  const countryOptions = useSelector(appSelectors.catalogueByType(CATALOGUES_TYPES.COUNTRIES));

  useEffect(() => {
    getCatalogueAndSaveState(CATALOGUES_TYPES.COUNTRIES);
  }, []);

  const { handleSubmit, setFieldValue, isValid, errors, validateField, validateForm, values } = useFormik({
    initialValues: {
      country: businessUnit?.country,
      name: businessUnit?.name,
      vat: businessUnit?.vat,
    },
    validationSchema: createCompanySchema.page1,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      console.log({ data });
      validateForm().then(async () => {
        setShowModalConfirmCreate(true);
      });
    },
  });

  const createBusinessUnit = async () => {
    const res = await patchBusinessUnit(values);
    if (res) {
      history.push(`${AppRoutes.createCompany()}/${CREATE_COMPANY_STEPS.COMPANY_PROFILE}`);
    }
  };

  useEffect(() => {
    if (businessUnit) {
      setFieldValue('name', businessUnit.name);
      setFieldValue('country', businessUnit.country);
      setFieldValue('vat', businessUnit.vat);
    }
  }, [businessUnit]);

  const onChangeValue = async (value, fieldName) => {
    // console.log({ value, fieldName });
    await setFieldValue(fieldName, value);
    await validateField(fieldName);
  };

  const onClickDeleteAccount = async () => {
    // getCampaigns()
    setShowModalConfirm(true);
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);
    await deleteBusinessUnit();
    await setFieldValue('name', '');
    await setFieldValue('country', '');
    await setFieldValue('vat', '');
    setIsLoading(false);
  };
  // console.log({ values });

  return (
    <Grid id={'create-company-data1'}>
      <ConfirmModal
        open={showModalConfirm}
        onClose={() => setShowModalConfirm(false)}
        onConfirm={onConfirmDelete}
        title={t('modal confirm delete business unit title')}
        subtitle={t('modal confirm delete business unit description')}
      />
      <ConfirmModal
        open={showModalConfirmCreate}
        onClose={() => setShowModalConfirmCreate(false)}
        onConfirm={createBusinessUnit}
        title={t('modal confirm create business unit title')}
        subtitle={t('modal confirm create business unit description')}>
        <Typography variant={'h6'}>{t('company name')}</Typography>
        <Typography variant={'body2'} mt={'0.5rem'}>
          {values.name}
        </Typography>
        <Typography variant={'h6'} mt={'1.5rem'}>
          {t('country')}
        </Typography>
        <Typography variant={'body2'} mt={'0.5rem'}>
          {values?.country?.label}
        </Typography>
        <Typography variant={'h6'} mt={'1.5rem'}>
          {t('vat code')}
        </Typography>
        <Typography variant={'body2'} mt={'0.5rem'} mb={'2rem'}>
          {values.vat}
        </Typography>
      </ConfirmModal>
      {!hideAction && (
        <>
          <Typography variant={'h1'}>{t('company data')}</Typography>
          <Typography variant={'h6'}>{t('company data subtitle 1')}</Typography>
          <Typography variant={'h6'}>{t('company data subtitle 2')}</Typography>
        </>
      )}
      <form onSubmit={handleSubmit} className={'form'}>
        <Grid xs={12} container>
          <Grid
            xs={12}
            md={6}
            sx={{
              mb: spacing['md'],
            }}>
            <InputText
              label={t('company name')}
              name="name"
              value={values.name}
              onChange={(e) => onChangeValue(e.target.value, 'name')}
              variant="standard"
              error={errors.name}
              helperText={t(errors.name)}
              disabled={disableForm}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <InputSelect
              type={CATALOGUES_TYPES.COUNTRIES}
              label={t('country')}
              name="country"
              disabled={disableForm}
              onChange={(value) => onChangeValue(value, 'country')}
              options={countryOptions}
              error={errors.country}
              helperText={t(errors.country)}
              value={values?.country?._id}
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <InputText
            label={t('vat code')}
            name="vat"
            onChange={(e) => onChangeValue(e.target.value, 'vat')}
            variant="standard"
            error={errors.vat}
            helperText={t(errors.vat)}
            disabled={disableForm}
            value={values.vat}
          />
        </Grid>

        <Row xs={12} md={6}>
          {!disableForm && (
            <Button
              sx={{
                mt: spacing['md'],
              }}
              text={t('save')}
              onClick={handleSubmit}
              disabled={!isValid}
              loading={isLoading}
              className={'save-button'}
            />
          )}
          {disableForm && !hideAction && (
            <Button
              sx={{
                mt: spacing['md'],
              }}
              text={t('delete company account')}
              onClick={onClickDeleteAccount}
              loading={isLoading}
              className={'save-button'}
            />
          )}
        </Row>
      </form>
    </Grid>
  );
});
