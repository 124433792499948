import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import 'draft-js/dist/Draft.css';
import './TextEditor.scss';

import { convertToHTML } from 'draft-convert';
import { useTranslation } from 'react-i18next';

const toolbarDefault = {
  options: ['inline', 'textAlign'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  textAlign: { inDropdown: true },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
};

export const TextEditor = ({ onChange, style, placeholder, initial = '<p></p>', toolbarOptions = toolbarDefault, error }) => {
  const blocksFromHTML = convertFromHTML(initial);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(EditorState.createWithContent(state));

  useEffect(() => {
    const converted = convertToHTML(editorState.getCurrentContent());
    onChange(converted);
  }, [editorState]);

  const onChangeEditor = (editorState) => {
    setEditorState(editorState);
  };

  const border = !!error ? '1px solid red' : '1px solid grey';
  return (
    <div style={{ border, borderRadius: '8px', minHeight: '6em', padding: '8px', cursor: 'text', ...style }}>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorState={editorState}
        onEditorStateChange={onChangeEditor}
        placeholder={t(placeholder)}
        toolbar={toolbarOptions}
      />
      {!!error && <span className={'editorError'}>{t(error)}</span>}
    </div>
  );
};
