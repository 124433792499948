import React, { useEffect, useMemo, useState } from 'react';
import './ProductPage.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { BaseModal, Button, CheckboxRow, Col, Icon, InputText, Row } from '../../components/ui';
import { Grid, IconButton, Typography } from '@mui/material';
import { getImages, getParameterFromUrl, normalizeCurrency, openEnchoraCookies, openEnchoraPrivacy, theme } from './../../utils';
import _ from 'lodash';
import { Image } from '../../components/ui/image/image';
import { useTranslation } from 'react-i18next';
import { fillSalesForm, getPublicPageCampaignInfo } from '../../api';
import { getUrlImageFromPath } from '../../api/upload';
import { useFormik } from 'formik';
import { productRegisterForm } from '../../utils/validations';
import { toast } from 'react-toastify';
import { translate } from '../../i18n';
import { carouselBreakpointResponsive, TOAST } from '../../utils/const';
import PayPal from '../../components/layout/payments/PayPal';
import { captureOrderPaypal } from '../../api/payments';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Video } from '../../components/ui/image/video';
import { AppRoutes } from '../../app/routers';

const toggles = {
  customerTerms: 'customerTerms',
  customerPrivacy: 'customerPrivacy',
  enchoraPrivacy: 'enchoraPrivacy',
};

const buttonStyle = {
  borderRadius: '0.6rem',
};

const ProductPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: locationState } = useLocation();
  const [product, setProduct] = useState(locationState?.product);
  const [campaign, setCampaign] = useState(locationState);
  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [assets, setAssets] = useState([]);
  const [privacyToggles, setPrivacyToggles] = useState({
    [toggles.customerTerms]: false,
    [toggles.customerPrivacy]: false,
    [toggles.enchoraPrivacy]: false,
  });
  const [filledForm, setFilledForm] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const availableQuantity = _.get(campaign, 'availableQuantity', 0);
  const { infId, cmpId, code } = useMemo(() => {
    return getParameterFromUrl(history.location.search);
  }, [history.location.search]);

  const setPrivacyToggle = (toggleName) => {
    setPrivacyToggles((prevState) => {
      return {
        ...prevState,
        [toggleName]: !prevState[toggleName],
      };
    });
  };

  const visibleDescription = useMemo(() => {
    if (showMore) {
      return _.get(product, 'description');
    } else {
      return _.truncate(_.get(product, 'description'), { length: 400 });
    }
  }, [product, showMore]);

  const productPrice = useMemo(() => {
    return normalizeCurrency(_.get(product, 'price'));
  }, [product]);

  useEffect(() => {
    getData();
  }, []);

  const { setFieldValue, isValid, errors, validateField, handleSubmit, values, touched, validateForm, setFieldTouched, dirty } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      vat: '',
      number: '',
      prefix: '+39',
      shippingAddress: '',
      quantity: 1,
    },
    validationSchema: productRegisterForm,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    // isInitialValid: false,
    onSubmit: async (data) => {
      console.log({ data });
      const bunTermsAccepted = privacyToggles.customerTerms;
      const bunPrivacyAccepted = privacyToggles.customerPrivacy;
      const enchoraPrivacyAccepted = privacyToggles.enchoraPrivacy;

      const filled = await fillSalesForm(
        data,
        {
          bunTermsAccepted,
          bunPrivacyAccepted,
          enchoraPrivacyAccepted,
        },
        cmpId,
        infId
      );
      setFilledForm(filled);
      console.log({ filled });
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    },
  });

  const payDisable = useMemo(() => {
    console.log(isValid, dirty);
    if (filledForm) {
      return true;
    }
    return !privacyToggles.customerTerms || !privacyToggles.customerPrivacy || !privacyToggles.enchoraPrivacy || !isValid;
  }, [privacyToggles, isValid, dirty, filledForm]);

  const getData = async () => {
    if (cmpId && cmpId !== 'new') {
      const campaign = await getPublicPageCampaignInfo(cmpId);
      campaign && setCampaign(campaign);
      campaign && campaign?.product && setProduct(campaign.product);
      const initialAssets = [..._.get(campaign, 'product.images', []), ..._.get(campaign, 'product.video', [])];
      const convertedAssets = await getImages(initialAssets);
      setAssets(convertedAssets);
    } else {
      const initialAssets = [..._.get(locationState, 'product.images', []), ..._.get(locationState, 'product.video', [])];
      console.debug({ initialAssets, locationState });
      const convertedAssets = await getImages(initialAssets);
      setAssets(convertedAssets);
    }
  };

  const onChangeRegisterValue = async (fieldName, fieldValue) => {
    await setFieldValue(fieldName, fieldValue);
    await validateField(fieldName);
    await setFieldTouched(fieldName, true);
  };

  const handleClickBuy = () => {
    setShowCustomerInfo(true);
  };

  const handleClickPay = async () => {
    const errors = await validateForm(values);
    _.isEmpty(errors) ? handleSubmit() : toast(translate('check required fields'), TOAST.ERROR);
  };

  const onChangeQuantity = async (e) => {
    const value = e.target.value;
    await setFieldValue('quantity', value);
    await validateField('quantity');
    await setFieldTouched('quantity', true);
  };

  const helperText = (field) => {
    if (!!errors[field] && !!touched[field]) return t(errors[field]);
    else return '';
  };

  const showError = (field) => {
    return !!errors[field] && !!touched[field];
  };

  // const goBack = () => {
  //   history.goBack();
  // };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const total = values.quantity * _.get(campaign, 'product.price.value', 0);

  const onShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const disableBuyNow = values.quantity > availableQuantity || values.quantity < 1 || !cmpId;

  return (
    <div id={'product-page'}>
      <Row md={12} sm={12} xs={12} style={{ padding: '0.5rem' }} spaceBetween className={'header-container'}>
        <Row md={6} sm={12} xs={12} justifyStart alignCenter>
          {!!locationState && (
            <IconButton onClick={() => history.goBack()}>
              <Icon icon={'far fa-arrow-left'} color={'black'} />
            </IconButton>
          )}
          <Image uri={_.get(campaign, 'businessUnit.avatar.uri')} path={_.get(campaign, 'businessUnit.avatar.uri')} className={'businessUnit-avatar'} />
          <Typography variant="h3" color={theme['primaryColor']}>
            {_.get(campaign, 'businessUnit.name')}
          </Typography>
        </Row>
      </Row>
      <hr className={'separator-primary'} />
      {!showCustomerInfo && (
        <div className={'articleDetailDesktop'}>
          <Grid container md={12} sm={12} xs={12} style={{ flexDirection: 'row-reverse' }}>
            <Col item md={5} sm={12} xs={12}>
              <span className={'productTitle'}>{_.get(product, 'name')}</span>
              <div className={'textContainer'} dangerouslySetInnerHTML={{ __html: visibleDescription }} />
              {product && product?.description && product?.description?.length > 400 && !showMore && (
                <span onClick={onShowMore} className={'prod-show-more'}>
                  {t('showMore')}
                </span>
              )}
              {product && product?.description && product?.description?.length > 400 && showMore && (
                <span onClick={onShowMore} className={'prod-show-more'}>
                  {t('showLess')}
                </span>
              )}
              <div>
                <ArticleDetail
                  productPrice={productPrice}
                  product={product}
                  values={values}
                  showError={showError}
                  helperText={helperText}
                  onChangeQuantity={onChangeQuantity}
                  availableQuantity={availableQuantity}>
                  <Button fullWidth text={t('buyNow')} style={buttonStyle} disabled={disableBuyNow} onClick={handleClickBuy} />
                </ArticleDetail>
              </div>
            </Col>
            <Col item md={7} sm={12} xs={12}>
              <Content assets={assets} />
            </Col>
          </Grid>
        </div>
      )}
      {!showCustomerInfo && (
        <div className={'articleDetailMobile'}>
          <Col item md={5} sm={12} xs={12}>
            <span className={'productTitle'}>{_.get(product, 'name')}</span>
            <Col item md={7} sm={12} xs={12} mt={'0.5rem'}>
              <Content assets={assets} />
            </Col>
            <div className={'textContainer'} dangerouslySetInnerHTML={{ __html: visibleDescription }} />
            {product && product?.description && product?.description?.length > 400 && !showMore && (
              <span onClick={onShowMore} className={'prod-show-more'}>
                {t('showMore')}
              </span>
            )}
            {product && product?.description && product?.description?.length > 400 && showMore && (
              <span onClick={onShowMore} className={'prod-show-more'}>
                {t('showLess')}
              </span>
            )}
            <ArticleDetail
              productPrice={productPrice}
              product={product}
              values={values}
              showError={showError}
              helperText={helperText}
              onChangeQuantity={onChangeQuantity}
              availableQuantity={availableQuantity}>
              <Button fullWidth text={t('buyNow')} style={buttonStyle} disabled={disableBuyNow} onClick={handleClickBuy} />
            </ArticleDetail>
          </Col>
        </div>
      )}
      <Col>
        {showCustomerInfo && <SectionRegisterForm onChangeRegisterValue={onChangeRegisterValue} touched={touched} errors={errors} values={values} />}
        {showCustomerInfo && <SectionPrivacyAndConditions privacyToggles={privacyToggles} setPrivacyToggle={setPrivacyToggle} campaign={campaign} />}
        {showCustomerInfo && (
          <div className={'paypalButtons'}>
            {/*<img alt={'payPal'} src={PayPalIcon} className={'paypalIcon'} />*/}
            <Button style={buttonStyle} text={t('payWithPaypal')} onClick={handleClickPay} disabled={payDisable} className={'buttonPayPal'} />
          </div>
        )}
        {filledForm && <PayForm code={code} paypalId={campaign.paypalId} total={total} values={values} customId={filledForm?._id} />}
      </Col>
    </div>
  );
};

export default ProductPage;

const SectionRegisterForm = ({ onChangeRegisterValue, errors, values, touched }) => {
  const { t } = useTranslation();

  const style = {
    marginTop: '0.8rem',
  };

  const helperText = (field) => {
    if (!!errors[field] && !!touched[field]) return t(errors[field]);
    else return '';
  };

  const showError = (field) => {
    return !!errors[field] && !!touched[field];
  };

  return (
    <Col md={8} className={'sectionRegisterForm'}>
      <Typography variant="h3">{t('product.registerTitle')}</Typography>
      <Typography variant="body">{t('product.registerSubTitle')}</Typography>
      <InputText
        style={style}
        label={t('firstName')}
        fullWidth
        error={showError('firstName')}
        helperText={t(helperText('firstName'))}
        onChange={(v) => onChangeRegisterValue('firstName', v.target.value)}
        value={values.firstName}
        variant={'outlined'}
      />
      <InputText
        style={style}
        label={t('lastName')}
        fullWidth
        error={showError('lastName')}
        helperText={t(helperText('lastName'))}
        onChange={(v) => onChangeRegisterValue('lastName', v.target.value)}
        value={values.lastName}
        variant={'outlined'}
      />
      <InputText
        style={style}
        label={t('email')}
        fullWidth
        error={showError('email')}
        helperText={t(helperText('email'))}
        onChange={(v) => onChangeRegisterValue('email', v.target.value)}
        value={values.email}
        variant={'outlined'}
      />
      <InputText
        style={style}
        label={t('vat code')}
        fullWidth
        error={showError('vat')}
        helperText={t(helperText('vat'))}
        onChange={(v) => onChangeRegisterValue('vat', v.target.value)}
        value={values.vat}
        variant={'outlined'}
      />
      <InputText
        style={style}
        label={t('number')}
        fullWidth
        error={showError('number')}
        helperText={t(helperText('number'))}
        onChange={(v) => onChangeRegisterValue('number', v.target.value)}
        value={values.number}
        variant={'outlined'}
      />
      <InputText
        style={style}
        label={t('shippingAddress')}
        fullWidth
        error={showError('shippingAddress')}
        helperText={t(helperText('shippingAddress'))}
        onChange={(v) => onChangeRegisterValue('shippingAddress', v.target.value)}
        value={values.shippingAddress}
        variant={'outlined'}
      />
    </Col>
  );
};

const SectionPrivacyAndConditions = ({ privacyToggles, setPrivacyToggle, campaign }) => {
  const { t } = useTranslation();

  const onClickCustomerPrivacy = async () => {
    const url = _.get(campaign, 'product.privacy.uri');
    const normUrl = await getUrlImageFromPath(url);
    normUrl && window.open(normUrl, '_blank');
  };

  const onClickCustomerTerms = async () => {
    const url = _.get(campaign, 'product.terms.uri');
    const normUrl = await getUrlImageFromPath(url);
    normUrl && window.open(normUrl, '_blank');
  };

  return (
    <Col className={'sectionPrivacyAndConditions'}>
      <Typography variant="body">{t('product.privacySubtitle')}</Typography>
      <div className={'box-privacy'}>
        <CheckboxRow checked={privacyToggles[toggles.enchoraPrivacy]} onChangeChecked={() => setPrivacyToggle(toggles.enchoraPrivacy)}>
          <span className={'privacyText'}>&nbsp;{t('product.accept1')}&nbsp;</span>
          <span className={'privacyLink'} onClick={openEnchoraPrivacy}>
            {t('product.enchoraPrivacy')}
          </span>
          <span className={'privacyText'}>&nbsp;{t('product.accept2')}&nbsp;</span>
          <span className={'privacyLink'} onClick={openEnchoraCookies}>
            {t('product.enchoraCookies')}
          </span>
          <span className={'privacyText'}>&nbsp;{t('product.accept3')}&nbsp;</span>
        </CheckboxRow>
        <CheckboxRow checked={privacyToggles[toggles.customerPrivacy]} onChangeChecked={() => setPrivacyToggle(toggles.customerPrivacy)}>
          <span className={'privacyText'}>&nbsp;{t('product.accept4')}&nbsp;</span>
          <span className={'privacyLink'} onClick={onClickCustomerPrivacy}>
            {t('product.customerPrivacy')}
          </span>
          <span className={'privacyText'}>&nbsp;{t('product.accept5')}&nbsp;</span>
          <span className={'privacyText'}>{_.get(campaign, 'businessUnit.name', ' - ')}</span>
        </CheckboxRow>
        <CheckboxRow checked={privacyToggles[toggles.customerTerms]} onChangeChecked={() => setPrivacyToggle(toggles.customerTerms)}>
          <span className={'privacyText'}>&nbsp;{t('product.accept4')}&nbsp;</span>
          <span className={'privacyLink'} onClick={onClickCustomerTerms}>
            {t('product.customerTerms')}
          </span>
          <span className={'privacyText'}>&nbsp;{t('product.accept5')}&nbsp;</span>
          <span className={'privacyText'}>{_.get(campaign, 'businessUnit.name', ' - ')}</span>
        </CheckboxRow>
      </div>
    </Col>
  );
};

const PayForm = ({ code, paypalId, total, values, customId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // const [openFinish, setOpenFinish] = useState(false);
  const [openPaypal, setOpenPaypal] = useState(true);

  useEffect(() => {}, []);

  const onPayApprove = async (data) => {
    console.log({ data });
    const captureRes = await captureOrderPaypal(data.orderID);
    console.log({ captureRes });
    // setOpenFinish(true);
    setOpenPaypal(false);
    history.push(AppRoutes.paymentSuccess());
  };

  const onClose = () => {
    // setOpenFinish(false);
  };

  const shippingInfo = {
    name: {
      given_name: values.firstName,
      surname: values.lastName,
    },
    email_address: values.email,
    address: {
      address_line_1: values.shippingAddress,
    },
  };

  const payment_source = {
    apple_pay: {
      id: 'DSF32432423FSDFS',
      name: 'Randy Oscar',
      email_address: 'randy.oscar@gmail.com',
      phone_number: {
        country_code: '1',
        national_number: '18882211161',
      },
      shipping: shippingInfo,
    },
  };

  console.log({ code });
  return (
    <div>
      {/*<BaseDialog open={openFinish} onClose={onClose} title={t('product.paymentConfirmTitle')} text={t('product.paymentConfirmDescription')} />*/}
      <BaseModal open={openPaypal} propStyle={{ backgroundColor: 'white', padding: '3rem' }}>
        <div>
          <Typography mb={'1rem'} variant="h2">
            {t('product.payment')}
          </Typography>
          <Typography mb={'1rem'} variant="h4">
            {t('product.total')}: {total}€
          </Typography>
          <Typography mb={'1rem'} variant="body2">
            {t('product.paymentSubTitle')}
          </Typography>
          <PayPal orderId={code} onPayApprove={onPayApprove} paypalId={paypalId} total={total} payment_source={payment_source} customId={customId} />
        </div>
      </BaseModal>
    </div>
  );
};

const Section = ({ title, product, fieldPath, children, hideTitle = false }) => {
  const { t } = useTranslation();
  return (
    <Col flexStart className={'section-product-container'}>
      {!hideTitle && (
        <Row>
          <span className={'section-title'}>{t(title)}</span>
        </Row>
      )}
      <Row>{fieldPath ? <span>{_.get(product, `${fieldPath}`)}</span> : children}</Row>
    </Col>
  );
};

const ArticleDetail = ({ children, productPrice, product, values, showError, helperText, onChangeQuantity, availableQuantity }) => {
  const { t } = useTranslation();

  return (
    <Col flexStart>
      <Section hideTitle={true}>
        <Row>
          <Col>
            <span className={'prod-text'}>{t('price')}:&nbsp;</span>
          </Col>
          <Col>
            <span className={'prod-text product-price-value'}>{productPrice}</span>
            <span className={'prod-iva'}>({t('prodIva')})</span>
          </Col>
        </Row>
      </Section>
      <Section hideTitle={true}>
        <span className={'prod-text'}>{t('deliveryCondition')}:&nbsp;</span>
        <span className={'prod-text bold'}>{_.get(product, 'delivery.condition')}</span>
      </Section>
      <Section hideTitle={true}>
        <span className={'prod-text'}>{t('deliveryTime')}:&nbsp;</span>
        <span className={'prod-text bold'}>{_.get(product, 'delivery.note')}</span>
      </Section>
      <Section hideTitle={true}>
        <Col xs={12}>
          <InputText
            name="quantity"
            value={values.quantity}
            variant={'outlined'}
            error={showError('quantity')}
            label={t('quantity')}
            helperText={t(helperText('quantity'))}
            style={{ alignSelf: 'flex-start', marginBottom: '0.5rem', width: '10rem' }}
            onChange={onChangeQuantity}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder={t('quantity')}
            InputProps={{
              step: 1,
              lang: 'it-IT',
            }}
            type="number"
            inputProps={{ min: 1, max: availableQuantity }}
            // inputProps={{ min: 1 }}
          />
          {values.quantity > availableQuantity && <span className={'prod-text'}>
            {t('max quantity available')}: {availableQuantity}
          </span>}
        </Col>
      </Section>
      {children}
    </Col>
  );
};

const Content = ({ assets }) => {
  const hasAssets = !_.isEmpty(assets);
  const multipleAssets = _.size(assets) > 1;
  return (
    <div>
      {hasAssets && (
        <Carousel draggable={true} autoPlay={true} autoPlaySpeed={3000000} responsive={carouselBreakpointResponsive} infinite={multipleAssets} arrows={multipleAssets} transitionDuration={600}>
          {assets?.map((asset) => {
            console.debug({ asset });
            if (asset.mimeType.includes('image')) {
              return (
                <div key={asset.uri} style={{ width: '100%', height: '30rem' }} className={'img-cont'}>
                  <img alt="" src={asset.uri} className={'image-preview'} />
                </div>
              );
            }
            if (asset.mimeType.includes('video')) {
              return (
                <div key={asset.uri} style={{ width: '100%', height: '30rem' }} className={'img-cont'}>
                  <Video controls={true} path={asset.uri} uri={asset.uri} loop={true} playing={true} muted={true} />
                </div>
              );
            }
            return <div />;
          })}
        </Carousel>
      )}
    </div>
  );
};
