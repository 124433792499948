import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from './routers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import './theme.scss';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import '../api/axios';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
import { ThemeProvider } from '@mui/material';
import theme from '../theme';
import './../utils/const';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-gantt/dist/dx-gantt.css';

library.add(fas, far, fal, fad, fab);

export const ENVIRONMENTS = {
  PROD: 'PROD',
  PRE_PROD: 'PRE_PROD',
  DEV: 'DEV',
};
export const ENVIRONMENTS_URLS = {
  [ENVIRONMENTS.PROD]: 'https://api.enchora.com/v1/it',
  [ENVIRONMENTS.PRE_PROD]: 'https://pre-api.enchora.com/v1/it',
  [ENVIRONMENTS.DEV]: 'https://dev-api.enchora.com/v1/it',
};

export const Theme = ({ children }) => {
  // const isDarkTheme = useSelector(getStoreDarkTheme)
  // const classTheme = isDarkTheme ? 'dark-theme' : 'light-theme'
  // return (
  //   <div className={classTheme}>
  //     <div id="app">
  //       {children}
  //     </div>
  //   </div>
  // )
};

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  );
};

export default App;
