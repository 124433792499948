import { useTranslation } from "react-i18next";
import { Box, Card, CardContent } from '@mui/material'
import {BaseModal, Col, Container, Row} from "../../../components/ui";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom'
import {userSelectors} from "../../../features/userSlice";
import {useDispatch, useSelector} from "react-redux";
import { auth } from "../../../lib/firebase";
import CardSignup, {CARD_SIGNUP_STEP_PASSWORD} from "../../../components/layout/cards/CardSignup";
import {AppRoutes} from "../../../app/routers";
import {ImageBg1} from "../../../assets";
import {defaultStyleSpacing} from "../../../theme";
import {resetPassword, signIn} from "../../../api/auth";
import StepPassword from "../../../components/layout/steps/_StepPassword";
import {toast} from "react-toastify";
import {translate} from "../../../i18n";
import {TOAST} from "../../../utils/const";

const ResetPasswordPage = () => {
  const { t } = useTranslation()
  const user = useSelector(userSelectors.detail)
  const userToken = useSelector(userSelectors.token)
  const history = useHistory()
  const dispatch = useDispatch()
  const { state: locationState } = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  function useQuery() {
    const { search } = useLocation();
    console.log({search})
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const params = useQuery();

  useEffect(() => {
    console.log({user})
    setShowModal(true)
  }, [])

  const handleSubmitPassword = async (newPassword) => {
    const oobCode = params.get('oobCode')
    setLoading(true)
    //Davide12?
    console.log({oobCode, newPassword})
    const resetPasswordSuccess = await resetPassword(newPassword, oobCode)
    setLoading(false)
    console.log({resetPasswordSuccess})

    if(resetPasswordSuccess && userToken) {
      history.push(AppRoutes.home())
      toast(translate('reset password success'), TOAST.SUCCESS);
    } else {
      await signIn(user.email, newPassword)
      history.push(AppRoutes.home())
      toast(translate('reset password success, do login to continue'), TOAST.SUCCESS);
    }
    setShowModal(false)
  }


  return (
    <Container>
      <Box p={2}>
        <h1 style={{marginLeft: defaultStyleSpacing * 3 }}>
          {t('reset password title')}
        </h1>

        <p style={{marginLeft: defaultStyleSpacing * 3 }}>
           {t('reset password subtitle')}
        </p>

        <Row justifyCenter>
          <img src={ImageBg1} alt={''} className={'image'}/>
        </Row>
      </Box>
      <BaseModal open={showModal}>
        <Card className='b-card-login__content'>
          <CardContent>
            <StepPassword
              user={user}
              loading={loading}
              errorMessage={errorMessage}
              onSubmit={handleSubmitPassword}
            />
          </CardContent>
        </Card>
      </BaseModal>
    </Container>
  )
}

export default ResetPasswordPage
