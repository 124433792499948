import React, { useMemo } from 'react';
import './HomeGantt.scss';
import { Col, Icon, InputDatePicker, InputSelect, InputText, Row } from '../../ui';
import _ from 'lodash';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-gantt/dist/dx-gantt.css';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Accordion, AccordionDetails, AccordionSummary, Badge } from '@mui/material';
import { theme } from '../../../utils';
import { campaignSelectors, setHomeGanttFilter } from '../../../features/campaignSlice';
import { statusesOption } from '../../../pages/admin/campaign/CampaignFilters';
import { campaignStatus, campaignTypes } from '../../../utils/const';
import './../gantt/Gantt.scss';
import { metricSelectors } from '../../../features/metricSlice';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import InputAdornment from '@mui/material/InputAdornment';

export const campaignTypeOpt = {
  ...campaignTypes,
  allTypes: undefined,
};

const inputStyle = {
  color: 'black',
  marginBottom: '0.5rem',
};

const HomeGanttFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ganttFilters = useSelector(campaignSelectors.homeGanttFilter, shallowEqual);
  const businessUnitCampaigns = useSelector(businessUnitSelectors.campaigns, shallowEqual);
  const campaigns = useSelector(metricSelectors.homeGanttCampaigns, shallowEqual);

  const filterCount = useMemo(() => {
    return _.size(_.omitBy(ganttFilters, _.isUndefined));
  }, [ganttFilters]);

  const options = useMemo(() => {
    const campaignStatusesOptions = [
      ..._.map([campaignStatus.FINALIZED, campaignStatus.ACTIVE, campaignStatus.CLOSED], (o) => ({
        _id: o,
        label: t(o),
      })),
      { _id: 'allStatus', label: t('allStatus') },
    ];
    const campaignOptions = [
      ..._(businessUnitCampaigns)
        .filter((cmp) => [campaignStatus.FINALIZED, campaignStatus.ACTIVE, campaignStatus.REJECTED, campaignStatus.CLOSED].includes(cmp.campaignStatus))
        .map((cmp) => ({
          _id: cmp._id,
          label: cmp.name,
        }))
        .orderBy('label', ['asc']),
      { _id: 'allCampaign', label: t('allCampaign') },
    ];
    const campaignTypeOptions = _.map(_.keys(campaignTypeOpt), (o) => ({
      _id: o,
      label: t(`${o}.title`),
    }));
    return {
      status: campaignStatusesOptions,
      campaigns: campaignOptions,
      types: campaignTypeOptions,
    };
  }, [campaigns, campaignTypeOpt, statusesOption]);

  const onChangeValue = (value, filterName) => {
    dispatch(setHomeGanttFilter({ [filterName]: value }));
  };

  const onChangeDate = (newDate, filterName) => {
    dispatch(setHomeGanttFilter({ [filterName]: newDate ? newDate.toMillis() : undefined }));
  };

  const dates = useMemo(() => {
    let from = null,
      to = null;
    if (!!_.get(ganttFilters, 'duration.begin.ts')) {
      from = DateTime.fromMillis(_.get(ganttFilters, 'duration.begin.ts')).toJSDate();
    }
    if (!!_.get(ganttFilters, 'duration.end.ts')) {
      to = DateTime.fromMillis(_.get(ganttFilters, 'duration.end.ts')).toJSDate();
    }

    return {
      from,
      to,
    };
  }, [ganttFilters]);

  return (
    <Col flex>
      <Accordion TransitionProps={{ unmountOnExit: true }} md={12} style={{ borderRadius: '1rem' }} className={'accordion'}>
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <Row fullWidth alignCenter spaceBetween style={{ paddingTop: '0.4rem', paddingBottom: '0.4rem' }}>
            <span className={'accordion-label'}> {t('campaigns')} </span>
            <Badge
              color="secondary"
              badgeContent={filterCount}
              className={'pointer'}
              showZero
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Icon icon={'far fa-filter'} color={theme['primaryColor']} sx={{ padding: '0.1rem' }} style={{ width: '1.5rem', height: '1.5rem' }} />
            </Badge>
          </Row>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Row grid container md={12} spacing={1}>
            <Col item md={3} sm={6} xs={12}>
              <InputDatePicker label={t('from')} value={dates.from} handleChange={(newDate) => onChangeDate(newDate, 'duration.begin.ts')} minDate={DateTime.fromObject({ year: 2020 })} />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputDatePicker label={t('to')} value={dates.to} handleChange={(newDate) => onChangeDate(newDate, 'duration.end.ts')} minDate={DateTime.fromObject({ year: 2020 })} />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputSelect
                variant={'outlined'}
                label={t('campaign')}
                emitValue
                value={_.get(ganttFilters, 'cmpId')}
                onChange={(value) => onChangeValue(value, 'cmpId')}
                options={options.campaigns}
                style={inputStyle}
              />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputSelect
                variant={'outlined'}
                label={t('campaignStatus')}
                emitValue
                value={_.get(ganttFilters, 'campaignStatus')}
                onChange={(value) => onChangeValue(value, 'campaignStatus')}
                options={options.status}
                style={inputStyle}
              />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputSelect
                variant={'outlined'}
                label={t('campaign type')}
                emitValue
                value={_.get(ganttFilters, 'typeof')}
                onChange={(value) => onChangeValue(value, 'typeof')}
                options={options.types}
                style={inputStyle}
              />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputText
                variant={'outlined'}
                label={t('minCompletionRatePercentage')}
                value={_.get(ganttFilters, 'minCompletionRate')}
                onChange={(e) => onChangeValue(e.target.value, 'minCompletionRate')}
                InputProps={{
                  inputProps: {
                    min: Number(0),
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                style={inputStyle}
                type={'number'}
              />
            </Col>
            <Col item md={3} sm={6} xs={12}>
              <InputText
                variant={'outlined'}
                label={t('maxCompletionRatePercentage')}
                value={_.get(ganttFilters, 'maxCompletionRate')}
                onChange={(e) => onChangeValue(e.target.value, 'maxCompletionRate')}
                InputProps={{
                  inputProps: {
                    min: Number(0),
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                style={inputStyle}
                type={'number'}
              />
            </Col>
          </Row>
        </AccordionDetails>
      </Accordion>
    </Col>
  );
};

export default HomeGanttFilters;
