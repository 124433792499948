import { useEffect, useState } from 'react';

import { Card, CardContent } from '@mui/material';
import clsx from 'clsx';

import StepEmail from './../steps/_StepEmail';
import StepPassword from './../steps/_StepPassword';
import StepProfile from './../steps/_StepProfile';
import StepWelcome from './../steps/_StepWelcome';

import './CardSignup.scss';

export const CARD_SIGNUP_STEP_EMAIL = 'CARD_SIGNUP_STEP_EMAIL';
export const CARD_SIGNUP_STEP_WELCOME = 'CARD_SIGNUP_STEP_WELCOME';
export const CARD_SIGNUP_STEP_PASSWORD = 'CARD_SIGNUP_STEP_PASSWORD';
export const CARD_SIGNUP_STEP_PROFILE = 'CARD_SIGNUP_STEP_PROFILE';

const STEPS = [CARD_SIGNUP_STEP_EMAIL, CARD_SIGNUP_STEP_WELCOME, CARD_SIGNUP_STEP_PASSWORD, CARD_SIGNUP_STEP_PROFILE];

const CardSignup = ({ user, step: defaultStep, errorMessage, loading, onSignout, onSubmitEmail, onSubmitPassword, onSubmitProfile, LinkTermsAndConditions, LinkPrivacyPolicy, LinkCookiePolicy }) => {
  const [step, setStep] = useState(defaultStep);

  const handleSubmitWelcome = () => {
    setStep(CARD_SIGNUP_STEP_PROFILE);
  };

  const handleSubmitEmail = (email) => {
    onSubmitEmail(email);
  };

  const handleSubmitPassword = (password) => {
    // console.log({password})
    onSubmitPassword(password);
  };

  const handleSubmitProfile = ({ firstName, lastName, companyRole }) => {
    // console.log({firstName, lastName, companyRole})
    onSubmitProfile({ firstName, lastName, companyRole });
  };

  const handleSignOut = () => {
    onSignout();
  };

  useEffect(() => {
    setStep(defaultStep);
  }, [defaultStep]);

  return (
    <Card className="b-card-login__content">
      <CardContent>
        {!step && null}
        {step === CARD_SIGNUP_STEP_EMAIL && <StepEmail user={user} onSubmit={handleSubmitEmail} errorMessage={errorMessage} />}
        {step === CARD_SIGNUP_STEP_WELCOME && <StepWelcome user={user} onSubmit={handleSubmitWelcome} onSignout={handleSignOut} />}
        {step === CARD_SIGNUP_STEP_PROFILE && (
          <StepProfile
            user={user}
            defaultFirstName={user.firstName}
            defaultLastName={user.lastName}
            onSubmit={handleSubmitProfile}
            LinkTermsAndConditions={LinkTermsAndConditions}
            LinkPrivacyPolicy={LinkPrivacyPolicy}
            LinkCookiePolicy={LinkCookiePolicy}
          />
        )}
        {step === CARD_SIGNUP_STEP_PASSWORD && <StepPassword user={user} loading={loading} errorMessage={errorMessage} onSubmit={handleSubmitPassword} />}{' '}
      </CardContent>
    </Card>
  );
};

export default CardSignup;
