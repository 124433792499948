import { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getParameterFromUrl } from './index';

export function useModal() {
  const [state, setState] = useState(false);

  const handlers = useMemo(
    () => ({
      open: () => {
        setState(true);
      },
      close: () => {
        setState(false);
      },
      toggle: () => {
        setState((prevState) => !prevState);
      },
    }),
    []
  );

  return [state, handlers];
}

export function useQuery() {
  const { search } = useLocation();
  console.log({ search });

  return useMemo(() => getParameterFromUrl(search), [search]);
}
