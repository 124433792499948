import React, { useEffect, useMemo } from 'react';
import { Badge, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { InputSelect, Row } from '../../ui';
import './header.scss';
import { AvatarIcon } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from '../../../utils/const';
import { AppRoutes } from '../../../app/routers';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../features/userSlice';
import variables from '../../../../src/app/spacing.module.scss';
import _ from 'lodash';
import { Icon } from '../../ui/index';
import { businessUnitSelectors, setBusinessUnitSelected } from '../../../features/businessUnitSlice';
import { getBusinessUnitInvitations, getBusinessUnitList } from '../../../api';
import { Image } from '../../ui/image/image';
import { campaignSelectors } from '../../../features/campaignSlice';

export const UserDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetail = useSelector(userSelectors.detail);
  const userRole = useSelector(userSelectors.role);
  const userBusinessUnits = useSelector(businessUnitSelectors.list);
  const userBusinessUnit = useSelector(businessUnitSelectors.selected);
  const campaignCart = useSelector(campaignSelectors.cart);

  const cartCount = useMemo(() => {
    return _.size(_.keys(campaignCart));
  }, [campaignCart]);

  const { account: profile } = userDetail;

  useEffect(() => {
    getBusinessUnitList();
  }, []);

  useEffect(() => {
    if (userBusinessUnits && !_.isEmpty(userBusinessUnits)) {
      getBusinessUnitInvitations();
    }
  }, [userBusinessUnits]);

  const onChangeBusinessUnit = (value) => {
    // console.log({ value });
    const selectedId = value._id;
    const selectedBusinessUnit = userBusinessUnits.find((bu) => bu._id === selectedId);
    // console.log({ selectedBusinessUnit });
    dispatch(setBusinessUnitSelected(selectedBusinessUnit));
  };

  const onClickCart = (e) => {
    e.preventDefault();
    history.push(AppRoutes.cart());
  };

  const showBusinessUnits = userRole === USER_ROLES.BUSINESS_USER && userBusinessUnit && !_.isEmpty(userBusinessUnit);

  return (
    <Row justifyEnd alignCenter container>
      {showBusinessUnits && (
        <Grid md={3} sx={{ margin: '4px' }}>
          <InputSelect
            label={<span style={{ padding: '0.2em' }}>{t('business unit')}</span>}
            options={userBusinessUnits}
            optionLabel={'name'}
            optionValue={'_id'}
            value={userBusinessUnit._id}
            disabled={_.size(userBusinessUnits) <= 1}
            onChange={onChangeBusinessUnit}
            sx={{
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: 'black',
              margin: '0.2rem',
              paddingLeft: '0.2rem',
              paddingRight: '0.2rem',
            }}
            size={'small'}
          />
        </Grid>
      )}
      <Image source={AvatarIcon} path={_.get(userDetail, 'avatar.uri')} className={'bun-avatar'} />
      {!!profile?.email && <Typography mr={variables['sm']}>{profile?.email}</Typography>}
      <Badge
        onClick={onClickCart}
        color="secondary"
        badgeContent={cartCount}
        className={'pointer'}
        invisible
        showZero
        sx={{
          marginLeft: '0.5rem',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Icon icon={'far fa-shopping-bag'} className={cartCount > 0 ? 'secondary' : 'primary'} sx={{ mr: variables['sm'] }} />
        <Typography>{t('cart')}</Typography>
      </Badge>
    </Row>
  );
};
