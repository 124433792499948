import React, { useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import './StepSocial.scss';
import { Button, Col, Icon, InputText, InputTextArea, Row } from '../../ui';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import _ from 'lodash';
import { assignCampaignToBusinessUnit, createCampaign, getCatalogueAndSaveState, getCompanySocials, patchCampaign } from '../../../api';
import { campaignTypes, CATALOGUES_TYPES, facebookId, instagramId, LANDING_PAGE, STEP_BUDGET, STEP_SOCIAL, TOAST } from '../../../utils/const';
import { getCampaignTypeConfig, getFormattedCampaignDataToPatch, renderSocialIcon } from '../../../utils';
import { campaignSelectors, editCampaignSelected, resetCreateCampaign, setCampaignCompletedSteps, setCampaignCurrentStep, setCampaignSelected } from '../../../features/campaignSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { campaignValidations, ERR, standardFormikProps, validateProduct } from '../../../utils/validations';
import { AppRoutes } from '../../../app/routers';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import { UploadImages } from './UploadImages';
import { UploadVideo } from './UploadVideo';
import { InstructionVideo } from './InstructionVideo';
import ProductDetail from './ProductDetail';
import { appSelectors } from '../../../features/appSlice';
import { store } from '../../../app/store';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import CampaignContactType from './CampaignContactType';

const StepSocial = ({ campaignType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCampaign = useSelector(campaignSelectors.selected, shallowEqual);
  const campaignConfig = getCampaignTypeConfig(campaignType);
  const socials = useSelector(appSelectors.catalogueByType(CATALOGUES_TYPES.ACTIVE_CATALOGUE_ENTRY_SOCIAL));
  const socialsPreConf = useSelector(appSelectors.socialsPreConf);
  const selectedCampaignSocial = _.get(selectedCampaign, 'contents.influencerContent.socials', []);
  const [product, setProduct] = useState({
    ..._.get(selectedCampaign, 'product', {}),
    price: {
      ..._.get(selectedCampaign, 'product.price', {}),
      currency: _.get(campaignConfig, 'costPerUnit.currency', {}),
    },
  });

  const [productErrors, setProductErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCatalogueAndSaveState(CATALOGUES_TYPES.SOCIALS);
    getCompanySocials();
  }, []);

  const socialsOptions = useMemo(() => {
    if (campaignType === campaignTypes.PayPerPostEngagement) {
      return socialsPreConf;
    } else {
      return socials;
    }
  }, [socials, socialsPreConf, campaignType]);

  const { handleSubmit, setFieldValue, errors, validateField, validateForm, values } = useFormik({
    initialValues: {
      brief: _.get(selectedCampaign, 'brief'),
      hasCustomContents: _.get(selectedCampaign, 'contents.influencerContent.hasCustomContents', true),
      landingPage: _.get(selectedCampaign, 'advice.external', true) ? LANDING_PAGE.EXTERNAL : LANDING_PAGE.INTERNAL,
      contactType: _.get(selectedCampaign, 'contactType') || null,
      hash: _.get(selectedCampaign, 'advice.hash', ''),
      info: _.get(selectedCampaign, 'contents.influencerContent.info', ''),
      paypalId: _.get(selectedCampaign, 'paypalId', ''),
      socialSelected: selectedCampaign?._id || _.get(selectedCampaign, 'contents.influencerContent.socials') ? _.map(selectedCampaignSocial, '_id') : _.map(socialsOptions, '_id'),
      link: _.get(selectedCampaign, 'advice.url.uri', undefined),
      hasCustomSocials: _.get(selectedCampaign, 'contents.influencerContent.hasCustomSocials', true),
    },
    validationSchema: campaignValidations[campaignType].social,
    ...standardFormikProps,
    onSubmit: async (data) => {
      setLoading(true);
      const state = store.getState();
      const storedCampaign = campaignSelectors.selected(state);
      let campaign;
      if (_.isNil(storedCampaign._id)) {
        campaign = await createCampaign(campaignType);
        // console.log({ campaign });
        campaign = { ...campaign, ...storedCampaign };
        await assignCampaignToBusinessUnit(campaign._id);
      } else {
        campaign = storedCampaign;
      }
      // console.log({ campaign });

      // setTimeout(async () => {
      const cmp = getFormattedCampaignDataToPatch(values, campaign, availableSocial, campaignType, product);
      // console.log({ cmp });
      const campaignToPatch = { ...cmp };
      await patchCampaign(campaign._id, campaignToPatch);
      // }, 200)
      dispatch(setCampaignCurrentStep(STEP_BUDGET));
      dispatch(setCampaignCompletedSteps([STEP_SOCIAL]));
      setLoading(false);
    },
  });

  useEffect(() => {
    if (selectedCampaign?._id && _.get(selectedCampaign, 'contents.influencerContent.socials')) {
      onChangeField(_.map(selectedCampaignSocial, '_id'), 'socialSelected');
    } else {
      onChangeField(_.map(socialsOptions, '_id'), 'socialSelected');
    }
  }, []);

  const onChangeField = async (value, fieldName) => {
    await setFieldValue(fieldName, value);
    await validateField(fieldName);
  };

  const availableSocial = useMemo(() => {
    // console.debug({ socialsPreConf, socialsOptions });
    if (values.hasCustomContents) {
      // onChangeField(
      //   socialsOptions.map((s) => s._id),
      //   'socialSelected'
      // );
      return socialsOptions;
    }
    if (!values.hasCustomContents) {
      // onChangeField(
      //   socialsPreConf.map((s) => s._id),
      //   'socialSelected'
      // );
      return socialsPreConf;
    }
  }, [values.hasCustomContents, selectedCampaign]);

  useEffect(() => {
    // onChangeField(_.map(availableSocial, '_id'), 'socialSelected');
  }, [availableSocial]);

  const handleHasCustomContentsChange = (e) => {
    const value = e.target.value;
    const prev = values.socialSelected;
    if (value) {
      setFieldValue('socialSelected', _.map(socialsOptions, '_id'));
    } else {
      setFieldValue(
        'socialSelected',
        _.filter(prev, (p) => [facebookId, instagramId].includes(p))
      );
      onChangeField('', 'info');
    }
    setFieldValue('hasCustomContents', _.isEqual(value, 'true'));
  };

  const renderDisclaimer = (text) => {
    return (
      <Box p="24px" bgcolor="#E1DDE6" style={{ borderRadius: 16 }}>
        <Typography variant="body2">{t(text)}</Typography>
      </Box>
    );
  };

  const onChangeLink = (e) => {
    const val = e.target.value;
    setFieldValue('link', val);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const index = _.findIndex(values.socialSelected, (s) => _.isEqual(s, value));
    if (index > -1) {
      setFieldValue(
        'socialSelected',
        _.filter(values.socialSelected, (s) => s !== value)
      );
    } else {
      const prevSocial = values.socialSelected;
      setFieldValue('socialSelected', [...prevSocial, value]);
    }
  };
  const handleChangeHasCustomSocial = () => {
    setFieldValue('hasCustomSocials', !values.hasCustomSocials);
  };

  const openSocialConfigurator = (e) => {
    e.preventDefault();
    onBeforeChangePage();
    history.push(AppRoutes.socialConfigurator());
  };

  const onSubmitEntireForm = async (e) => {
    e.preventDefault();
    const errors = await validateForm(values);
    console.debug({ values, errors });
    if ([campaignTypes.PayPerClick, campaignTypes.PayPerPostEngagement, campaignTypes.PayPerContact].includes(campaignType)) {
      _.isEmpty(errors) ? handleSubmit() : toast(translate('check required fields'), TOAST.ERROR);
    }
    if (campaignType === campaignTypes.PayPerSales) {
      let errorsProd = await validateProduct(product);
      const assets = [..._.get(product, 'images', []), ..._.get(product, 'video', [])];
      if (_.isEmpty(assets)) {
        errorsProd.assets = ERR.required;
      }
      console.debug({ errorsProd });
      setProductErrors((prevState) => errorsProd);
      _.isEmpty(errors) && _.isEmpty(errorsProd) ? handleSubmit() : toast(translate('check required fields'), TOAST.ERROR);
    }
  };

  const onBeforeChangePage = () => {
    // console.log({ product });
    const cmp = getFormattedCampaignDataToPatch(values, selectedCampaign, availableSocial, campaignType, product);
    // console.log({ cmp });
    dispatch(editCampaignSelected({ ...cmp }));
  };

  const SocialList = ({ extraSocial }) => {
    const showTip = values.socialSelected.length !== availableSocial.length || (extraSocial && !values.hasCustomSocials);
    return (
      <Box>
        <FormGroup className={_.isEqual(values.hasCustomContents, true) && 'grid'}>
          {availableSocial.map((s) => (
            <FormControlLabel
              key={`social-option-${s._id}`}
              control={<Checkbox onChange={handleChange} />}
              checked={values.socialSelected.includes(s._id)}
              value={s._id}
              label={<span className={'label'}>{renderSocialIcon(s, true)}</span>}
            />
          ))}
        </FormGroup>
        {campaignTypes.PayPerPostEngagement !== campaignType && (
          <>
            {extraSocial && (
              <Row sm={12} style={{ marginTop: 10 }}>
                <FormControlLabel
                  key={`social-option-extra`}
                  control={<Checkbox onChange={handleChangeHasCustomSocial} />}
                  checked={values.hasCustomSocials}
                  value={'extraSocial'}
                  label={<span className={'label-extra'}>{t('allow to use other social')}</span>}
                />
              </Row>
            )}
            {values.hasCustomContents && showTip && <Typography variant="body">{t('deselect social campaign warning')}</Typography>}
          </>
        )}
      </Box>
    );
  };

  const openLandingPageCreator = async () => {
    onBeforeChangePage();
    history.push(AppRoutes.landingPageCreator());
  };

  const onExit = () => {
    dispatch(resetCreateCampaign());
    dispatch(setCampaignSelected({}));
    history.goBack();
  };
  console.log({ values });

  return (
    <div id={'step-social'}>
      <Box mt="16px">
        <Typography variant="h3" mb="8px">
          {t(`step social title`)}
        </Typography>
        <Typography variant="lead" component="p" mb="16px">
          {t(`step social description`)}
        </Typography>
        <InputTextArea
          style={{ borderRadius: 16 }}
          rows="8"
          onChange={(value) => onChangeField(value, 'brief')}
          value={values.brief}
          error={errors.brief}
          helperText={t(errors.brief)}
          placeholder={t('insert brief')}
          maxLength={300}
        />
      </Box>
      <Box mt="16px">
        <Typography variant="h3" mb="8px">
          {t('campaign content')}
        </Typography>
        <Typography variant="lead" component="p" mb="16px">
          {t(`campaign content title`)}
        </Typography>
        <Grid container>
          <Grid item md={6} sm={12}>
            <FormControl component="fieldset">
              <RadioGroup value={values.hasCustomContents} onChange={handleHasCustomContentsChange}>
                <FormControlLabel value={true} control={<Radio />} label={t('customContentNoLabel')} />
                <FormControlLabel value={false} control={<Radio />} label={t('customContentYesLabel')} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={6} sm={12}>
            {_.isEqual(values.hasCustomContents, true) && renderDisclaimer('customContentYesDisclaimer')}
            {_.isEqual(values.hasCustomContents, false) && renderDisclaimer('customContentNoDisclaimer')}
          </Grid>
        </Grid>
      </Box>
      <Box mt="16px">
        {values.hasCustomContents && (
          <Typography variant="h3" mb="8px">
            {t(`select social on active campaign`)}
          </Typography>
        )}
        {_.isEqual(values.hasCustomContents, true) && ( //social default
          <div>
            <SocialList extraSocial={true} />
            <InputTextArea
              style={{ borderRadius: 16 }}
              rows="8"
              onChange={(value) => onChangeField(value, 'info')}
              value={values.info}
              label={t('instructions')}
              error={errors.info}
              helperText={t(errors.info)}
              placeholder={t('insert the instruction')}
            />
            <UploadImages />
            <UploadVideo />
            <InstructionVideo />
          </div>
        )}
        {_.isEqual(values.hasCustomContents, false) && ( //social configurator
          <>
            <SocialList />
            <Row alignEnd>
              <Button onClick={openSocialConfigurator} text={t('open social configurator')} size={'small'} sx={{ borderRadius: '0.8rem', marginTop: '0.8rem' }} />
              <Icon icon={'fab fa-facebook-f'} style={{ height: 30, width: 30 }} className={'primary'} />
              <Icon icon={'fab fa-instagram'} style={{ height: 30, width: 30 }} className={'primary'} />
            </Row>
          </>
        )}
      </Box>
      {!_.isNull(values.hasCustomContents) && campaignType === campaignTypes.PayPerClick && (
        <LandingPage
          onChangeLandingPage={async (v) => {
            await setFieldValue('landingPage', v.target.value);
            await setFieldValue('link', '');
            await validateField('hash');
          }}
          errors={errors}
          values={values}
          onChangeLink={onChangeLink}
          hashError={errors.hash}
          openLandingPageCreator={openLandingPageCreator}
        />
      )}
      {!_.isNull(values.hasCustomContents) && campaignType === campaignTypes.PayPerSales && (
        <ProductDetail
          errors={productErrors}
          setError={setProductErrors}
          hasCustomContents={values.hasCustomContents}
          productValues={product}
          setProductValues={setProduct}
          onOpenPreview={onBeforeChangePage}>
          <InputText
            label={t('paypalId')}
            fullWidth
            error={errors.paypalId}
            helperText={t(errors.paypalId)}
            onChange={(v) => onChangeField(v.target.value, 'paypalId')}
            value={values.paypalId}
            variant={'outlined'}
          />
        </ProductDetail>
      )}
      {!_.isNull(values.hasCustomContents) && campaignType === campaignTypes.PayPerContact && (
        <CampaignContactType errors={errors} hasCustomContents={values.hasCustomContents} values={values} validateField={validateField} setFieldValue={setFieldValue} />
      )}
      <Row spaceAround mt="16px">
        <Button onClick={onExit} variant={'outlined'} text={t('exit')} size={'small'} sx={{ minWidth: '12rem' }} />
        <Button onClick={onSubmitEntireForm} text={t('continue')} size={'small'} sx={{ minWidth: '12rem' }} loading={loading} />
      </Row>
    </div>
  );
};

export default StepSocial;

const LandingPage = ({ onChangeLandingPage, values, onChangeLink, openLandingPageCreator, hashError, errors }) => {
  const { t } = useTranslation();

  const renderLabelLandingPage = (type) => {
    return (
      <span>
        <Typography variant="h6" component="p" sx={{ fontWeight: 'bold' }}>
          {t(`${type} landing page`)}
          {type === LANDING_PAGE.INTERNAL && <span>&nbsp;*&nbsp;</span>}
        </Typography>
        <Typography variant="label1" component="p">
          {t(`${type} landing page subtitle`)}
        </Typography>
      </span>
    );
  };

  return (
    <Box mt="16px">
      <Typography variant="h3" mb="8px">
        {values.hasCustomContents ? '4. ' : '3. '}
        {t('campaign type landing page')}
      </Typography>
      <Grid container>
        <Grid item md={6} sm={12}>
          <FormControl component="fieldset">
            <RadioGroup value={values.landingPage} onChange={onChangeLandingPage}>
              <FormControlLabel value={LANDING_PAGE.EXTERNAL} control={<Radio />} label={renderLabelLandingPage(LANDING_PAGE.EXTERNAL)} />
              <FormControlLabel value={LANDING_PAGE.INTERNAL} control={<Radio />} label={renderLabelLandingPage(LANDING_PAGE.INTERNAL)} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {_.isEqual(values.landingPage, LANDING_PAGE.EXTERNAL) && (
        <InputText label={t('link')} fullWidth onChange={onChangeLink} value={values.link} error={errors.link} helperText={t(errors.link)} style={{ marginTop: 8 }} variant={'outlined'} />
      )}
      {_.isEqual(values.landingPage, LANDING_PAGE.INTERNAL) && (
        <Row justifyStart alignCenter style={{ marginTop: '0.5rem' }}>
          <Button
            onClick={openLandingPageCreator}
            text={t('open landing page creator')}
            size={'small'}
            sx={{
              borderRadius: '0.8rem',
            }}
          />
          {!!hashError && <span style={{ color: 'red', fontSize: '0.8rem', marginLeft: '0.5rem' }}>{t(hashError)}</span>}
        </Row>
      )}
    </Box>
  );
};
