import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {userSelectors} from "../features/userSlice";
import {useHistory} from "react-router-dom";
import {AppRoutes} from "./routers";
import {setLocalEnvironment} from '../features/appSlice';
import {ENVIRONMENTS} from './App';

export const Auth = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  const userToken = useSelector(userSelectors.token)

  const headerUrl = window.location.origin
  console.log({headerUrl})

  useEffect(() => {
    if (headerUrl.includes('localhost')) {
      return
    }
    if (headerUrl.includes('dev-web')) {
      dispatch(setLocalEnvironment(ENVIRONMENTS.DEV));
      return
    }
    if (headerUrl.includes('pre-web')) {
      dispatch(setLocalEnvironment(ENVIRONMENTS.PRE_PROD));
      return
    }
    dispatch(setLocalEnvironment(ENVIRONMENTS.PROD));
  }, [headerUrl])


  useEffect(() => {
    if(history.location.pathname.includes('public') || history.location.pathname.includes('inf')){
      return
    }
    if(history.location.pathname === '/login' && !!userToken) {
      history.replace(AppRoutes.home())
    }
  }, [userToken])

  return (
    <div/>
  )
}
