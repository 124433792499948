import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BaseModal, Button, Row } from '../../../ui';
import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CampaignModalRecapMap.scss';
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../features/userSlice';
import { COORDINATES_DEFAULT } from '../../campaign/StepCategoryMap';
import { setAreasFromGeoJson } from '../../../../utils';
import { getInfluencerHeatmap } from '../../../../api';

export const CampaignModalRecapMap = ({ open, onClose, video, label = 'areas', campaign }) => {
  const { t } = useTranslation();

  return (
    <BaseModal open={open} propStyle={{ minWidth: '85vw', height: '70vh' }}>
      <Card id={'campaign-modal-recap-map'}>
        <Typography variant="h2" style={{ marginBottom: '2rem' }}>
          {t(label)}
        </Typography>

        <CampaignAreas campaign={campaign} />

        <Row justifyEnd style={{ marginTop: '1rem' }}>
          <Button onClick={onClose} variant={'outlined'}>
            {t('close')}
          </Button>
        </Row>
      </Card>
    </BaseModal>
  );
};

export const CampaignAreas = ({ campaign }) => {
  const [center, setCenter] = useState(COORDINATES_DEFAULT);
  const userCoordinates = useSelector(userSelectors.coordinates);
  const [map, setMap] = React.useState(null);
  const [areas, setAreas] = useState([]);
  const [path, setPath] = useState();
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const mapRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      const initialAreas = setAreasFromGeoJson(campaign);
      console.debug({ initialAreas });
      setAreas((prevState) => [...prevState, ...initialAreas]);
    }, 2000);
  }, []);

  useEffect(() => {
    // console.debug({ userCoordinates });
    setCenter(userCoordinates);
    onMapDrag();
  }, [userCoordinates]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBMiSu4bfyKmaJbqN8uI5iXbF-cP-roJGs',
    libraries: ['visualization'],
  });

  const onMapDrag = async (drag) => {
    try {
      // console.log({mapRef})
      // console.log({mapRef: mapRef.current})
      // console.log({bounds: mapRef.current.state.map.getBounds()})
      // console.log({center: _.get(mapRef, 'current.state.map')})
      const northEast = mapRef.current.state.map.getBounds().getNorthEast();
      const southWest = mapRef.current.state.map.getBounds().getSouthWest();
      const marker1 = [northEast.lng(), northEast.lat()];
      const marker2 = [southWest.lng(), northEast.lat()];
      const marker3 = [southWest.lng(), southWest.lat()];
      const marker4 = [northEast.lng(), southWest.lat()];

      const coordinates = [marker1, marker2, marker3, marker4, marker1];
      console.log({ coordinates });

      const infData = await getInfluencerHeatmap(coordinates);
      console.log({ infData });
      if (window.google) {
        const heatData = infData.map((id) => {
          return new window.google.maps.LatLng(id.circles.lat, id.circles.lng);
        });
        // const heatData = [
        //   new window.google.maps.LatLng(Math.random()*50, Math.random()*10),
        // ]
        console.log({ heatData });
      }
    } catch (e) {
      console.log({ errorMap: e });
    }
  };

  const onEdit = useCallback(
    (ref, id) => {
      console.log({ ref, id });
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
          });
        setPath(nextPath);
        console.log({ polygonRef });
        console.log({ nextPath });
        const selectedArea = areas.find((area) => area.id === id);
        const oldAreas = areas.filter((area) => area.id !== id);
        setAreas([
          ...oldAreas,
          {
            ...selectedArea,
            path: nextPath,
          },
        ]);
      }
    },
    [setPath, areas]
  );

  const onLoadPolygon = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      // listenersRef.current.push(
      //   path.addListener("set_at", onEdit),
      //   path.addListener("insert_at", onEdit),
      //   path.addListener("remove_at", onEdit)
      // );
    },
    [onEdit]
  );

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // Clean up refs
  const onUnmountPolygon = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  return (
    <div className={'map-wrapper'}>
      {isLoaded && (
        <GoogleMap mapContainerClassName={'map'} center={center} zoom={12} onLoad={onLoad} onUnmount={onUnmount} ref={mapRef}>
          {areas?.map((area) => {
            return <Polygon path={area.path} options={{ fillColor: area.color }} onUnmount={onUnmountPolygon} />;
          })}
        </GoogleMap>
      )}
    </div>
  );
};
