import React, { memo, useState } from 'react';
import './companyData2.scss';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button, InputFile, InputTextArea, Row } from '../../ui';
import { patchBusinessUnit } from '../../../api';
import { useSelector } from 'react-redux';
import { uploadBusinessUnitLogo } from '../../../api/upload';
import _ from 'lodash';
import { AppRoutes } from '../../../app/routers';
import { CREATE_COMPANY_STEPS } from '../../../utils/const';
import { Image } from '../../ui/image/image';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { isAdminOfBusinessUnit } from '../../../utils';

export const CreateCompanyData2 = memo(({ fromCart }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const [description, setDescription] = useState(businessUnit.description);
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const disableForm = !isAdminOfBusinessUnit();
  const disableSave = !description;

  // useEffect(() => {
  // const avatarPath = _.get(businessUnit, 'avatar.uri', '');
  // if (avatarPath) {
  // const avatarUrl = getUrlImageFromPath(avatarPath)
  // setImage(avatarUrl)
  // }
  // }, [businessUnit]);

  const handleSubmit = async () => {
    setIsLoading(true);
    let avatar = undefined;
    if (image) {
      avatar = await uploadBusinessUnitLogo(image);
    }
    await patchBusinessUnit({ description, avatar });
    if (!fromCart) {
      history.push(`${AppRoutes.createCompany()}/${CREATE_COMPANY_STEPS.BILLING_INFO}`);
    }
    setIsLoading(false);
  };

  const onUploadFile = (url) => {
    setImage(url);
  };

  const avatarPath = _.get(businessUnit, 'avatar.uri', '');
  return (
    <Grid id={'create-company-data2'}>
      {!fromCart && (
        <>
          <Typography variant={'h1'}>{t('company profile')}</Typography>
          <Typography variant={'h6'}>{t('company profile subtitle')}</Typography>
        </>
      )}
      <Grid xs={12}>
        <InputTextArea
          label={t('company description')}
          rows={5}
          name="description"
          value={description}
          inputProps={{ minLength: 3, maxLength: 300 }}
          onChange={setDescription}
          disabled={disableForm}
        />
      </Grid>

      <Grid xs={12} className={'logo-section'}>
        <Typography variant={'subtitle'}>{t('company logo')}</Typography>
        <Row xs={12} alignCenter className={'logo-input-row'}>
          <InputFile disabled={disableForm} onUpload={onUploadFile} editImage={true} className={'logo-input'} maxDimension={2 ** 20} accept={'.png,.jpeg,.jpg,.webp'} />
          <Typography variant={'body2'}>{t('allowed formats')}: jpg, jpeg, png, webp</Typography>
        </Row>
        {image ? <img src={image} className={'company-profile'} /> : <Image path={avatarPath} className={'company-profile'} />}

        <Typography variant={'body2'}>{t('company profile logo description')}</Typography>
      </Grid>

      {fromCart ? (
        <Row alignCenter justifyCenter>
          <Button text={t('edit')} size={'small'} variant={'outlined'} onClick={handleSubmit} loading={isLoading} className={'save-button'} disabled={disableForm || disableSave} />
        </Row>
      ) : (
        <Button text={t('save')} onClick={handleSubmit} loading={isLoading} className={'save-button'} disabled={disableForm || disableSave} />
      )}
    </Grid>
  );
});
