import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import { BaseModal, Button, Col, InputDateTimePicker, InputText, InputTextArea, Row } from '../../ui';
import {Card, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {useFormik} from "formik";
import './CampaignModalDismiss.scss'
import _ from "lodash";
import {dismissModalSchema, payPerClick} from "../../../utils/validations";
import { FIELD_NAME_EMAIL } from '../steps/_StepEmail';
import { DateTime } from 'luxon';
import { fromMillisToDate } from '../../../utils';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../features/appSlice';


export const CampaignModalDismiss = ({open, onClose, onConfirm, campaign}) => {

  console.log({campaign})
  const appConfig = useSelector(appSelectors.config)
  const minPercentage = _.get(appConfig, 'campaign.minPercentageToExtend', 0)
  console.log({appConfig})
  const disableForm = minPercentage > campaign.completionRate
  const endCampaign = _.get(campaign, 'duration.end.ts')
  const { t } = useTranslation()
  const [budget, setBudget] = useState(0)
  const [date, setDate] = useState(DateTime.fromMillis(endCampaign).plus({ days: 0 }))
  const [errorMessage, setErrorMessage] = useState('')

  const onChangeBudget = (e) => {
    const newValue = Number(e.target.value)
    setBudget(newValue)
  }

  const disableButton = disableForm || true

  return (
    <BaseModal open={open}>
      <Card id={'campaign-modal-extend'}>
        <Typography variant='h3' mb="1rem">{t('extend campaign title')}</Typography>
        <Typography variant='body2' mb="1.5rem">{t('extend campaign description')}</Typography>
        <Typography variant='h4' mb="1rem">{t('extend campaign budget title')}</Typography>
        <Typography variant='body2' mb="1.5rem">{t('extend campaign budget description', {budget: campaign.budget.total.value})}</Typography>
        <InputText
          label={t('extend campaign budget label')}
          type="number"
          onChange={onChangeBudget}
          value={budget}
          error={!!errorMessage}
          helperText={errorMessage}
        />
        <Typography variant='h4' mt={'1.5rem'} mb={'1rem'}>{t('select a date')}</Typography>
        <InputDateTimePicker
          value={date}
          handleChange={async (newDate) => {
            setDate(newDate)
          }}
          fullWidth
          // error={errors.dateFrom}
          // helperText={dateError}
          minDate={DateTime.fromMillis(endCampaign).plus({ days: 0 })}
        />

        {disableForm && <Row mt={'1rem'}>
          <Typography variant='error' mb="1.5rem">{t('extend campaign disclaimer', {percentage: minPercentage * 100})}</Typography>
        </Row>}
        <Row spaceBetween mt={'1rem'}>
          <Button text={t('undo')} variant={'outlined'} onClick={onClose} />
          <Button text={t('extend')} disabled={disableButton}/>
        </Row>
      </Card>
    </BaseModal>
  )
}
