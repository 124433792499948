import { createSlice } from '@reduxjs/toolkit'
import _ , { get, isEmpty } from 'lodash'
import {USER_ROLES} from "../utils/const";
import {store} from '../app/store';
import {userSelectors} from './userSlice';

const initialState = {
  selected: {},
  businessUnitList: [],
  businessUnitInvitations: [],
  campaigns: []
}

export const businessUnitSlice = createSlice({
  name: 'businessUnit',
  initialState,
  reducers: {
    setBusinessUnitSelected: (state, action ) => {
      state.selected = action.payload
    },
    setBusinessUnitCampaigns: (state, action) => {
      state.campaigns = action.payload
    },
    setUserBusinessUnitList: (state, action) => {
      state.businessUnitList = action.payload
    },
    setBusinessUnitInvitations: (state, action) => {
      state.businessUnitInvitations = action.payload
    },
  },
})

export const {
  setBusinessUnitSelected,
  setUserBusinessUnitList,
  setBusinessUnitCampaigns,
  setBusinessUnitInvitations,
} = businessUnitSlice.actions

export default businessUnitSlice.reducer

export const businessUnitSelectors = {
  selected: (state) => get(state, 'businessUnit.selected') || {},
  list: (state) => get(state, 'businessUnit.businessUnitList') || [],
  selectedInvitations: (state) => get(state, 'businessUnit.businessUnitInvitations') || [],
  campaigns: (state) => get(state, 'businessUnit.campaigns') || [],
}

