import React, { useState } from 'react';
import './companyData4.scss';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Button, Col, InputRadio } from '../../ui';
import { patchBusinessUnit } from '../../../api';
import { ModalBankTransfer } from './modalBankTransfer';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { businessUnitSelectors } from '../../../features/businessUnitSlice';
import { isAdminOfBusinessUnit } from '../../../utils';

export const PAYMENT_TYPES = {
  CREDIT_CARD: 'credit-card',
  PAYPAL: 'paypal',
  BANK_TRANSFER: 'bank-transfer',
};

export const CreateCompanyData4 = () => {
  const { t } = useTranslation();
  const businessUnit = useSelector(businessUnitSelectors.selected);
  const [payment, setPayment] = useState(_.get(businessUnit, 'payments.default', ''));
  const [showModalBankTransfer, setShowModalBankTransfer] = useState(false);
  const disableForm = !isAdminOfBusinessUnit();

  const handleSubmit = async () => {
    await patchBusinessUnit({ payments: { default: payment } });
  };

  const onPressCheck = (pressed) => {
    setPayment(pressed);
  };

  const onPressBankTransfer = () => {
    setShowModalBankTransfer(true);
  };

  return (
    <Grid id={'create-company-data4'}>
      <ModalBankTransfer open={showModalBankTransfer} onClose={() => setShowModalBankTransfer(false)} onConfirm={() => onPressCheck(PAYMENT_TYPES.BANK_TRANSFER)} />
      <Typography variant={'h1'}>{t('payment information')}</Typography>
      <Typography variant={'h6'}>{t('company payment information subtitle')}</Typography>
      <Col xs={11} md={6} className={'grid-radio'}>
        <Typography variant={'subtitle'}>{t('payment method')}</Typography>
        <InputRadio disabled={disableForm} label={t('credit card')} onChange={() => onPressCheck(PAYMENT_TYPES.CREDIT_CARD)} checked={payment === PAYMENT_TYPES.CREDIT_CARD} />
        <InputRadio disabled={disableForm} label={t('paypal')} onChange={() => onPressCheck(PAYMENT_TYPES.PAYPAL)} checked={payment === PAYMENT_TYPES.PAYPAL} />
        <InputRadio disabled={disableForm} label={t('bank transfer')} onChange={onPressBankTransfer} checked={payment === PAYMENT_TYPES.BANK_TRANSFER} />
      </Col>
      <Button disabled={disableForm} text={t('save')} onClick={handleSubmit} className={'save-button'} />
    </Grid>
  );
};
