import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material'
import './checkbox.scss'
import clsx from 'clsx'

export const Checkbox = ({ label, checked, ...props }) => (
  <FormControlLabel
    className={clsx('checkbox')}
    checked={checked}
    control={<MuiCheckbox {...props} />}
    label={label || ''}
  />
)
