import { createSlice } from '@reduxjs/toolkit';
import _, { get } from 'lodash';
import { defaultListObj } from '../utils/const';

const initialState = {
  selected: {},
  selectedType: null,
  list: defaultListObj,
  selectedStep: null,
  completedSteps: [],
  cart: {},
  filters: {},
  page: 0,
  areas: [],
  homeGanttFilter: {},
  homePerformanceFilter: {},
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignSelected: (state, action) => {
      state.selected = action.payload;
      if (_.isEmpty(action.payload)) {
        state.areas = [];
      }
    },
    setHomeGanttFilter: (state, action) => {
      state.homeGanttFilter = {
        ...state.homeGanttFilter,
        ...action.payload,
      };
    },
    resetGanttFilter: (state, action) => {
      state.homeGanttFilter = {};
    },
    setHomePerformanceFilter: (state, action) => {
      state.homePerformanceFilter = {
        ...state.homePerformanceFilter,
        ...action.payload,
      };
    },
    setCart: (state, action) => {
      state.cart = { ...action.payload };
    },
    addCampaignToCard: (state, action) => {
      console.log({ campaign: action.payload });
      const { _id } = action.payload;
      console.log({ _id });
      if (_id) {
        state.cart[_id] = action.payload;
      }
    },
    removeCampaignToCard: (state, action) => {
      delete state.cart[action.payload];
    },
    setCampaignFilters: (state, action) => {
      state.page = 0;
      state.filters = { ...state.filters, ...action.payload };
    },
    setCampaignList: (state, action) => {
      state.list = action.payload;
    },
    setCampaignPagination: (state, action) => {
      state.page = action.payload;
    },
    editCampaignSelected: (state, action) => {
      state.selected = {
        ...state.selected,
        ...action.payload,
      };
    },
    setCampaignSelectedType: (state, action) => {
      state.selectedType = action.payload;
    },
    setCampaignCurrentStep: (state, action) => {
      state.selectedStep = action.payload;
    },
    setCampaignCompletedSteps: (state, action) => {
      state.completedSteps = action.payload;
    },
    resetCreateCampaign: (state, action) => {
      state.selectedStep = null;
      state.completedSteps = [];
    },
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
  },
});

export const {
  setCampaignSelected,
  editCampaignSelected,
  setCampaignSelectedType,
  setCampaignCurrentStep,
  setCampaignCompletedSteps,
  setCampaignFilters,
  setCampaignList,
  setCampaignPagination,
  resetCreateCampaign,
  setAreas,
  addCampaignToCard,
  removeCampaignToCard,
  setCart,
  setHomeGanttFilter,
  setHomePerformanceFilter,
  resetGanttFilter,
} = campaignSlice.actions;

export default campaignSlice.reducer;

export const campaignSelectors = {
  selected: (state) => getSelectedCampaign(state),
  selectedType: (state) => get(state, 'campaign.selectedType') || null,
  currentStep: (state) => get(state, `campaign.selectedStep`),
  completedSteps: (state) => get(state, `campaign.completedSteps`),
  filters: (state) => _.get(state, 'campaign.filters') || {},
  pagination: (state) => _.get(state, 'campaign.page') || 0,
  list: (state) => _.get(state, 'campaign.list') || defaultListObj,
  areas: (state) => _.get(state, 'campaign.areas') || {},
  cart: (state) => _.get(state, 'campaign.cart') || {},
  homeGanttFilter: (state) => _.get(state, 'campaign.homeGanttFilter') || {},
  homePerformanceFilter: (state) => _.get(state, 'campaign.homePerformanceFilter') || {},
  campaignCardById: (state, id) => {
    console.log({ state, id });
    return state.cart[id];
  },
};

// Aggiungo gli elementi per gli sotto oggetti, in modo da facilitarne l'utilizzo
export const getSelectedCampaign = (state) => {
  return {
    ...baseCampaign,
    ...get(state, 'campaign.selected'),
    contents: {
      ...baseCampaign.contents,
      ...get(state, 'campaign.selected.contents'),
      influencerContent: {
        ...baseCampaign.contents.influencerContent,
        ...get(state, 'campaign.selected.contents.influencerContent'),
      },
      businessUnitContent: {
        ...baseCampaign.contents.businessUnitContent,
        ...get(state, 'campaign.selected.contents.businessUnitContent'),
      },
    },
  };
};

const baseCampaign = {
  contents: {
    influencerContent: {
      images: [],
    },
    businessUnitContent: {},
  },
  category: {},
};
