import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Container } from '../components/ui';
import { useTranslation } from 'react-i18next';
import {
  CreateCompanyData1,
  CreateCompanyData2,
  CreateCompanyData3,
  CreateCompanyData4,
  CreateCompanyData5,
  CreateCompanyData6,
  CreateCompanySidebar,
} from '../components/layout/createCompany';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { CREATE_COMPANY_STEPS } from '../utils/const';
import { getBusinessUnit, getMe } from '../api';

export const CreateCompanyPage = () => {
  const { t } = useTranslation();
  const searchParams = useParams();
  const { path } = useRouteMatch();

  useEffect(() => {
    getMe();
    getBusinessUnit();
  }, []);

  const submit = async (step, data) => {
    // const patchedBu = await apiSubmit(step, data)
    // console.log('submit', { data, patchedBu })
    // if (patchedBu) {
    //   hydrateFromApiResponse(patchedBu as BusinessUnit)
    // }
    // goToNext()
  };

  return (
    <Container>
      <Grid container>
        <CreateCompanySidebar active={'payment-information'} />
        <Grid item md={9} xs={12} style={{ paddingTop: '1.5rem' }}>
          <Switch>
            <Route
              exact
              path={`/create-company/${CREATE_COMPANY_STEPS.COMPANY_DATA}`}
              component={CreateCompanyData1}
            />
            <Route
              exact
              path={`/create-company/${CREATE_COMPANY_STEPS.COMPANY_PROFILE}`}
              component={CreateCompanyData2}
            />
            <Route
              exact
              path={`/create-company/${CREATE_COMPANY_STEPS.BILLING_INFO}`}
              component={CreateCompanyData3}
            />
            <Route
              exact
              path={`/create-company/${CREATE_COMPANY_STEPS.PAYMENT_INFO}`}
              component={CreateCompanyData4}
            />
            <Route
              exact
              path={`/create-company/${CREATE_COMPANY_STEPS.CONTACTS}`}
              component={CreateCompanyData5}
            />
            <Route
              exact
              path={`/create-company/${CREATE_COMPANY_STEPS.REFERENTS}`}
              component={CreateCompanyData6}
            />
            <Redirect
              path={`${path.replace(/\/$/, '')}`}
              to={path.concat(`/${CREATE_COMPANY_STEPS.COMPANY_DATA}`)}
            />
          </Switch>
        </Grid>
      </Grid>
    </Container>
  );
};
