import React, {useEffect, useState} from 'react'
import './LandingContent.scss'
import {Row, Col, Button} from "../../ui";
import {Typography} from "@mui/material";
import {theme} from './../../../utils/index'
import _  from 'lodash'
import {useTranslation} from "react-i18next";
import {Image} from "../../ui/image/image";
import {getUrlImageFromPath} from "../../../api/upload";

export const LandingContent = ({content}) => {

  const { t } = useTranslation()

  const { logo, background, title, description1, description2, description3, callToActionLabel, callToAction} = content
  console.log({logo, background})
  const [localUri, setLocalUri] = useState(_.get(background, 'uri'))

  useEffect(() => {
    if (background?.uri && !background?.toUpload) {
      getUrlImageFromPath(background.uri).then(newUri => {
        console.log({newUri})
        if (newUri) {
          setLocalUri(old => newUri)
        }
      })
    }
    if(background?.toUpload) {
      setLocalUri(old => background.uri)
    }
  }, [background])


  let mainStyle = !_.isEmpty(background) ? {backgroundImage: `url(${localUri})`} : { backgroundColor: '#D7D7D7' }

  const Text = ({text}) => {
    console.log({text})
    return (
      <div
        className={'text'}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }

  const onClickCallToAction = () => {
    window.open(callToAction, '_blank')
  }

  return (
    <div id={'landing-content'}>
      <Col container className={'content'} style={mainStyle}>
        <Row alignStart>
          {logo?.uri && <Image path={logo?.uri} uri={logo?.uri} source={logo?.uri} className={'logo-image'} alt={'logo'}/>}
        </Row>
        <Row justifyCenter alignCenter flex>
          <Typography variant={'h2'} style={{color: theme['white']}}>
            {title}
          </Typography>
        </Row>
        <Row alignCenter justifyCenter flex>
          <Button text={t(callToActionLabel || 'call to action')} onClick={onClickCallToAction}/>
        </Row>
        <Row spaceBetween style={{padding: '1rem'}}>
          <Text text={description1}/>
          <Text text={description2}/>
          <Text text={description3}/>
        </Row>
      </Col>
    </div>
  )
}
