import React, { useCallback, useEffect, useRef, useState } from 'react';
import './StepCategoryMap.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, Icon, Row } from '../../ui';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { checkPolygonsValidity, getInfluencerHeatmap } from '../../../api';
import { GoogleMap, HeatmapLayer, Polygon, useJsApiLoader } from '@react-google-maps/api';
import _ from 'lodash';
import '@googlemaps/react-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { campaignSelectors, setAreas, setAreas as setAreasToState } from '../../../features/campaignSlice';
import { getRandomNumber, setAreasFromGeoJson } from '../../../utils';
import { userSelectors } from '../../../features/userSlice';

export const COORDINATES_DEFAULT = { lat: 45.4642, lng: 9.19 };
const containerStyle = {
  // width: '100%', height: '100%',
};

export const StepCategoryMap = ({ onSetMapResponse }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaign = useSelector(campaignSelectors.selected);
  const userCoordinates = useSelector(userSelectors.coordinates);
  const [center, setCenter] = useState(COORDINATES_DEFAULT);
  const [map, setMap] = React.useState(null);
  const [areas, setAreas] = useState([]);
  const [path, setPath] = useState();
  const [heatMap, setHeatMap] = useState([]);
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const mapRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      const initialAreas = setAreasFromGeoJson(campaign);
      console.debug({ initialAreas });
      setAreas((prevState) => [...prevState, ...initialAreas]);
    }, 2000);
  }, []);

  useEffect(() => {
    // console.debug({ userCoordinates });
    setCenter(userCoordinates);
    onMapDrag();
  }, [userCoordinates]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBMiSu4bfyKmaJbqN8uI5iXbF-cP-roJGs',
    libraries: ['visualization'],
  });
  // console.log({ isLoaded, loadError });

  useEffect(() => {
    checkPolygonsValidity(areas)
      .then((validity) => {
        console.debug('validity', { validity });
        if (validity) {
          const isValid = validity.semaphore === 'green' || validity.semaphore === 'yellow';
          dispatch(setAreasToState({ isValid, areas, semaphore: validity.semaphore }));
          onSetMapResponse(isValid);
        }
      })
      .catch((errorPolygon) => {
        console.log({ errorPolygon });
        dispatch(setAreasToState({ isValid: false, areas, semaphore: 'red' }));
      });
  }, [areas]);

  // Call setPath with new edited path
  const onEdit = useCallback(
    (ref, id) => {
      console.log({ ref, id });
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
          });
        setPath(nextPath);
        console.log({ polygonRef });
        console.log({ nextPath });
        const selectedArea = areas.find((area) => area.id === id);
        const oldAreas = areas.filter((area) => area.id !== id);
        setAreas([
          ...oldAreas,
          {
            ...selectedArea,
            path: nextPath,
          },
        ]);
      }
    },
    [setPath, areas]
  );

  // Bind refs to current Polygon and listeners
  const onLoadPolygon = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      // listenersRef.current.push(
      //   path.addListener("set_at", onEdit),
      //   path.addListener("insert_at", onEdit),
      //   path.addListener("remove_at", onEdit)
      // );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmountPolygon = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onClickAddArea = (event) => {
    console.log({ event });

    try {
      let latCenter = mapRef.current.state.map.center.lat();
      let lngCenter = mapRef.current.state.map.center.lng();
      if (event) {
        latCenter = event.latLng.lat();
        lngCenter = event.latLng.lng();
      }
      // const center = _.get(mapRef, 'current.state.map.center')
      // console.log({center})
      // console.log({center: _.get(mapRef, 'current.state.map')})
      // console.log({state: _.get(mapRef, 'current.state')})
      // console.log({center: center.lat()})
      // console.log({center: center.lng()})
      const mapZoom = _.get(mapRef, 'current.state.map.zoom');
      const newZoom = mapZoom < 5.5 ? 5.5 : mapZoom;
      const randomColor = `rgb(${getRandomNumber()},${getRandomNumber()},${getRandomNumber()})`;
      console.debug({ newZoom, latCenter, lngCenter, mapZoom });
      const moltiplicator = (1 / Math.pow(newZoom, 8)) * 100000000;

      const newAreas = [
        ...areas,
        {
          path: [
            {
              lat: latCenter - 0.05 * moltiplicator,
              lng: lngCenter,
            },
            {
              lat: latCenter - Math.sqrt(0.00125) * moltiplicator,
              lng: lngCenter + Math.sqrt(0.00125) * moltiplicator,
            },
            {
              lat: latCenter,
              lng: lngCenter + 0.05 * moltiplicator,
            },
            {
              lat: latCenter + Math.sqrt(0.00125) * moltiplicator,
              lng: lngCenter + Math.sqrt(0.00125) * moltiplicator,
            },
            {
              lat: latCenter + 0.05 * moltiplicator,
              lng: lngCenter,
            },
            {
              lat: latCenter + Math.sqrt(0.00125) * moltiplicator,
              lng: lngCenter - Math.sqrt(0.00125) * moltiplicator,
            },
            {
              lat: latCenter,
              lng: lngCenter - 0.05 * moltiplicator,
            },
            {
              lat: latCenter - Math.sqrt(0.00125) * moltiplicator,
              lng: lngCenter - Math.sqrt(0.00125) * moltiplicator,
            },
          ],
          color: randomColor,
          // color: `#${Math.random().toFixed(2)}`,
          id: Math.random().toFixed(4).toString(),
        },
      ];
      setAreas(newAreas);
    } catch (e) {
      console.log({ errorAddArea: e });
    }
  };

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  }, []);

  const onClickDeleteArea = useCallback(
    (areaToDelete) => {
      setAreas((prevState) => _.filter(prevState, (area) => area.id !== areaToDelete.id));
    },
    [areas]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMapDrag = async (drag) => {
    try {
      // console.log({mapRef})
      // console.log({mapRef: mapRef.current})
      // console.log({bounds: mapRef.current.state.map.getBounds()})
      // console.log({center: _.get(mapRef, 'current.state.map')})
      const northEast = mapRef.current.state.map.getBounds().getNorthEast();
      const southWest = mapRef.current.state.map.getBounds().getSouthWest();
      const marker1 = [northEast.lng(), northEast.lat()];
      const marker2 = [southWest.lng(), northEast.lat()];
      const marker3 = [southWest.lng(), southWest.lat()];
      const marker4 = [northEast.lng(), southWest.lat()];

      const coordinates = [marker1, marker2, marker3, marker4, marker1];
      console.log({ coordinates });

      const infData = await getInfluencerHeatmap(coordinates);
      console.log({ infData });
      if (window.google) {
        const heatData = infData.map((id) => {
          return new window.google.maps.LatLng(id.circles.lat, id.circles.lng);
        });
        // const heatData = [
        //   new window.google.maps.LatLng(Math.random()*50, Math.random()*10),
        // ]
        console.log({ heatData });
        setHeatMap(heatData);
      }
    } catch (e) {
      console.log({ errorMap: e });
    }
  };

  return (
    <div id={'step-category-map'}>
      <div className={'title'}>
        <Box mt="16px">
          <Typography variant="h3" mb="8px">
            {t(`step category title 3`)}
            <Tooltip title={t('step category title 3 tooltip')}>
              <IconButton>
                <Icon icon={'fal fa-info-circle'} />
              </IconButton>
            </Tooltip>
          </Typography>
        </Box>
      </div>
      <div className={'map-wrapper'}>
        {isLoaded && (
          <GoogleMap
            mapContainerClassName={'map'}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}
            ref={mapRef}
            onDrag={onMapDrag}
            onZoomChanged={onMapDrag}
            onResize={onMapDrag}
            onClick={onClickAddArea}>
            {areas?.map((area) => {
              return (
                <Polygon
                  editable
                  draggable
                  path={area.path}
                  options={{ fillColor: area.color }}
                  // Event used when manipulating and adding points
                  onMouseUp={(ref) => onEdit(ref, area.id)}
                  // Event used when dragging the whole Polygon
                  onDragEnd={(ref) => onEdit(ref, area.id)}
                  onLoad={onLoadPolygon}
                  onUnmount={onUnmountPolygon}
                />
              );
            })}
            {/*<Marker position={{ lat: 52.50104268487331, lng: 13.342933784855674 }}/>*/}
            {/*<Marker position={{ lat: 52.50104268487331, lng: 13.474769722355674 }}/>*/}
            {/*<Marker position={{ lat: 52.567870767372725, lng: 13.342933784855674 }}/>*/}
            {/*<Marker position={{ lat: 52.567870767372725, lng: 13.474769722355674 }}/>*/}
            <CustomHeatMap heatData={heatMap} />
          </GoogleMap>
        )}
        <Col className={'map-controller'}>
          <Box mt="1rem">
            <Button style={{ width: '11rem', lineHeight: 1 }} onClick={() => onClickAddArea()}>
              {t('add new area')}
            </Button>
            <Col flexStart style={{ marginTop: '0.5rem' }}>
              {areas.map((area, index) => {
                return (
                  <Row key={area.id} alignStart sm={4} md={12} xs={4} className={'chip-container'}>
                    <Row sm={12} className={'chip'} spaceBetween alignCenter>
                      <Typography>{index}</Typography>
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          backgroundColor: area.color,
                        }}
                      />
                      <Icon icon={'fas fa-trash-alt'} className={'primary'} onClick={() => onClickDeleteArea(area)} />
                    </Row>
                  </Row>
                );
              })}
            </Col>
          </Box>
          <Row flex={1} />
          <RenderSemaphore />
        </Col>
      </div>
    </div>
  );
};

const CustomHeatMap = ({ heatData }) => {
  console.log({ heatData });
  const onLoadHeat = (heatmapLayer) => {
    console.log('HeatmapLayer onLoad heatmapLayer: ', heatmapLayer);
  };

  const onUnmountHeat = (heatmapLayer) => {
    console.log('HeatmapLayer onUnmount heatmapLayer: ', heatmapLayer);
  };

  return (
    <HeatmapLayer
      // optional
      onLoad={onLoadHeat}
      // optional
      onUnmount={onUnmountHeat}
      // required
      data={heatData}
      options={{
        radius: 8,
      }}
    />
  );
};

const RenderSemaphore = () => {
  const { t } = useTranslation();
  const stateArea = useSelector(campaignSelectors.areas);
  const dispatch = useDispatch();
  console.log({ stateArea });

  useEffect(() => {
    dispatch(setAreas({}));
  }, []);

  const semaphore = _.get(stateArea, 'semaphore', '');
  // console.debug(semaphore);

  if (!semaphore) {
    return <div />;
  }
  return (
    <Row className={'sem-texts'} alignStart>
      <Row noFlex alignCenter>
        <Col className={'semaphore-mew'}>
          <div className={`sem-circle red-transparent ${semaphore === 'red' && 'red'}`} />
          <div className={`sem-circle yellow-transparent ${semaphore === 'yellow' && 'yellow'}`} />
          <div className={`sem-circle green-transparent ${semaphore === 'green' && 'green'}`} />
        </Col>
      </Row>
      <Col flex={1}>
        <Typography variant="h3" mb="8px" mt="0.2rem">
          {t(`areas semaphore ${semaphore}`)}
        </Typography>
        <Typography variant="body2" mb="8px">
          {t(`areas semaphore ${semaphore} description`)}
        </Typography>
      </Col>
    </Row>
  );
};
