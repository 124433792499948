import { useTranslation } from "react-i18next";
import { Box } from '@mui/material'
import ModalSignup from './../../../components/layout/modals/signupModal'
import {Container, Row} from "../../../components/ui";
import React, { useEffect } from "react";
import {userSelectors} from "../../../features/userSlice";
import {useSelector} from "react-redux";
import './SignupComplete.scss'
import {ImageBg1} from "../../../assets";
import {useHistory, useLocation} from 'react-router-dom'

const PageSignupComplete = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useSelector(userSelectors.detail)
  const { state } = useLocation()

  useEffect(() => {
    console.log({user, history, state})
  }, [])

  return (
    <Container id={'signup-complete'}>
      <Box pl={4} pr={4}>
        <h1> {t('signup complete text 1')} </h1>
        <p><strong>{t('signup complete text 2')}</strong></p>
      </Box>
      <Row justifyCenter>
        <img src={ImageBg1} alt={''} className={'image'}/>
      </Row>
      <ModalSignup locationState={state}/>
    </Container>
  )
}

export default PageSignupComplete
