import React, { memo, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import clsx from 'clsx';
import InputAdornment from '@mui/material/InputAdornment';
import './inputTextArea.scss';

export const InputTextArea = ({ defaultValue, className, value, onChange, InputProps, maxLength, disabled, ...props }) => {
  const [charCount, setCharCount] = useState((value || '').length);
  const ref = useRef();

  const handleOnChange = (evt) => {
    const newCharCount = ~~evt?.currentTarget?.value?.length;
    // if (newCharCount > maxLength) {
    //   return
    // }
    setCharCount(newCharCount);
    if (typeof onChange === 'function') {
      onChange(evt?.currentTarget?.value);
    }
  };

  return (
    <TextField
      ref={ref}
      {...props}
      className={clsx('e-input-textarea', className)}
      multiline
      InputLabelProps={{ shrink: true }}
      color="primary"
      defaultValue={defaultValue}
      value={value}
      onChange={handleOnChange}
      InputProps={{
        ...InputProps,
        disabled,
        endAdornment: (
          <InputAdornment
            position="end"
            aria-hidden="true"
            className={clsx([
              'e-input-charcounter',
              {
                'is-success': ref.current?.querySelector('textarea')?.validity?.valid,
                'is-error': charCount > maxLength,
              },
            ])}>
            {charCount}
          </InputAdornment>
        ),
      }}
    />
  );
};
