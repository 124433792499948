import React from "react";
import {Button, Icon, InputPassword, Row} from '../../ui'
import { useTranslation } from "react-i18next";
import {defaultStyleSpacing} from "../../../theme";
import {Typography, Box} from "@mui/material";
import spacing from './../../../../src/app/spacing.module.scss'
export const FIELD_NAME_PASSWORD = 'password'

const StepPasswordLogin = ({
  email,
  loading,
  errorMessage,
  onChange: handleChange,
  onClearEmail: handleClearEmail,
  onSubmit,
  ForgotPasswordLink
}) => {
  const { t } = useTranslation()
  const handleSubmit = (e) => {
    e.preventDefault()
    const value = e.target[FIELD_NAME_PASSWORD].value
    onSubmit(value)
  }

  return (
    <form onSubmit={handleSubmit}>
      {email && <Row spaceBetween style={{marginBottom: defaultStyleSpacing * 2}}>
        {email}
        <Icon
          icon={'fas pencil-alt'}
          className={'primary'}
          onClick={handleClearEmail}
        />
      </Row>}
      <InputPassword
        fullWidth
        label={t(FIELD_NAME_PASSWORD)}
        name={FIELD_NAME_PASSWORD}
        onChange={handleChange}
        error={!!errorMessage}
      />
      {errorMessage && <p className='b-card-login__warn'>{t(errorMessage)}</p>}
      <Box sx={{mb: spacing['sm'], mt: spacing['sm']}}>
        <Typography variant={'label'} className='b-card-login__warn'>
          {t('forgot password')}{' '}
          {ForgotPasswordLink}
        </Typography>
      </Box>
      <Button
        type='submit'
        fullWidth
        loading={loading}
        text={t('login')}
      />
    </form>
  )
}

export default StepPasswordLogin
