import React from 'react';
import { Card, Link, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import StepEmail from '../steps/_StepEmail';
import './CardLogin.scss';

import { SocialButtons } from '../socialButtons';
import StepPasswordLogin from '../steps/_StepPasswordLogin';

export const CARD_LOGIN_STEP_CHECK_ACCOUNT = 'CARD_LOGIN_STEP_CHECK_ACCOUNT';
export const CARD_LOGIN_STEP_SIGN_IN = 'CARD_LOGIN_STEP_SIGN_IN';

export const CARD_LOGIN_VARIANT_SIGN_UP = 'CARD_LOGIN_VARIANT_SIGN_UP';
export const CARD_LOGIN_VARIANT_SIGN_IN = 'CARD_LOGIN_VARIANT_SIGN_IN';

const STEPS = [CARD_LOGIN_STEP_CHECK_ACCOUNT, CARD_LOGIN_STEP_SIGN_IN];

const CardLogin = ({
  email,
  onChangeEmail,
  onChangePassword,
  onSubmitEmail,
  onSubmitPassword,
  step,
  errorMessage,
  loading,
  onClickLoginSocial,
  onClearEmail,
  onClickTipLink,
  variant,
  ForgotPasswordLink,
}) => {
  const { t } = useTranslation();
  const stepEmailButtonText =
    (variant === CARD_LOGIN_VARIANT_SIGN_IN && errorMessage) ||
    variant === CARD_LOGIN_VARIANT_SIGN_UP
      ? 'register'
      : 'login';

  const handleChangeEmail = (value) => {
    onChangeEmail(value);
  };

  const handleClearEmail = (e) => {
    onClearEmail();
  };

  const handleSubmitEmail = (value) => {
    onSubmitEmail(value);
  };

  const handleSubmitPassword = (password) => {
    onSubmitPassword({ email, password });
  };

  const handleClickLoginSocial = (service) => {
    onClickLoginSocial(service);
  };

  const handleClickTipLink = (e) => {
    onClickTipLink();
  };

  return (
    <Card className={clsx('b-card-login')}>
      <Typography component="h1" variant="h1">
        {t(variant === CARD_LOGIN_VARIANT_SIGN_UP ? 'register' : 'login')}
      </Typography>
      {step === STEPS[0] && (
        <StepEmail
          value={email}
          defaultValue={email}
          onChange={handleChangeEmail}
          loading={loading}
          errorMessage={errorMessage}
          onSubmit={handleSubmitEmail}
          variant={variant}
          buttonText={stepEmailButtonText}
        />
      )}
      {step === STEPS[1] && (
        <StepPasswordLogin
          email={email}
          loading={loading}
          errorMessage={errorMessage}
          onClearEmail={handleClearEmail}
          onSubmit={handleSubmitPassword}
          onChange={onChangePassword}
          ForgotPasswordLink={ForgotPasswordLink}
        />
      )}

      <SocialButtons
        onClick={handleClickLoginSocial}
        variant={variant}
        disabled={loading}
      />

      <p className="b-card-login__tip">
        {t(
          variant === CARD_LOGIN_VARIANT_SIGN_UP
            ? 'tip have account'
            : 'tip no account'
        )}{' '}
        <Link href="#" onClick={handleClickTipLink}>
          {t(variant === CARD_LOGIN_VARIANT_SIGN_UP ? 'login' : 'register')}
        </Link>
      </p>
    </Card>
  );
};

export default CardLogin;
