import React, { useEffect, useState } from 'react';
import './InfluencerListPage.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Col, Icon, InputText, Row } from '../../../components/ui';
import InputAdornment from '@mui/material/InputAdornment';
import { downloadCsv, getEnchorerInfluencerList } from '../../../api/enchorer';
import Table from '../../../components/layout/table/Table';
import _ from 'lodash';
import { fromMillisToFormat, normalizeCurrency, theme } from '../../../utils';
import TablePagination from '../../../components/layout/table/TablePagination';
import { influencerEntityFields } from '../../../utils/entityFields';
import { defaultListObj } from '../../../utils/const';
import { ListSocial } from '../campaign/CampaignDetailPage';
import { AppRoutes } from '../../../app/routers';

const InfluencerListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [data, setData] = useState(defaultListObj);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(undefined);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({});

  const onChangeText = (e) => {
    const val = e.target.value;
    const fts = {
      text: val,
      caseSensitive: false,
    };
    setFilters((prevState) => ({ ...prevState, fts }));
  };

  useEffect(() => {
    getInitialData();
  }, [filters]);

  const onDownloadCsv = async () => {
    setLoadingCsv(true)
    await downloadCsv('Influencer')
    setLoadingCsv(false)
  }

  const getInitialData = async () => {
    const influencerRes = await getEnchorerInfluencerList(0, { ...filters, sort });
    setData(influencerRes);
    setPage(0);
  };

  const handleChangePage = async (newPage) => {
    const influencerRes = await getEnchorerInfluencerList(newPage, { ...filters, sort });
    setData(influencerRes);
    setPage(newPage);
  };

  const goToInfluencerDetail = async (influencer) => {
    history.push(AppRoutes.influencerDetail(influencer._id));
  };

  const bodyCellComponent = {
    nickname: (row) => _.get(row, 'nickname', ' - '),
    nameSurname: (row) => `${_.get(row, 'firstName', ' - ')} ${_.get(row, 'lastName', ' - ')}`,
    'careerLevel.level.label': (row) => _.get(row, 'careerLevel.level.label', ' - '),
    'careerLevel.score': (row) => _.get(row, 'careerLevel.score', 0),
    categories: (row) => renderCategories(row),
    socials: (row) => renderSocials(row),
    'created.ts': (row) => fromMillisToFormat(_.get(row, 'created.ts', 0)),
    'dateOfBirth.date.ts': (row) => fromMillisToFormat(_.get(row, 'dateOfBirth.date.ts', 0)),
    'address.place': (row) => renderAddress(row),
    'mission.newEnchorer': (row) => renderNewEnchorerMission(row),
    'wallet.value': (row) => renderWallet(row),
    actions: (row) => renderActions(row),
  };

  const renderAddress = (influencer) => {
    const address = _.get(influencer, 'address', {});
    if (_.isString(address.place)) {
      return _.get(address, 'place', ' - ');
    } else {
      return ' - ';
    }
  };

  const renderNewEnchorerMission = (influencer) => {
    const mission = _.get(influencer, 'missions', []);
    const newEnchorerStatus = _.find(mission, (m) => m.item._id === 'new-enchorer');
    if (!newEnchorerStatus) {
      return ' - ';
    }
    return `${t(newEnchorerStatus.status)}`;
  };

  const renderWallet = (influencer) => {
    return (
      <Row>
        {normalizeCurrency(_.get(influencer, 'wallet', {}))}
        {influencer && influencer?.wallet && influencer.wallet?.value && <Icon icon={'far fa-credit-card'} color={theme['primaryColor']} sx={{ paddingLeft: '0.3rem' }} />}
      </Row>
    );
  };

  const renderCategories = (influencer) => {
    const categories = _.get(influencer, 'categories', []);
    return (
      <Col>
        {categories?.map((cat) => (
          <span>{_.get(cat, 'label')}</span>
        ))}
      </Col>
    );
  };

  const renderSocials = (influencer) => {
    const style = { height: '1rem', width: '1rem' };
    const rowStyle = { maxWidth: '10rem' };
    const socials = _.get(influencer, 'socials', []);
    return <ListSocial socials={socials} rowStyle={rowStyle} sx={{ height: '1.5rem', width: '1.5rem', marginRight: '0.5rem' }} iconStyle={style} className={'icon-social'} />;
  };

  const renderActions = (row) => {
    const style = { width: '1rem', height: '1rem', padding: '0.3rem' };
    return (
      <Row justifyEnd flex spaceBetween>
        <IconButton className={'button'} onClick={() => goToInfluencerDetail(row)}>
          <Icon icon={'fas fa-pencil'} color={theme['primaryColor']} style={style} />
        </IconButton>
      </Row>
    );
  };

  const onRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    let sort = { asc: isAsc };

    if (property === 'nameSurname') {
      sort.fields = ['firstName', 'lastName'];
    } else {
      sort.fields = [property];
    }

    setSort(sort);

    const influencerRes = await getEnchorerInfluencerList(page, { ...filters, sort });
    setData(influencerRes);
  };

  return (
    <div id={'campaign-list'}>
      <Row alignCenter flex container md={12}>
        <Grid item md={3}>
          <Typography variant={'h1'}>{t('influencer List')}</Typography>
        </Grid>
        <Row item alignCenter md={9}>
          <Row flex>
            <InputText
              variant={'outlined'}
              fullWidth
              value={_.get(filters, 'fts.text', '')}
              onChange={onChangeText}
              label={t('search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon icon={'far fa-search'} color={'black'} />
                  </InputAdornment>
                ),
              }}
            />
          </Row>
            <IconButton className={'button-download'} onClick={onDownloadCsv}>
              <Icon loading={loadingCsv} icon={'far fa-file-csv'} color={'white'} sx={{ padding: '0.5rem' }} style={{ width: '1.5rem', height: '1.5rem' }} />
            </IconButton>
          </Row>
      </Row>
      <Table
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
        entityFields={influencerEntityFields}
        sortEnable={true}
        showCheckbox={false}
        data={data.data}
        bodyCellComponent={bodyCellComponent}>
        <TablePagination rows={data.data} setPage={handleChangePage} totalData={data.total} page={page} />
      </Table>
    </div>
  );
};

export default InfluencerListPage;
