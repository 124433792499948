import React, { memo } from 'react';
import { Button } from "../index";

import './buttonBranded.scss'

export const ButtonBranded = memo (({
  brand,
  icon ,
  ...props
}) => {

  return (
    <div id={'buttonBranded'}>
      <Button
        size={'small'}
        variant={'outlined'}
        startIcon={<img src={icon} alt={brand} className={'icon'}/>}
        {...props}
      />
    </div>
  )
})

