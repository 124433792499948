import React, { memo } from 'react'
import { Modal } from "@material-ui/core";
import "./baseModal.scss"
import { Col } from "../col/col";

export const BaseModal = memo(({
  children,
  open,
  onClose,
  propStyle,
  ...props
}) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 32,
    maxWidth: '30vw',
    backgroundColor: 'transparent',
    ...propStyle
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div id={'baseModal'}>
        <Col className={'container'} style={style} justifyCenter>
          {children}
        </Col>
      </div>
    </Modal>
  )

})

