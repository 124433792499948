import React, { useEffect } from 'react';
import { Container } from '../components/ui';
import { Grid } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { getParameterFromUrl } from '../utils';
import { AppRoutes } from '../app/routers';
import { acceptBusinessUnitInvitation } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInvitationId, userSelectors } from '../features/userSlice';

const AcceptCompanyInvitation = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userToken = useSelector(userSelectors.token);

  useEffect(() => {
    const params = getParameterFromUrl(location.search);
    console.log({ params });
    if (params.invitationId) {
      dispatch(setUserInvitationId(params.invitationId));
      onAcceptBusinessInvitation(params.invitationId);
    } else {
      history.push(AppRoutes.home());
    }
  }, []);

  const onAcceptBusinessInvitation = async (invitationId) => {
    const acceptRes = await acceptBusinessUnitInvitation(invitationId, history);
    console.log({ acceptRes });
    if (acceptRes) {
    } else {
    }
  };

  return (
    <Container>
      <Grid container></Grid>
    </Container>
  );
};

export default AcceptCompanyInvitation;
