import React, { memo } from 'react'
import './navRegistration.scss'
import clsx from 'clsx'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'


export const NavRegistration = memo (({items, onClick}) => {

  console.log({items})
  const handleClickItem = (event, item) => {
    event.preventDefault()
    if (!item.disabled) {
      onClick(item)
    }
    console.log({item})
  }

  return (
    <List component={'nav'} className='c-nav-registration' id={'sidebar'}>
      {items.map((item, i) => (
        <ListItem
          disablePadding
          className={clsx('sidebar-item')}
          key={`${item.label}-${item.href}`}
          disabled={item.disabled}
        >
          <ListItemButton
            className={'item'}
            href={item.href}
            selected={item.selected}
            classes={{
              selected: {
                // color: styles['selected-color'],
                // backgroundColor: styles['background-color']
                backgroundColor: 'red'
              }
            }}
            onClick={(event) => handleClickItem(event, item)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              className={clsx('e-list-text')}
              primary={item.label}
            />
            {/*{item.ornamentIcon ? (*/}
            {/*  <Tooltip*/}
            {/*    placement={'top-end'}*/}
            {/*    title={intl.formatMessage(*/}
            {/*      messages['Components.NavRegistration.tooltip']*/}
            {/*    )}*/}
            {/*  >*/}
            {/*    <ListItemIcon className={clsx('e-list-icon-ornament')}>*/}
            {/*      {item.ornamentIcon}*/}
            {/*    </ListItemIcon>*/}
            {/*  </Tooltip>*/}
            {/*) : null}*/}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
})


